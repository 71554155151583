import { CURRENCY } from "../config/site";

export const titleCase = (input?: string) => {
  try {
    return input
      ? input
          .toLocaleLowerCase()
          .split(" ")
          .map((word) => word[0].toUpperCase() + word.slice(1))
          .join(" ")
      : undefined;
  } catch (error) {
    console.error(error);
    console.log(input);
    return input;
  }
};

export const currency = (value: number) => {
  const options = {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency: CURRENCY,
  };
  return Intl.NumberFormat(undefined, options).format(value);
};

export const formatDate = (datetime: string) => {
  if (!datetime) return null;
  const locale = navigator?.language;
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return new Intl.DateTimeFormat(locale, options).format(new Date(datetime));
};

export const formatDateTime = (datetime: string) => {
  if (!datetime) return null;
  const locale = navigator?.language;
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Intl.DateTimeFormat(locale, options).format(new Date(datetime));
};

export const formatCardNumber = (cardNumber: string | number | undefined) => {
  if (cardNumber === undefined) {
    return undefined;
  }
  if (String(cardNumber).match(/^\d+$/)) {
    return `#${cardNumber}`;
  }
  return cardNumber;
};

export const getCardName = (grading: Grading) => {
  const { cardName: gradingCardName, gameProduct } = grading;
  return gameProduct?.cardName ?? gradingCardName;
};
