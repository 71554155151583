import Typography from "@material-ui/core/Typography";
import Note from "./Note";

interface NotesListProps {
  notes: Note[];
}

function NotesList(props: NotesListProps) {
  const { notes } = props;

  if (notes.length < 1)
    return <Typography>This grading has no notes yet.</Typography>;

  return (
    <>
      {notes.map((note) => (
        <Note note={note} key={note.id} />
      ))}
    </>
  );
}

export default NotesList;
