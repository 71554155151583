import React from "react";
import SignInView from "../../views/SignInView";
import { useAuth } from "../../hooks/authHooks";

interface AuthCheckProps {
  children: React.ReactChild | React.ReactChildren;
}

function AuthCheck(props: AuthCheckProps) {
  const { children } = props;
  const {authAttempted, user} = useAuth();

  if (!authAttempted) {
    // TODO:: Loading spinner or something
    return null;
  }

  if (!user) {
    return <SignInView />;
  }

  return <>{children}</>;
}

export default AuthCheck;
