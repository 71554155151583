import { useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import { useFetchGameOptions } from "../hooks/cardHooks";
import EditGameButton from "../components/catalog/EditGameButton";
import { filterReviewed } from "../helpers/tableHelpers";
import AddGameButton from "../components/catalog/AddGameButton";
import UploadCSVButton from "../components/catalog/UploadCSVButton";
function CatalogView() {
  const gameOptions = useFetchGameOptions();
  const [showReviewed, setShowReviewed] = useState<"all" | "true" | "false">(
    "all"
  );
  const InitData = {
    game: "",
    gameCode: "",
    sets: [],
    finish: [],
    gameImage: "",
    description: "",
    cardsGraded: 0,
    reviewed: false,
    found: false,
  };

  return (
    <>
      <Typography variant="h2">Catalog</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "100%",
              flexWrap: "wrap",
              alignItems: "center",
              margin: "0 1rem",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <AddGameButton data={InitData} />
              <UploadCSVButton />
            </Box>
            <Box width={200}>
              <FormControl fullWidth>
                <InputLabel id="filter-by-reviewed-label">
                  Filter by Reviewed
                </InputLabel>
                <Select
                  labelId="filter-by-reviewed-label"
                  id="filter-by-reviewed"
                  value={showReviewed}
                  label="Filter by Reviewed"
                  onChange={(e: any) => setShowReviewed(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        {gameOptions.filter(filterReviewed(showReviewed)).map((entry) => {
          const { id, game, gameCode, gameImage, cardsGraded } = entry;
          return (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              key={id}
              style={{ padding: "1rem" }}
            >
              <Box
                style={{
                  textAlign: "center",
                  border: "1px solid #212121",
                  borderRadius: 2,
                  padding: "18px 12px",
                  height: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link to={`/catalog/${gameCode}`}>
                    <img
                      src={gameImage}
                      alt={game}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Link>
                </Box>
                <Box
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Link to={`/catalog/${gameCode}`}>
                    <Typography variant="h6">{game}</Typography>
                  </Link>
                  <Typography>Cards graded: {cardsGraded || 0}</Typography>
                </Box>
                <Box
                  style={{
                    textAlign: "right",
                    paddingTop: 8,
                  }}
                >
                  <EditGameButton data={entry} />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default CatalogView;
