import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

import {
  filterRowContains,
  filterArchived,
  filterReviewed
} from "../helpers/tableHelpers";

import { useFetchSetInfo, useGetGameInfo } from "../hooks/cardHooks";

import FilterSearch from "../components/generic/FilterSearch";
import EditCardButton from "../components/catalog/EditCardButton";
import AddCardButton from "../components/catalog/AddCardButton";
import ExportCsvButton from "../components/catalog/exportCsvButton";

function CatalogSetView() {
  const params = useParams<{ gameCode: string; setName: string }>();
  const { gameCode = "", setName = "" } = params;

  const [search, setSearch] = useState("");
  const [showReviewed, setShowReviewed] = useState<"all" | "true" | "false">(
    "all"
  );
  const [showArchived, setShowArchived] = useState<"all" | "true" | "false">(
    "all"
  );

  const { data } = useFetchSetInfo(gameCode, setName);
  const gameInfo = useGetGameInfo(gameCode);

  useEffect(() => {
    setSearch("");
  }, [gameCode, setName]);

  const filteredData =
    data
      ?.slice()
      .filter(filterArchived(showArchived))
      .filter(filterReviewed(showReviewed))
      .filter(filterRowContains(search)) || [];

  const InitData = {
    gameCode: gameCode,
    title: "",
    cardName: "",
    rarity: "",
    number: "",
    image: "",
    releaseDate: "",
    setName: setName,
    cardsGraded: 0,
    finalGradeCounts: [],
    archived: false,
    reviewed: false
  };

  return (
    <>
      <Typography variant="h2">{setName}</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "100%",
              flexWrap: "wrap",
              alignItems: "center",
              margin: "0 1rem"
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem"
              }}
            >
              <AddCardButton data={InitData} />
              <ExportCsvButton gameCode={gameCode} setName={setName} />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Box width={200} paddingRight={2}>
                <FormControl fullWidth>
                  <InputLabel id="filter-by-reviewed-label">
                    Filter by Reviewed
                  </InputLabel>
                  <Select
                    labelId="filter-by-reviewed-label"
                    id="filter-by-reviewed"
                    value={showReviewed}
                    label="Filter by Reviewed"
                    onChange={(e: any) => setShowReviewed(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box width={200}>
                <FormControl fullWidth>
                  <InputLabel id="filter-by-archived-label">
                    Filter by Archived
                  </InputLabel>
                  <Select
                    labelId="filter-by-archived-label"
                    id="filter-by-archived"
                    value={showArchived}
                    label="Filter by Archived"
                    onChange={(e: any) => setShowArchived(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FilterSearch onChange={(value: string) => setSearch(value)} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={150}>Card #</TableCell>
                  <TableCell>Card Name</TableCell>
                  <TableCell>Rarity</TableCell>
                  <TableCell width={100} style={{ textAlign: "right" }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.length > 0 ? (
                  filteredData?.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.number}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>
                        {row.rarity ? row.rarity.charAt(0).toUpperCase() + 
                          row.rarity.slice(1) : ''}
                      </TableCell>
                      <TableCell>
                        <EditCardButton data={row} gameInfo={gameInfo} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} style={{ fontStyle: "italic" }}>
                      No results
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default CatalogSetView;
