export const arcInnerRadius = 13.9;
export const arcWidth = 1.45;

export const gradientColours = {
  fullGold: { stopColour0: "#967845", stopColour1: "#BF9F65" },
  gold: { stopColour0: "#977539", stopColour1: "#debb68" },
  silver: { stopColour0: "#868da7", stopColour1: "#d0d2e0" },
  bronze: { stopColour0: "#835236", stopColour1: "#c5895d" },
  orange: { stopColour0: "#af2624", stopColour1: "#f36d21" },
};

export const popReportUrl = "https://www.pcgpopreport.com/report";
