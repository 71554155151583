import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DropzoneArea } from "material-ui-dropzone";
import { useUploadFileToGrading } from "../../hooks/gradingHooks";
import { useAlert } from "../../hooks/alertHooks";

const useStyles = makeStyles({
  spinner: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

interface ImageUploadProps {
  gradingId: number;
}

// Delete confirmation

function ImageUpload(props: ImageUploadProps) {
  const { gradingId } = props;
  const { mutateAsync, isMutating } = useUploadFileToGrading();
  const { addAlert } = useAlert();
  const classes = useStyles();

  const handleFileUpload = async (files: File[]) => {
    await Promise.all(
      files.map(async (file) => {
        try {
          await mutateAsync({ gradingId, file });
          addAlert(`File ${file.name} uploaded`, "success");
        } catch (error) {
          addAlert(`Failed to upload ${file.name}`, "error");
        }
      })
    );
  };

  if (isMutating)
    return <CircularProgress size={192} className={classes.spinner} />;

  return (
    <DropzoneArea
      acceptedFiles={["image/*"]}
      dropzoneText={"Drag and drop an image here or click"}
      onChange={handleFileUpload}
      onDelete={(file) => console.log("deleted", file)}
      filesLimit={6}
      initialFiles={[]}
      showAlerts={false}
      onDropRejected={(files: File[], event: unknown) =>
        console.log(files, event)
      }
      maxFileSize={30000000}
    />
  );
}

export default ImageUpload;
