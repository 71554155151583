const MAX_SCAN_TIME = 500; // milliseconds
const PERMITTED_LENGTHS = [6, 7, 8, 12, 13, 14, 15];

let captureStart = new Date();
const digits = [] as string[];

const handleKeypress = (event: KeyboardEvent) => {
  if (event.code.startsWith("Digit")) {
    if (digits.length === 0) {
      captureStart = new Date();
    }
    digits.push(event.key);
  } else if (event.code === "Enter") {
    const now = new Date();
    const elapsedTime = captureStart.getTime() - now.getTime();
    if (
      elapsedTime < MAX_SCAN_TIME &&
      PERMITTED_LENGTHS.includes(digits.length)
    ) {
      const barcodeEvent = new CustomEvent("onbarcode", {
        detail: digits.join(""),
      });
      window.dispatchEvent(barcodeEvent);
    }
    clearBuffer();
  } else {
    // Invalid input
    clearBuffer();
  }
};

const clearBuffer = () => (digits.length = 0);

export const startBarcodeListener = () => window.addEventListener("keypress", handleKeypress);

export const stopBarcodeListener = () =>
  window.removeEventListener("keypress", handleKeypress);
