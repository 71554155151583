import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {
  useFetchRoles,
  useFetchMe,
  useCreateUser,
  useUpdateUser,
} from "../../hooks/userHooks";
import { useFetchSites } from "../../hooks/siteHooks";
import { useAlert } from "../../hooks/alertHooks";
import { titleCase } from "../../helpers/formattingHelpers";
import ProgressButton from "../generic/ProgressButton";
import { getCurrentRegion } from "../../helpers/siteHelpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "1rem",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      gap: theme.spacing(0.5),
    },
    formControl: {
      margin: theme.spacing(1),
      width: "25ch",
    },
  })
);

const getInputChangeHandler =
  (setFunction: React.Dispatch<React.SetStateAction<string | undefined>>) =>
  (event: React.ChangeEvent<HTMLInputElement>) =>
    setFunction(event.target.value);

interface UserFormProps {
  user?: User;
  ActionsWrapper: Function;
  closeForm: VoidFunction;
}

function UserForm(props: UserFormProps) {
  const { user, ActionsWrapper, closeForm } = props;
  const [name, setName] = useState<OptionalString>("");
  const [email, setEmail] = useState<OptionalString>("");
  const [role, setRole] = useState<OptionalString>("");
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const classes = useStyles();
  const { addAlert } = useAlert();

  const { data: currentUser } = useFetchMe();
  const { isLoading: isLoadingRoles, data: roles } = useFetchRoles();
  const { isLoading: isLoadingSites, data: sites } = useFetchSites();
  const submitAction = user ? useUpdateUser : useCreateUser;
  const { mutateAsync, isMutating } = submitAction();

  const isSuperAdmin = currentUser?.role === "Super Admin";
  const currentRegion = getCurrentRegion();

  useEffect(() => {
    const userDetails = user ?? {
      enabled: true,
      name: "",
      email: "",
      role: "",
      buckets: ["Receiving/Research And ID"],
      regions: currentRegion ? [currentRegion] : [],
    };
    setName(userDetails.name);
    setEmail(userDetails.email);
    setRole(userDetails.role);

    // If not super admin and creating new user, force current region only
    if (!isSuperAdmin && !user) {
      setSelectedRegions(currentRegion ? [currentRegion] : []);
    } else {
      setSelectedRegions(
        userDetails.regions || (currentRegion ? [currentRegion] : [])
      );
    }
  }, [user, currentRegion, isSuperAdmin]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formErrors = [];
    if (!name || name === "") {
      formErrors.push("name");
    }
    if (!email || email === "") {
      formErrors.push("email");
    }
    if (!role || role === "") {
      formErrors.push("role");
    }
    if (selectedRegions.length === 0) {
      formErrors.push("regions");
    }
    if (formErrors.length > 0) {
      setErrors(formErrors);
      return false;
    }

    try {
      const userDetails = user ?? {
        enabled: true,
        buckets: ["Receiving/Research And ID"],
      };

      // Ensure non-super admins can only set current region
      const regionsToSubmit = isSuperAdmin
        ? selectedRegions
        : ([currentRegion].filter(Boolean) as string[]);

      await mutateAsync({
        ...userDetails,
        name: name!,
        email: email!,
        role: role!,
        regions: regionsToSubmit,
      });
      addAlert("User updated", "success");
      closeForm();
    } catch (error) {
      addAlert("Failed to update user", "error");
    }
    return false;
  };

  const handleRegionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!isSuperAdmin) return; // Extra safety check
    setSelectedRegions(event.target.value as string[]);
  };

  const availableRoles =
    roles?.map((role) => ({
      name: role.name,
      label: titleCase(role.name),
    })) || [];

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        required
        id="name"
        label="Name"
        value={name}
        onChange={getInputChangeHandler(setName)}
        error={errors.includes("name")}
      />
      <TextField
        required
        id="email"
        label="email"
        type="email"
        value={email}
        onChange={getInputChangeHandler(setEmail)}
        error={errors.includes("email")}
      />
      <TextField
        id="role"
        select
        label="Role"
        value={role}
        onChange={getInputChangeHandler(setRole)}
        error={errors.includes("role")}
        helperText="Please select a role"
      >
        {isLoadingRoles ? <CircularProgress size={24} /> : null}
        {availableRoles.map((role) => (
          <MenuItem key={role.name} value={role.name}>
            {role.label}
          </MenuItem>
        ))}
      </TextField>

      <FormControl
        className={classes.formControl}
        error={errors.includes("regions")}
      >
        <InputLabel id="regions-label">Regions*</InputLabel>
        <Select
          labelId="regions-label"
          id="regions"
          multiple
          value={selectedRegions}
          onChange={handleRegionChange}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  label={
                    sites?.find((site) => site.region === value)?.region ||
                    value
                  }
                  size="small"
                />
              ))}
            </div>
          )}
          disabled={isLoadingSites || !isSuperAdmin}
        >
          {sites?.map((site) => (
            <MenuItem key={site.region} value={site.region}>
              {site.region}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ActionsWrapper>
        <ProgressButton
          type="submit"
          inProgress={isMutating}
          disabled={isMutating}
          color="primary"
          variant="contained"
        >
          Save
        </ProgressButton>
        <Button color="default" variant="contained" onClick={() => closeForm()}>
          Cancel
        </Button>
      </ActionsWrapper>
    </form>
  );
}

export default UserForm;
