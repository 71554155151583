import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@material-ui/core';

import UploadImage from './UploadImage';

interface EditableKey { 
  key: string,
  type: 'string' | 'image' | 'boolean',
}

function CreateModal(props: {
  title: string,
  editableKeys: EditableKey[],
  open: boolean,
  onSave: any,
  onClose: any,
  data: { [key: string]: string | null | boolean },
  isMutating: boolean,
  onFileChange?: any,
}) {
  const { title, data, editableKeys, open, onSave, onClose, isMutating, onFileChange } = props;
  
  const [localData, setLocalData]= useState(data);

  useEffect(() => {
    if (data.gameImage && data.gameImage !== localData.gameImage) {
      setLocalData({
        ...localData,
        gameImage: data.gameImage,
      })
    }
    if (data.image && data.image !== localData.image) {
      setLocalData({
        ...localData,
        image: data.image,
      })
    }
    if (data.setImage && data.setImage !== localData.setImage) {
      setLocalData({
        ...localData,
        setImage: data.setImage,
      })
    }
  }, [data, localData, setLocalData]);

  const handleOnSave = () => {
    onSave(localData);
  }

  const handleSetLocalData = (key: string, value: string | boolean) => {
    setLocalData({
      ...localData,
      [key]: value === "" ? null : value,
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`alert-dialog-${title}-title`}
      aria-describedby={`alert-dialog-${title}-description`}
    >
      <DialogTitle id={`alert-dialog-${title}-title`}>{title}</DialogTitle>
      <DialogContent>
        {editableKeys.map((entry: EditableKey) => {
          const { key, type } = entry;
          const value = localData[key];

          if (data[key] !== undefined) {
            switch(type) {
              case 'string': 
                return (
                  <TextField
                    key={key}
                    label={key}
                    value={value === null ? '' : value}
                    onChange={(e) => handleSetLocalData(key, e.target.value)}
                    fullWidth
                    style={{ marginBottom: 16 }}
                  />
                )
              case 'image': 
                return (<UploadImage key={key} label={key} value={String(value)} onFileChange={onFileChange} />)
              case 'boolean':
                const booleanValue = Boolean(value);
                return (
                  <FormControl fullWidth key={key}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={booleanValue}
                          onChange={() => handleSetLocalData(key, !booleanValue)}
                          name={key}
                        />
                      }
                      label={key}
                    />
                  </FormControl>
                )
              default:
                return null;
            }
          }
          return null
        })}
        {isMutating && 
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', textAlign: 'center', background: 'black', opacity: '.6' }}>
            <CircularProgress style={{ marginTop: '30%' }} />
          </div>        
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isMutating}>
          Cancel
        </Button>
        <Button onClick={handleOnSave} color="primary" autoFocus disabled={isMutating || isEqual(data, localData)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateModal;
