import { fetchWithToken } from "../services/api";
import {
  buildQueryFromOptionalParams,
  OptionalParamsObject,
} from "../helpers/apiHelpers";
import { API_BASE_URL } from "../config/api";

interface FetchOrdersParams extends OptionalParamsObject {
  offset: number;
  limit: number;
  order: AscendingOrder;
  orderBy?: string;
  bucket?: OptionalString;
}

export type AddCardRequest = {
  gameProductId?: number;
  manualEntry: boolean;
  cardDetails: {
    name: string;
    subgradeRequired: boolean;
    productId: number;
    quantity: number;
    title: string;
    variantId: number;
    variantTitle: string;
    game: string;
    setName: string;
    cardName: string;
    cardNumber: string;
    finish: string;
    language: string;
    cardValue: number;
    cardError: string;
    rarity: string;
    slabType: string;
  };
};

export const addCardToOrder = (
  orderId: number,
  data: AddCardRequest
): Promise<Order> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${API_BASE_URL}/order/${orderId}/addCard`,
    payload: data,
  });

export const fetchOrders = (params: FetchOrdersParams): Promise<OrderList> => {
  const { order, orderBy, ...urlParams } = params;
  if (orderBy) {
    urlParams["sort"] = `${order === "asc" ? "+" : "-"}${orderBy}`;
  }
  let url = `${API_BASE_URL}/order/gradings?`;
  url += buildQueryFromOptionalParams(urlParams);
  return fetchWithToken({
    method: "GET",
    endpoint: url,
  });
};

export const fetchOrderByBarcode = (barcode: string): Promise<Order> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/order/grading/byBarcode/${barcode}`,
  });
