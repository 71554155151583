import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CustomersView from "../views/CustomersView";
import DashboardView from "../views/DashboardView";
import GradingDetailsView from "../views/GradingDetailsView";
import CatalogView from "../views/CatalogView";
import CatalogGameView from "../views/CatalogGameView";
import CatalogSetView from "../views/CatalogSetView";
import GradingsView from "../views/GradingsView";
import OrdersView from "../views/OrdersView";
import CreateUserView from "../views/CreateUserView";
import UsersView from "../views/UsersView";
import UpdateUserView from "../views/UpdateUserView";
import UpdateGradingView from "../views/UpdateGradingView";
import GradeCheck from "../views/GradeCheck";
// import TestView from "../views/TestView";

import Label from "../views/Label";
import { gradingComplete } from "../testHelpers/gradingMocks";
import UpdateCardView from "../views/UpdateCardView";

interface AppRoutingProps {
  children: React.ReactChild | React.ReactChildren;
}

function AppRouting(props: AppRoutingProps) {
  const { children } = props;

  return (
    <Router>
      {children}
      <Container style={{ paddingTop: 32 }}>
        <Switch>
          <Route path="/users/new">
            <CreateUserView />
          </Route>
          <Route path="/users/:userId">
            <UpdateUserView />
          </Route>
          <Route path="/gradings/:gradingId">
            <UpdateGradingView />
          </Route>
          <Route path="/orders/gradings/:gradingId">
            <UpdateGradingView />
          </Route>
        </Switch>
        <Route path="/card/:gradingId">
          <UpdateCardView />
        </Route>
        <Switch>
          <Route path="/customers">
            <CustomersView />
          </Route>
          <Route path="/dashboard">
            <DashboardView />
          </Route>
          <Route path="/orders">
            <OrdersView />
          </Route>
          <Route path="/users">
            <UsersView />
          </Route>
          <Route path="/catalog/:gameCode/:setName">
            <CatalogSetView />
          </Route>
          <Route path="/catalog/:gameCode">
            <CatalogGameView />
          </Route>
          <Route path="/catalog">
            <CatalogView />
          </Route>
          <Route path="/grading/:gradingId">
            <GradingDetailsView />
          </Route>
          <Route path="/gradings">
            <GradingsView />
          </Route>
          <Route path="/dashboard">
            <DashboardView />
          </Route>
          <Route path="/label">
            <Label grading={gradingComplete} />
          </Route>
          <Route path="/gradeCheck">
            <GradeCheck />
          </Route>
          {/* <Route path="/test/:barcode">
            <TestView />
          </Route> */}
          <Route path="/">
            <OrdersView />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}

export default AppRouting;
