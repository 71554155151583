import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel
} from "@material-ui/core";
import FilterSearch from "../generic/FilterSearch";
import { useState } from "react";
import { useFetchSetInfo } from "../../hooks/cardHooks";
import { SearchResultsTable } from "./SearchResultTable";

type DeleteConfirmModalProps = {
  cardInfo: any;
  open: boolean;
  onClose: () => void;
  onConfirm: (data: any) => void;
};

export const DeleteConfirmMigrateModal = ({
  cardInfo,
  open,
  onClose,
  onConfirm
}: DeleteConfirmModalProps) => {
  const { gameCode, setName } = cardInfo;

  const isNoCardsToMigrate = cardInfo?.cardsGraded <= 0;

  const [search, setSearch] = useState("");
  const [isNoMigration, setIsNoMigration] = useState(isNoCardsToMigrate);
  const [selectedMigration, setSelectedMigration] = useState<number | null>(
    null
  );

  const { data: cardList } = useFetchSetInfo(gameCode, setName);

  const handleConfirm = () => {
    onConfirm({
      selectedMigration: cardList?.find(
        (card: any) => card.id === selectedMigration
      )
    });
    onClose();
  };

  const results =
    search === ""
      ? []
      : cardList?.filter(
          (card: any) =>
            card.cardName.toLowerCase().includes(search.toLowerCase()) &&
            card.id !== cardInfo.id
        );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>Migrate {cardInfo?.cardsGraded || null} Grades</DialogTitle>
      <DialogContent>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <FilterSearch
              onChange={(searchString: string) => setSearch(searchString)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNoMigration}
                  onChange={(e) => setIsNoMigration(e.target.checked)}
                />
              }
              label="No Migration"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              maxHeight: "50vh"
            }}
          >
            {results ? (
              <SearchResultsTable
                data={results}
                selected={selectedMigration}
                onSelect={(id) => setSelectedMigration(id)}
              />
            ) : null}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <br />
        <Button
          variant="contained"
          size="small"
          style={{ float: "right" }}
          color="primary"
          onClick={handleConfirm}
          disabled={!selectedMigration && !isNoMigration}
        >
          {isNoMigration ? "Delete" : "Delete and Migrate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
