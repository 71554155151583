/**
 * filterCardsGraded: filter function for non-case matching flatened rows
 *
 */
export const filterCardsGraded = (
  gradeValue: string | null = null,
  showAlways: boolean
) => (row: any) => {
  if (!showAlways) {
    return true;
  }
  if (gradeValue && gradeValue !== "") {
    return (
      row.cardsGraded !== null &&
      row.finalGradeCounts.length > 0 &&
      row.finalGradeCounts.filter(
        (grade: any) => String(grade.grade) === gradeValue
      ).length > 0
    );
  }
  return row.cardsGraded !== null;
};

export const filterReviewed = (reviewed: "all" | "true" | "false") => (
  row: any
) => {
  if (reviewed === "all") {
    return true;
  }
  if (reviewed === "true") {
    if (row.reviewed === true) {
      return true;
    }
    return false;
  }
  if (reviewed === "false") {
    if (row.reviewed === false) {
      return true;
    }
    return false;
  }
};

export const filterArchived = (archived: "all" | "true" | "false") => (
  row: any
) => {
  if (archived === "all") {
    return true;
  }
  if (archived === "true") {
    if (row.archived === true) {
      return true;
    }
    return false;
  }
  if (archived === "false") {
    if (row.archived === false) {
      return true;
    }
    return false;
  }
};

const parseDate = (dateStr: string): Date => {
  if (dateStr.length === 4) {
    // If the date string is in the format "YYYY", add "-01-01" to represent the first day of the year
    dateStr += "-01-01";
  }

  return new Date(dateStr);
};

/**
 * filterRowContains: filter function for non-case matching flatened rows
 *
 * @param {string} needle
 */
export const filterRowContains = (needle: string) => (row: any) =>
  JSON.stringify(row)
    .toLowerCase()
    .includes(needle.toLowerCase());

const DEFAULTS_MAP = {
  releaseDate: "0000-01-01",
  cardsGraded: 0
};

export const sortGameSetsTable = (
  order: "asc" | "desc",
  orderBy: keyof Set<string>
) => (a: Set<string>, b: Set<string>) => {
  try {
    const aVal =
      a[orderBy] || DEFAULTS_MAP[orderBy as keyof typeof DEFAULTS_MAP];
    const bVal =
      b[orderBy] || DEFAULTS_MAP[orderBy as keyof typeof DEFAULTS_MAP];

    if (typeof aVal === "string" && typeof bVal === "string") {
      if (orderBy === "releaseDate") {
        const aValDate = parseDate(aVal);
        const bValDate = parseDate(bVal);

        let result = 0;

        if (aValDate < bValDate) {
          result = 1;
        } else if (aValDate > bValDate) {
          result = -1;
        }

        return order === "asc" ? result : -result;
      }

      if (order === "desc") {
        return aVal.localeCompare(bVal, "en", { sensitivity: "base" });
      }

      return aVal.localeCompare(bVal, "en", { sensitivity: "base" }) === 1
        ? -1
        : 1;
    }

    if (typeof aVal === "number" && typeof bVal === "number") {
      if (aVal === bVal) {
        return 0;
      }
      if (order === "asc") {
        return aVal - bVal;
      }
      return bVal - aVal;
    }

    return 0;
  } catch (err) {
    return 0;
  }
};

export const sortSetViewTable = (
  order: "asc" | "desc",
  orderBy: keyof CardInfo
) => (a: CardInfo, b: CardInfo) => {
  try {
    const aVal = a[orderBy];
    const bVal = b[orderBy];

    if (typeof aVal === "string" && typeof bVal === "string") {
      if (order === "desc") {
        return aVal.localeCompare(bVal, "en", { sensitivity: "base" });
      }
      return aVal.localeCompare(bVal, "en", { sensitivity: "base" }) === 1
        ? -1
        : 1;
    }

    if (typeof aVal === "number" && typeof bVal === "number") {
      if (aVal === bVal) {
        return 0;
      }
      if (order === "asc") {
        return aVal - bVal;
      }
      return bVal - aVal;
    }

    return 0;
  } catch (err) {
    return 0;
  }
};
