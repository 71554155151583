import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  downloadPythonLabels,
  orderItemToPythonLabel,
  PythonLabel
} from '../../helpers/pythonHelpers';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AddNoteButton from "./AddNoteButton";
import GradingBarcode from "./GradingBarcode";
import ReferToSupervisorButton from "./ReferToSupervisorButton";
import LabelCustomisation from "../label/LabelCustomisation";
import { getCardName } from "../../helpers/formattingHelpers";

const useStyles = makeStyles({
  printContent: {
    display: "none",
  },
});

interface GradingActionsMenuProps {
  grading: Grading;
}

function GradingActionsMenu(props: GradingActionsMenuProps) {
  const { grading } = props;
  const { id: gradingId, barcode, orderBarcode } = grading;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showLabelCustomisation, setShowLabelCustomisation] = useState(false);
  const barcodeRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const allowedBucketsCustomPrint = [
    "Encapsulating",
    "Label Engraving",
    "Quality Checkpoint 2",
    "Encapsulating/Quality Checkpoint 2",
    "Shipping/Done",
    "Shipping",
    "Done",
  ];

  const pythonLabel : PythonLabel | null = orderItemToPythonLabel(grading);

  const handlePrint = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const handleDownloadPythonLabel = (_ : React.MouseEvent<HTMLLIElement>) => {
    if(pythonLabel !== null)
      downloadPythonLabels(pythonLabel.certNumber, [pythonLabel]);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!gradingId) return null;

  const cardName = getCardName(grading);

  return (
    <>
      <Button
        aria-controls="grading-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="grading-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose()}>
          <AddNoteButton gradingId={gradingId}>Add note</AddNoteButton>
        </MenuItem>
        <MenuItem>
          <ReferToSupervisorButton
            gradingId={gradingId}
            onComplete={handleClose}
          />
        </MenuItem>
        <MenuItem onClick={handlePrint}>Print Barcode</MenuItem>
        {Number.isFinite(grading.finalGrade) &&
        allowedBucketsCustomPrint.includes(grading.bucket) ? (
          <MenuItem onClick={() => setShowLabelCustomisation(true)}>
            Print Custom Label
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleDownloadPythonLabel} disabled={!pythonLabel}>
          Download Label
        </MenuItem>
      </Menu>
      {showLabelCustomisation ? (
        <LabelCustomisation
          grading={grading}
          cancelAction={() => setShowLabelCustomisation(false)}
        />
      ) : null}
      <div className={classes.printContent}>
        <div ref={barcodeRef}>
          <GradingBarcode
            barcode={barcode}
            order={orderBarcode}
            cardName={cardName}
            slabType={grading.slabType}
          />
        </div>
      </div>
    </>
  );
}

export default GradingActionsMenu;
