import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { useCsvExport } from "../../hooks/catalogHooks";

function ExportCsvButton({ gameCode, setName }: any) {
  const { data, mutateAsync, isMutating } = useCsvExport();

  const handleGenerateExport = async () => {
    await mutateAsync({ gameCode: gameCode, setName: setName });
  };

  const convertToSlug = (text: string) => {
    return text.toLowerCase().split(" ").join("-");
  };

  useEffect(() => {
    if (data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${convertToSlug(gameCode)}-${convertToSlug(
        setName
      )}-${new Date().getTime()}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }, [data, gameCode, setName]);

  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      onClick={handleGenerateExport}
      disabled={isMutating}
    >
      Export CSV
    </Button>
  );
}

export default ExportCsvButton;
