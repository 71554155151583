export type OptionalParamsObject = {
  [key: string]: string | string[] | number | boolean | undefined;
};

export const buildQueryFromOptionalParams = (
  params: OptionalParamsObject
): string =>
  Object.entries(params)
    .map(([key, value]) => (value !== undefined ? `&${key}=${value}` : ""))
    .join("");

export const sanitizeURISlug = (slug: string | undefined) => {
  if (!slug) {
    return slug;
  }
  return slug.replace('/', '%2F').replace('&', '%26');
}
