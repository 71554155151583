import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchOrders,
  fetchOrderByBarcode,
  AddCardRequest,
  addCardToOrder,
} from "../api/order";
import { useAlert } from "./alertHooks";

type OrderSearchParams = {
  bucket?: string;
  barcode?: string;
  customerEmail?: string;
  shopifyId?: string;
  certNumber?: string;
};

export const useFetchOrders = (
  offset: number,
  limit: number,
  order: AscendingOrder,
  orderBy?: string,
  searchParams?: OrderSearchParams
) =>
  useQuery(
    [
      "orders",
      {
        offset,
        limit,
        order,
        orderBy,
        ...searchParams,
      },
    ],
    () =>
      fetchOrders({
        offset,
        limit,
        order,
        orderBy,
        ...searchParams,
      }),
    {
      keepPreviousData: true,
    }
  );

export const useFetchOrderByBarcode = (barcode?: string) =>
  useQuery(["order", { barcode }], () => fetchOrderByBarcode(barcode!), {
    enabled: barcode !== undefined,
  });

export const useAddCardToOrder = (orderId: number) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlert();

  return useMutation(
    (cardData: AddCardRequest) => addCardToOrder(orderId, cardData),
    {
      onSuccess: (updatedOrder) => {
        // Update the orders cache
        queryClient.setQueryData<Order[]>(["orders"], (prev) => {
          if (prev) {
            return prev.map((order) =>
              order.id === orderId ? updatedOrder : order
            );
          }
          return [updatedOrder];
        });
        addAlert("Card added successfully", "success");
      },
      onError: () => {
        addAlert("Failed to add card", "error");
      },
      onSettled: () => {
        // Invalidate and refetch orders
        queryClient.invalidateQueries("orders", { refetchInactive: true });
      },
    }
  );
};
