export enum ActionType {
  CHANGE_PAGE = "CHANGE_PAGE",
  CHANGE_BUCKET = "CHANGE_BUCKET",
  SEARCH_SET_NAME = "SEARCH_SET_NAME",
  SEARCH_CARD_NAME = "SEARCH_CARD_NAME",
  SEARCH_CARD_NUMBER = "SEARCH_CARD_NUMBER",
}

export type Action =
  | { type: ActionType.CHANGE_PAGE; page: number }
  | { type: ActionType.CHANGE_BUCKET; bucket: string }
  | { type: ActionType.SEARCH_SET_NAME; search: string }
  | { type: ActionType.SEARCH_CARD_NAME; search: string }
  | { type: ActionType.SEARCH_CARD_NUMBER; search: string };

export type State = {
  page: number;
  bucket?: string;
  setName?: string;
  cardName?: string;
  cardNumber?: string;
};

export const gradingReducer = (state: State, action: Action): State => {
  console.log(action);
  switch (action.type) {
    case ActionType.CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case ActionType.CHANGE_BUCKET:
      return {
        page: 0,
        bucket: action.bucket,
      };
    case ActionType.SEARCH_SET_NAME:
      return {
        page: 0,
        setName: action.search,
      };
    case ActionType.SEARCH_CARD_NAME:
      return {
        page: 0,
        cardName: action.search,
      };
    case ActionType.SEARCH_CARD_NUMBER:
      return {
        page: 0,
        cardNumber: action.search,
      };
    default:
      return state;
  }
};
