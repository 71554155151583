import QRCode from "react-qr-code";
import {
  scoreToArc,
  reformatScore,
  scoreToColour,
  getFinishAndRarity,
  getFrontLine2Text,
  getBackLine5Text,
} from "../../helpers/labelHelpers";
import { extractGradingData } from "../../helpers/gradingHelpers";
import { gradientColours, popReportUrl } from "../../config/labels";
import LabelTemplateStandardLogo from "./LabelTemplateStandardLogo";
import LabelTemplateFABWorldsGradeLogo from "./LabelTemplateFABWorldsGradeLogo";

interface LabelTemplateProps {
  grading: Grading;
  cardNameFrontSizeOverride?: number;
  cardNameBackSizeOverride?: number;
  cardDetailsFrontSizeOverride?: number;
  cardDetailsBackSizeOverride?: number;
  cardNameOverride?: string;
  cardFrontLine2Override?: string;
  cardBackLine2Override?: string;
  cardBackLine3Override?: string;
  cardBackLine4Override?: string;
  cardBackLine5Override?: string;
  gradeIdOverride?: string;
  flipReverse?: boolean;
  worldsLogos?: boolean;
  etcher?: boolean;
}

function LabelTemplate(props: LabelTemplateProps) {
  const {
    grading,
    cardNameFrontSizeOverride = undefined,
    cardNameBackSizeOverride = undefined,
    cardDetailsFrontSizeOverride = undefined,
    cardDetailsBackSizeOverride = undefined,
    cardNameOverride = undefined,
    cardFrontLine2Override = undefined,
    cardBackLine2Override = undefined,
    cardBackLine3Override = undefined,
    cardBackLine4Override = undefined,
    cardBackLine5Override = undefined,
    gradeIdOverride = undefined,
    flipReverse = false,
    worldsLogos = false,
    etcher = false,
  } = props;

  const {
    // cardName,
    game,
    gradedId,
    subgradeRequired,
    centeringGrade,
    cornersGrade,
    edgesGrade,
    surfaceGrade,
    finalGrade: finalGradeNumber,
    grade: finalGradeName,
  } = grading;

  const gradingDetails = extractGradingData(grading);
  const frontLine2 = getFrontLine2Text(gradingDetails);
  const { cardName, setName } = gradingDetails;
  const cardBackLine4 = getFinishAndRarity(gradingDetails);
  const cardBackLine5 = getBackLine5Text(gradingDetails);

  const fullGold =
    centeringGrade === 10 &&
    cornersGrade === 10 &&
    edgesGrade === 10 &&
    surfaceGrade === 10;

  const authentic =
    centeringGrade === 0 &&
    cornersGrade === 0 &&
    edgesGrade === 0 &&
    surfaceGrade === 0;

  // const gemMint10 =
  //   [centeringGrade, cornersGrade, edgesGrade, surfaceGrade].filter(
  //     grade => grade === 10 || grade === 9.5
  //   ).length === 4;

  const colour = fullGold ? "fullGold" : scoreToColour(finalGradeNumber);
  const gradient = gradientColours[colour];

  const finalGradeFontSizes = {
    Pristine: "5px",
    "Gem Mint": "5px",
    Mint: "6px",
    "NM-Mint+": "5px",
    "NM-Mint": "5.5px",
    "Near Mint+": "4px",
    "Near Mint": "4.5px",
    "EX-NM": "6px",
    Excellent: "4.5px",
    "Very Good": "4.2px",
    Good: "6px",
    Fair: "6px",
    Poor: "6px",
    Authentic: "4.5px",
  } as Record<string, string>;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 209.7 144"
    >
      <defs>
        <style>
          {`
      @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Scheherazade&display=swap');

      g#labelFront, g#labelReverse, .labelInvertable {
        filter: ${etcher ? "invert(1)" : "inherit"};
      }

      .subgradeLabel, .subgradeScore, .gradingId, .cardName, .cardDetails, .subgradeLabel {
        fill: #fff;
      }

      .finalGradeScore {
        font-size: 34px;
        letter-spacing:0.02rem;
      }

      .finalGradeScore, .cardName {
        font-family: Scheherazade-Regular, Scheherazade;
      }

      .subgradeLabel, .finalGradeName, {
        font-family: ProximaNova-Semibold, Proxima Nova, 'Montserrat';
      }

      .finalGradeName {
        font-size: ${
          authentic
            ? finalGradeFontSizes["Authentic"]
            : finalGradeFontSizes[finalGradeName]
        };
        font-weight: 600;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }

      .subgradeLabel {
        font-size: 3.69px;
        font-weight: 600;
        letter-spacing: 0.1em;
      }

      .baseArc {
        fill: #2d2d2d;
      }

      .surfaceGradeArc {
        fill: url(#linear-gradient-2);
      }

      .edgesGradeArc {
        fill: url(#linear-gradient-3);
      }

      .cornersGradeArc {
        fill: url(#linear-gradient-4);
      }

      .centeringGradeArc {
        fill: url(#linear-gradient-5);
      }

      .cardName {
        font-size: ${cardNameBackSizeOverride ?? 16}px;
      }

      .cardName--large {
        font-size: ${cardNameFrontSizeOverride ?? 16}px;
      }

      .cardName--extraLarge {
        font-size: ${cardNameFrontSizeOverride ?? 21}px
        fill: #231f20;
        mix-blend-mode: multiply;
      }

      .cardDetails {
        font-family: ProximaNova, Proxima Nova;
        font-size: ${cardDetailsBackSizeOverride ?? 7}px;
      }

      .cardDetails--large {
        font-size: ${cardDetailsFrontSizeOverride ?? 7}px;
      }

      .cardDetails--extraLarge {
        font-size: ${cardDetailsFrontSizeOverride ?? 8}px;
        fill: #231f20;
        mix-blend-mode: multiply;
      }

      .subgrades {
        display: ${subgradeRequired ? "block" : "none"}
      }

      .rearDetails--noSubgrade {
        transform: scale(1.04) translate(-10px, 10px);
      }

      .subgradeScore,
      .cardNameFront,
      .cardDetailsFront,
      .subgradeLabel,
      .text,
      .cardNameBack,
      .gradingId {
        fill: ${fullGold ? "#050505" : "#fff"};
      }

      .baseArc {
        fill: #2d2d2d;
      }

      .backgroundLogo {
        fill: ${fullGold ? "#a5874fcc" : "#2d2d2d"}
      }

      .backgroundFront {
        ${fullGold ? "fill: url(#linear-gradient);" : ""}
      }

      .backgroundRear {
        fill: ${fullGold ? "none" : ""}
      }

      .backFrameGradient {
        fill: ${
          etcher
            ? "white"
            : fullGold
            ? "#050505"
            : authentic
            ? "silver"
            : "url(#linear-gradient)"
        };
      }

      .frontFrameGradient {
        fill: ${
          etcher
            ? "white"
            : fullGold
            ? "#050505"
            : authentic
            ? "silver"
            : "url(#linear-gradient-2)"
        };
      }

      .cornerLogoGradient1 {
        fill: ${
          fullGold
            ? "#050505"
            : authentic
            ? "silver"
            : "url(#linear-gradient-3)"
        };
      }

      .cornerLogoGradient2 {
        fill: ${
          fullGold
            ? "#050505"
            : authentic
            ? "silver"
            : "url(#linear-gradient-4)"
        };
      }

      .qrCodeBackground {
        fill: ${fullGold ? "#BF9F65" : "black"}
      }

      .fullGoldBackground {
        fill: url(#linear-gradient);
      }

      .subgradeLabel {
        font-size: 3.75px;
      }

      .finalGradeName,
      .text,
      .subgradeLabel {
        font-family: NotoSans, Noto Sans;
      }

      .subgradeLabel {
        letter-spacing: 0.07em;
      }

      .subgradeScore {
        font-size: 8px;
      }

      .subgradeScore,
      .finalGradeScore,
      .cardNameBack,
      .cardNameFront {
        font-family: NotoSans-Bold, Noto Sans;
        font-weight: 700;
      }

      .gradingArc {
        fill: ${fullGold ? "#050505" : "url(#linear-gradient-6)"};
      }

      .finalGradeScore {
        font-size: 21px;
        letter-spacing:0.02rem;
      }

      .finalGradeScore,
      .finalGradeName {
        ${fullGold ? "fill: #d5b262;" : ""}
      }

      .finalGradeName {
        ${fullGold ? "text-transform: none;" : ""}
        ${fullGold ? "letter-spacing: 0;" : ""}
      }

      .worldsLogo {
        fill: ${fullGold ? "#bf9f65" : "#2d2d2d"};
        isolation:isolate;
        opacity:.65;
      }

      .finalGradeName,
      .text {
        font-size: ${
          authentic
            ? finalGradeFontSizes["Authentic"]
            : finalGradeFontSizes[finalGradeName]
        };
      }

      .cardNameFront {
        font-size: ${cardNameFrontSizeOverride ?? 10}px;
      }

      .cardNameBack {
        font-size: ${cardNameBackSizeOverride ?? 8}px;
      }

      .cardDetailsFront {
        font-size: ${cardDetailsFrontSizeOverride ?? 6}px
      }

      .cardDetailsBack {
        font-size: ${cardDetailsBackSizeOverride ?? 6}px
      }

      .gradingId {
        font-size: 5px;
      `}
        </style>
        <linearGradient
          id="linear-gradient"
          x1="406.15"
          y1="96.16"
          x2="332.64"
          y2="41.53"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={gradient.stopColour0} />
          <stop offset="1" stopColor={gradient.stopColour1} />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="161.81"
          y1="103.11"
          x2="122.39"
          y2="56.68"
          href="#linear-gradient"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="114.78"
          y1="132.56"
          x2="75.35"
          y2="86.12"
          href="#linear-gradient"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="118.99"
          y1="128.98"
          x2="79.57"
          y2="82.54"
          href="#linear-gradient"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="147.32"
          y1="104.93"
          x2="107.9"
          y2="58.49"
          href="#linear-gradient"
          xlinkHref="#linear-gradient"
        />
      </defs>
      {fullGold ? (
        <rect
          id="fullGoldBackground"
          className="fullGoldBackground"
          x="0"
          y="0"
          width="209.76"
          height="142"
        />
      ) : null}
      <g
        id="LabelReverse"
        className="labelInvertable"
        transform="rotate(180) translate(-428.2 -130.5)"
      >
        <g transform={`rotate(${flipReverse ? 180 : 0}, 323.3 32 )`}>
          <g id="Background">
            <rect
              x="218.43"
              width="209.76"
              height="72"
              className="backgroundRear"
              transform={`translate(0 ${flipReverse ? 3.5 : -11.5})`}
            />
            <rect
              x="218.43"
              width="209.76"
              height="65"
              className="backgroundRear"
              transform={`translate(0 ${flipReverse ? -1.6 : 1})`}
            />
            {/*{ worldsLogos ? <LabelTemplateFABWorldsLogo /> : <LabelTemplateStandardLogo /> }*/}
            <LabelTemplateStandardLogo />
            <rect
              id="QR_Code_Black"
              data-name="QR Code Black"
              className="qrCodeBackground"
              x="376.25"
              y="13.81"
              width="38.32"
              height="44.49"
            />
            <g
              id="QR_Code"
              data-name="QR Code"
              transform="translate(379,16.7) scale(0.13)"
            >
              <QRCode
                value={`${popReportUrl}/${gradeIdOverride ?? gradedId}`}
                bgColor={fullGold ? "#FFFFFF00" : "#000000"}
                fgColor={fullGold ? "#000000" : "#FFFFFF"}
                level="M"
              />
            </g>
            <text
              id="ID_Number"
              data-name="ID Number"
              className="gradingId"
              textAnchor="middle"
              transform="translate(395.5 55.48)"
            >
              {gradeIdOverride ?? gradedId}
            </text>
          </g>
          <path
            id="Back_Frame"
            data-name="Back Frame"
            className="backFrameGradient"
            d="M221.24,2.79V58H414V2.79ZM412.81,56.84H222.46V4.06H412.81Z"
            transform="translate(5.67 5.67)"
          />
          <g id="Card_Details-Back" data-name="Card Details">
            <text
              id="Card_Details_2-1"
              data-name="Card Details - Game"
              className="text cardDetailsBack"
              textAnchor="left"
              transform="translate(238.94 32.9)"
            >
              {cardBackLine2Override ?? game}
            </text>
            <text
              id="Card_Details_2-2"
              data-name="Card Details - Set"
              className="text cardDetailsBack"
              textAnchor="left"
              transform="translate(238.94 39.3)"
            >
              {cardBackLine3Override ?? setName}
            </text>
            <text
              id="Card_Details_2-3"
              data-name="Card Details - Rarity"
              className="text cardDetailsBack"
              textAnchor="left"
              transform="translate(238.94 45.8)"
            >
              {cardBackLine4Override ?? cardBackLine4}
            </text>
            <text
              id="Card_Details_2-4"
              data-name="Card Details - Year"
              className="text cardDetailsBack"
              textAnchor="left"
              transform="translate(238.94 52.2)"
            >
              {cardBackLine5Override ?? cardBackLine5}
            </text>

            <g id="Card_Name" data-name="Card Name">
              <text
                className="cardNameBack"
                transform="translate(238.94 25.36)"
              >
                {cardNameOverride ?? cardName}
              </text>
            </g>
          </g>
        </g>
      </g>

      <g
        id="LabelFront"
        className="labelInvertable"
        transform="translate(0 -3.5)"
      >
        {!etcher && (
          <g id="Background">
            <rect
              width="209.76"
              height="65"
              transform="translate(0 3.5)"
              className="backgroundFront"
            />
          </g>
        )}
        <g id="Front_Frame" data-name="Front Frame">
          <polygon
            className="frontFrameGradient"
            points="23.77 8.46 23.77 9.73 165.14 9.73 165.14 62.51 9.7 62.51 9.7 15.33 8.48 15.33 8.48 63.66 79.55 63.66 79.6 63.66 94.23 63.66 95.04 63.66 201.28 63.66 201.28 8.46 23.77 8.46"
          />
          <path
            className="cornerLogoGradient1"
            d="M4.06,6.09H5.27A1.66,1.66,0,0,0,6.8,5.28v0A2.53,2.53,0,0,0,9.45,7.87a2.33,2.33,0,0,0,2.21-1.34L10.55,6a1.2,1.2,0,0,1-1.1.73A1.37,1.37,0,0,1,8.1,5.3,1.36,1.36,0,0,1,9.45,3.86a1.21,1.21,0,0,1,1.1.72l1.11-.52A2.31,2.31,0,0,0,9.45,2.72,2.56,2.56,0,0,0,7,4.28,1.6,1.6,0,0,0,5.27,2.81H2.78v5H4.06Zm0-2.2h1A.56.56,0,1,1,5.09,5h-1Z"
            transform="translate(5.67 5.67)"
          />
          <path
            className="cornerLogoGradient2"
            d="M14.06,7.87a2.88,2.88,0,0,0,2.2-1V5h-2.4V6H15v.42a1.57,1.57,0,0,1-.94.3A1.38,1.38,0,0,1,12.67,5.3a1.37,1.37,0,0,1,1.39-1.44,1.23,1.23,0,0,1,1,.59l1.08-.57a2.35,2.35,0,0,0-2.13-1.16A2.55,2.55,0,0,0,11.37,5.3,2.54,2.54,0,0,0,14.06,7.87Z"
            transform="translate(5.67 5.67)"
          />
        </g>
        {!authentic ? (
          <g id="Subgrades" className="subgrades">
            <g id="Surface">
              <g id="Surface-2" data-name="Surface">
                <text
                  className="subgradeLabel"
                  transform="translate(133.16 57.16)"
                >
                  SURFACE
                </text>
                {!etcher && (
                  <path
                    id="Base_Arc"
                    data-name="Base Arc"
                    className="baseArc"
                    d="M151.4,51.54H150a13.75,13.75,0,1,0-27.49,0H121a15.2,15.2,0,1,1,30.4,0Z"
                    transform="translate(5.67 5.67)"
                  />
                )}
              </g>
              <g id="_5" data-name="5">
                <text
                  className="subgradeScore"
                  transform="translate(141.76 52.67)"
                  textAnchor="middle"
                >
                  {reformatScore(surfaceGrade)}
                </text>
                <path
                  id="_5-arc"
                  data-name="5-arc"
                  className="gradingArc"
                  d={scoreToArc(surfaceGrade, 141.76, 52.67)}
                  transform="translate(15.5 4.5)"
                />
              </g>
            </g>
            <g id="Edges">
              <g id="Edges-2" data-name="Edges">
                <text
                  className="subgradeLabel"
                  transform="translate(99.21 57.16)"
                >
                  EDGES
                </text>
                {!etcher && (
                  <path
                    id="Base_Arc-2"
                    data-name="Base Arc"
                    className="baseArc"
                    d="M115.11,51.54H113.66a13.75,13.75,0,1,0-27.49,0H84.71a15.2,15.2,0,1,1,30.4,0Z"
                    transform="translate(5.67 5.67)"
                  />
                )}
              </g>
              <g id="_7" data-name="7">
                <text
                  className="subgradeScore"
                  textAnchor="middle"
                  transform="translate(105.36 52.67)"
                >
                  {reformatScore(edgesGrade)}
                </text>
                <path
                  id="_7-arc"
                  data-name="7-arc"
                  className="gradingArc"
                  d={scoreToArc(edgesGrade, 105.36, 52.67)}
                  transform="translate(15.5 4.5)"
                />
              </g>
            </g>
            <g id="Corners">
              <g id="Corners-2" data-name="Corners">
                <text
                  className="subgradeLabel"
                  transform="translate(60.95 57.16)"
                >
                  CORNERS
                </text>
                {!etcher && (
                  <path
                    id="Base_Arc-3"
                    data-name="Base Arc"
                    className="baseArc"
                    d="M79.73,51.54H78.27a13.75,13.75,0,1,0-27.49,0H49.33a15.2,15.2,0,1,1,30.4,0Z"
                    transform="translate(5.67 5.67)"
                  />
                )}
              </g>
              <g id="_6" data-name="6">
                <text
                  className="subgradeScore"
                  textAnchor="middle"
                  transform="translate(69.88 52.67)"
                >
                  {reformatScore(cornersGrade)}
                </text>
                <path
                  id="_6-arc"
                  data-name="6-arc"
                  className="gradingArc"
                  d={scoreToArc(cornersGrade, 69.88, 52.67)}
                  transform="translate(15.5 4.5)"
                />
              </g>
            </g>
            <g id="Centering">
              <g id="Centering-2" data-name="Centering">
                <text
                  className="subgradeLabel"
                  transform="translate(21.4 57.16)"
                >
                  CENTERING
                </text>
                {!etcher && (
                  <path
                    id="Base_Arc-4"
                    data-name="Base Arc"
                    className="baseArc"
                    d="M42.51,51.54H41.06a13.75,13.75,0,1,0-27.49,0H12.11a15.2,15.2,0,1,1,30.4,0Z"
                    transform="translate(5.67 5.67)"
                  />
                )}
              </g>
              <g id="_6-2" data-name="6">
                <text
                  className="subgradeScore"
                  textAnchor="middle"
                  transform="translate(32.76 52.67)"
                >
                  {reformatScore(centeringGrade)}
                </text>

                <path
                  id="_6-arc-2"
                  data-name="6-arc"
                  className="gradingArc"
                  d={scoreToArc(centeringGrade, 32.76, 52.67)}
                  transform="translate(15.5 4.5)"
                />
              </g>
            </g>
          </g>
        ) : null}
        <g id="EX-NM-6">
          <text
            className="finalGradeScore"
            textAnchor="middle"
            transform="translate(183 47.28)"
          >
            {authentic ? "A" : finalGradeNumber}
          </text>
          <text
            className="finalGradeName"
            textAnchor="middle"
            transform="translate(183 28.68)"
          >
            {authentic ? "Authentic" : finalGradeName}
          </text>
        </g>
        {worldsLogos ? <LabelTemplateFABWorldsGradeLogo /> : ""}
        <g
          id="FrontDetails"
          transform={`translate(0 ${
            authentic ? 8.5 : subgradeRequired ? 0 : 5.5
          })`}
        >
          <text
            id="Card_Name-2"
            data-name="Card Name"
            className="cardNameFront"
            textAnchor="middle"
            transform="translate(87.79 26.83)"
          >
            {cardNameOverride ?? cardName}
          </text>
          <text
            id="Card_Details-3"
            data-name="Card Details"
            className="text cardDetailsFront"
            textAnchor="middle"
            transform={`translate(87.79 ${subgradeRequired ? 34.83 : 36.83})`}
          >
            {cardFrontLine2Override ?? frontLine2}
          </text>
        </g>
      </g>
    </svg>
  );
}

export default LabelTemplate;
