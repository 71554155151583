import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFetchGameInfo } from "../../hooks/cardHooks";
import { getFinishByGame } from "../../helpers/cardHelpers";

export const LANGUAGE_OPTIONS = ["English", "Japanese"];

const useStyles = makeStyles({
  root: {
    marginTop: "2rem"
  },
  imageWrapper: {
    padding: "1rem",
    backgroundColor: "#fff",
    minHeight: "20rem"
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat"
  }
});

interface CardDetailsProps {
  card: SearchCard;
  game: string;
  setGame: React.Dispatch<React.SetStateAction<string>>;
  setName: string;
  setSetName: React.Dispatch<React.SetStateAction<string>>;
  cardName: string;
  setCardName: React.Dispatch<React.SetStateAction<string>>;
  cardNumber: string;
  setCardNumber: React.Dispatch<React.SetStateAction<string>>;
  finish: string;
  setFinish: React.Dispatch<React.SetStateAction<string>>;
  cardValue: string;
  setCardValue: React.Dispatch<React.SetStateAction<string>>;
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  rarity: string;
  setRarity: React.Dispatch<React.SetStateAction<string>>;
  cardError: string;
  setCardError: React.Dispatch<React.SetStateAction<string>>;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

function CardDetails(props: CardDetailsProps) {
  const {
    card,
    game,
    setGame,
    setName,
    setSetName,
    cardName,
    setCardName,
    cardNumber,
    setCardNumber,
    finish,
    setFinish,
    cardValue,
    setCardValue,
    year,
    setYear,
    rarity,
    setRarity,
    cardError,
    setCardError,
    language,
    setLanguage
  } = props;

  const classes = useStyles();
  const { isLoading: isLoadingGames, data: gameInfo } = useFetchGameInfo();

  const finishes = getFinishByGame(gameInfo, card.gameCode);
  const hasImage = !!card.image;

  return (
    <Grid container className={classes.root}>
      <Grid xs={12} lg={hasImage ? 8 : 12} item>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Game</TableCell>
                <TableCell>
                  <TextField
                    required
                    name="game"
                    fullWidth
                    value={game}
                    onChange={event => setGame(event.target.value)}
                    placeholder="e.g. Magic: the Gathering"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Set Name</TableCell>
                <TableCell>
                  <TextField
                    required
                    name="setName"
                    fullWidth
                    value={setName}
                    onChange={event => setSetName(event.target.value)}
                    placeholder="e.g. Core Set 2020"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Card Name</TableCell>
                <TableCell>
                  <TextField
                    required
                    name="cardName"
                    fullWidth
                    value={cardName}
                    onChange={event => setCardName(event.target.value)}
                    placeholder="e.g. Fatal Push"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Card Number</TableCell>
                <TableCell>
                  {" "}
                  <TextField
                    required
                    name="cardNumber"
                    fullWidth
                    value={cardNumber}
                    onChange={event => setCardNumber(event.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Finish</TableCell>
                <TableCell>
                  {" "}
                  <Autocomplete
                    id="finish"
                    fullWidth
                    freeSolo
                    value={finish}
                    onChange={(event: any, newValue: string | null) => {
                      setFinish(newValue || "");
                    }}
                    disabled={isLoadingGames}
                    options={finishes.map(option => option.printing)}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        onChange={event => setFinish(event.target.value)}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Year</TableCell>
                <TableCell>
                  {" "}
                  <TextField
                    required
                    name="year"
                    fullWidth
                    value={year}
                    onChange={event => setYear(event.target.value)}
                    placeholder="2021"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Language</TableCell>
                <TableCell>
                  <Autocomplete
                    id="language"
                    freeSolo
                    value={language}
                    options={LANGUAGE_OPTIONS}
                    renderInput={params => <TextField {...params} />}
                    onChange={(event, value) => value && setLanguage(value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rarity</TableCell>
                <TableCell>
                  {" "}
                  <TextField
                    required
                    name="rarity"
                    fullWidth
                    value={rarity}
                    onChange={event => setRarity(event.target.value)}
                    placeholder="rare"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Card Error</TableCell>
                <TableCell>
                  {" "}
                  <TextField
                    required
                    name="cardError"
                    fullWidth
                    value={cardError}
                    onChange={event => setCardError(event.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Card value ($)</TableCell>
                <TableCell>
                  {" "}
                  <TextField
                    required
                    name="cardValue"
                    fullWidth
                    value={cardValue}
                    onChange={event => setCardValue(event.target.value)}
                    placeholder="100"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {hasImage ? (
        <Grid item xs={12} lg={4} className={classes.imageWrapper}>
          <div
            className={classes.image}
            style={{ backgroundImage: `url("${card.image}"` }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default CardDetails;
