import { useQueryClient, useQuery, useMutation } from "react-query";
import { fetchGameInfo, fetchSetInfo, updateCardInfo } from "../api/card";

const LONG_LIFE_DATA_STALE_TIME = 30 * 60 * 1000; // ms

export const useUpdateCardInfo = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (cardInfo: CardInfoVariables) => updateCardInfo(cardInfo),
    {
      onSuccess: (
        updatedGrading: Grading,
        updatedCardInfo: CardInfoVariables
      ) => {
        queryClient.setQueryData<Grading | undefined>(
          ["grading", { gradingId: updatedGrading.id }],
          updatedGrading
        );
        queryClient.setQueryData<Grading[]>(["gradings"], (prev): Grading[] => {
          if (prev) {
            return prev.map((grading) =>
              grading.id === updatedCardInfo.gradingItemId
                ? updatedGrading
                : grading
            );
          }
          return [updatedGrading];
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("gradings", { refetchInactive: true });
        queryClient.invalidateQueries("orders", { refetchInactive: true });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useFetchGameOptions = () => {
  const { isLoading, data, error } = useQuery(["gameInfo"], fetchGameInfo, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });
  if (!isLoading && !error && data) {
    let names = data?.map((game) => ({
      ...game,
      found: true,
    }));
    return names;
  }
  return [];
};

export const useFetchGameInfo = () =>
  useQuery(["gameInfo"], fetchGameInfo, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

export const useFetchSetInfo = (game: string, setName: string) =>
  useQuery(["setInfo", { game, setName }], () => fetchSetInfo(game, setName), {
    enabled: setName.length > 0,
  });

export const useGetGameInfo = (gameCode: string): GameInfo => {
  const { isLoading, data, error } = useQuery(["gameInfo"], fetchGameInfo, {
    staleTime: LONG_LIFE_DATA_STALE_TIME,
  });

  if (!isLoading && !error && data) {
    const result = data?.find(
      (game) => game.gameCode.toLowerCase() === gameCode?.toLowerCase()
    );
    if (result) {
      return {
        ...result,
      };
    }
  }
  return {
    id: 0,
    game: "",
    gameCode: "",
    sets: [],
    finish: [],
    cardsGraded: null,
    gameImage: "",
    description: "",
  };
};
