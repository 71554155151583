import { formatCardNumber } from "./formattingHelpers";

const roundDown = (num: number): number => {
  // Integers only from 7 downwards
  if (num < 7.25) {
    return Math.floor(num);
  }
  // Half steps from 7.5 upwards
  return Math.floor(num * 2) / 2;
};

const roundUp = (num: number): number => {
  // Assuming number is above 8, so half steps
  return Math.ceil(num * 2) / 2;
};

export const calculateFinalGrade = (
  subgrade1: number | string,
  subgrade2: number | string,
  subgrade3: number | string,
  subgrade4: number | string
) => {
  const subgrades = [
    Number(subgrade1),
    Number(subgrade2),
    Number(subgrade3),
    Number(subgrade4)
  ].sort((a, b) => b - a); // Reverse numeric sort

  const isGemMint10 =
    subgrades.filter(grade => grade === 10).length === 2 &&
    subgrades.filter(grade => grade === 9.5).length === 2;

  if (isGemMint10) return 9.75; // 9.75 is the same as 10 for the purposes of the final grade

  // The Second Highest subgrade
  const secondHighest = subgrades[1];
  // The Third Highest subgrade + 0.5, rounded up if the grade would than equal
  // below a 7
  const thirdHighestPlusHalf =
    subgrades[2] + 0.5 >= 7
      ? roundUp(subgrades[2] + 0.5)
      : roundUp(subgrades[2] + 1);
  // The Forth Highest subgrade +1
  const forthHighestPlusOne = subgrades[3] + 1;
  const findLowestIn = [
    secondHighest,
    thirdHighestPlusHalf < 7
      ? roundUp(thirdHighestPlusHalf)
      : thirdHighestPlusHalf,
    forthHighestPlusOne
  ];

  // If the 4th subgrade is 0.5 lower than the 3rd subgrade...
  if (subgrades[2] - subgrades[3] === 0.5) {
    // 4a The Third Highest subgrade if the 4th subgrade is 0.5 lower than the 3rd subgrade
    findLowestIn.push(subgrades[2]);
  } else {
    //  The Average of the 3rd & 4th Subgrade rounded UP only if the grade would
    // then equal 9, 9.5 or 10.
    const threeAndFourAverage = (subgrades[2] + subgrades[3]) / 2;
    if (roundUp(threeAndFourAverage) >= 9) {
      findLowestIn.push(roundUp(threeAndFourAverage));
    }
    // The Average of the 3rd and 4th Subgrade rounded DOWN +0.5 if the grade would
    // then be equal to a 7.5, 8 or 8.5
    else if (roundDown(threeAndFourAverage) + 0.5 > 7) {
      findLowestIn.push(roundDown(threeAndFourAverage) + 0.5);
    }
    // The Average of the 3rd and 4th Subgrade rounded DOWN +1 if the grade would
    // then equal below a 7.5
    else {
      findLowestIn.push(roundDown(threeAndFourAverage) + 1);
    }
  }

  // The average of all 4 sub grades +0.125 rounded down
  const average =
    (subgrades[0] + subgrades[1] + subgrades[2] + subgrades[3]) / 4;
  findLowestIn.push(roundDown(average + 0.125));

  // The overall grade will be the same as the two lowest subgrades that equal the same
  // value for 6 and below
  if (subgrades[2] <= 6 && subgrades[2] === subgrades[3]) {
    findLowestIn.push(subgrades[2]);
  }

  console.log(findLowestIn);
  return Math.min(...findLowestIn);
};

export const extractGradingData = (grading: Grading) => {
  const { gameProduct } = grading;
  let cardName = grading?.cardName ?? gameProduct?.cardName;
  if (gameProduct?.gameCode === "pkm") {
    cardName = gameProduct?.cardName ?? grading?.cardName;
  }
  return {
    setName: grading?.setName ?? gameProduct?.setName,
    cardName: cardName,
    cardNumber: formatCardNumber(grading?.cardNumber ?? gameProduct?.number),
    image: gameProduct?.image,
    finish: grading.finish,
    releaseDate: gameProduct?.releaseDate,
    releaseYear: grading?.year,
    game: grading?.game ?? gameProduct?.gameCode,
    rarity: grading.rarity ?? gameProduct?.rarity,
    language: grading?.language
  };
};
