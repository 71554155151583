import { useHistory } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { extractGradingData } from "../../helpers/gradingHelpers";
import { useFetchMe } from "../../hooks/userHooks";

const CAN_EDIT_IN_BUCKET = [
  "Receiving",
  "Receiving/Research and ID",
  "Research and ID",
  "Quality Checkpoint 1",
  "Grading",
];

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    padding: "1rem",
    backgroundColor: "#fff",
    minHeight: "20rem",
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  manualEntryRow: {
    backgroundColor: alpha(theme.palette.warning.light, 0.1),
  },
  manualEntryBadge: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    padding: "2px 8px",
    borderRadius: "4px",
    fontSize: "0.75rem",
    marginLeft: theme.spacing(1),
    display: "inline-block",
  },
  manualEntryTable: {
    "& .MuiPaper-root": {
      borderLeft: `2px solid ${theme.palette.warning.main}`,
      borderRight: `2px solid ${theme.palette.warning.main}`,
    },
  },
}));

interface CardInformationProps {
  grading: Grading;
}

function CardInformation(props: CardInformationProps) {
  const { grading } = props;
  const history = useHistory();
  const classes = useStyles();
  const { data: currentUser } = useFetchMe();
  const data = extractGradingData(grading);

  const getReleaseDate = (
    releaseDate: string | null | undefined,
    releaseYear: string | number | undefined
  ) => {
    if (releaseYear) return releaseYear;
    if (!releaseDate) return "Unknown";
    try {
      const date = new Date(releaseDate);
      if (date) {
        return date.getFullYear();
      }
    } catch (error) {
      console.error(error);
    }
    return "Unknown";
  };

  const handleEdit = () => {
    history.push(`/card/${grading.id}`);
  };

  const hasImage = !!data.image;
  const role = currentUser?.role;

  const canEditCard = (userRole: string | undefined, bucket: string) => {
    if (userRole === "admin" || userRole?.toLowerCase() === "super admin" || userRole === "supervisor") return true;
    return CAN_EDIT_IN_BUCKET.includes(bucket);
  };

  return (
    <Grid container>
      <Grid xs={12} lg={hasImage ? 8 : 12} item>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Game</TableCell>
                <TableCell>{grading.game}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Set Name</TableCell>
                <TableCell>{data.setName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Card Name</TableCell>
                <TableCell>
                  {data.cardName}
                  {!grading.gameProduct && (
                    <span className={classes.manualEntryBadge}>
                      Manual Entry
                    </span>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Card Number</TableCell>
                <TableCell>{data.cardNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Finish</TableCell>
                <TableCell>{grading.finish}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rarity</TableCell>
                <TableCell>{grading.rarity}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Language</TableCell>
                <TableCell>{grading?.language || "English"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Release Date</TableCell>
                <TableCell>
                  {getReleaseDate(data.releaseDate, data.releaseYear)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Card Errors</TableCell>
                <TableCell>{grading.cardError || "N/A"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>{grading.orderBarcode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Grading Barcode</TableCell>
                <TableCell>{grading.barcode}</TableCell>
              </TableRow>
              <TableRow>
                {canEditCard(role, grading.bucket) ? (
                  <TableCell colSpan={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => handleEdit()}
                    >
                      Edit card details
                    </Button>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {data.image ? (
        <Grid item xs={12} lg={4} className={classes.imageWrapper}>
          <div
            className={classes.image}
            style={{ backgroundImage: `url("${data.image}"` }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default CardInformation;
