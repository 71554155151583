export enum ActionType {
  CHANGE_PAGE = "CHANGE_PAGE",
  CHANGE_BUCKET = "CHANGE_BUCKET",
  SEARCH_BARCODE = "SEARCH_BARCODE",
  SEARCH_CERT = "SEARCH_CERT",
  SEARCH_EMAIL = "SEARCH_EMAIL",
  SEARCH_ORDER = "SEARCH_ORDER"
}

export type Action =
  | { type: ActionType.CHANGE_PAGE; page: number }
  | { type: ActionType.CHANGE_BUCKET; bucket: string }
  | { type: ActionType.SEARCH_BARCODE; search: string }
  | { type: ActionType.SEARCH_CERT; search: string }
  | { type: ActionType.SEARCH_EMAIL; search: string }
  | { type: ActionType.SEARCH_ORDER; search: string };

export type State = {
  page: number;
  bucket?: string;
  barcode?: string;
  customerEmail?: string;
  orderName?: string;
  certNumber?: string;
};

export const orderReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      };
    case ActionType.CHANGE_BUCKET:
      return {
        page: 0,
        bucket: action.bucket
      };
    case ActionType.SEARCH_BARCODE:
      return {
        page: 0,
        barcode: action.search
      };
    case ActionType.SEARCH_EMAIL:
      return {
        page: 0,
        customerEmail: action.search
      };
    case ActionType.SEARCH_ORDER:
      return {
        page: 0,
        orderName: "%23" + action.search
      };
    case ActionType.SEARCH_CERT:
      return {
        page: 0,
        certNumber: action.search
      };
    default:
      return state;
  }
};
