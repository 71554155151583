import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ScoreIndicator from "../generic/ScoreIndicator";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
  },
  title: {
    color: theme.palette.primary.main,
  },
  finalScore: {
    textAlign: "center",
  },
  finalGrade: {
    backgroundColor: "#444",
    color: "#fff",
    padding: "0.75rem",
    marginBottom: "0.5rem",
    borderRadius: "1rem",
    minWidth: "5rem",
    textAlign: "center",
  },
}));

interface ViewGradingScoreProps {
  centeringGrade?: NullableNumber;
  cornersGrade?: NullableNumber;
  edgesGrade?: NullableNumber;
  surfaceGrade?: NullableNumber;
  finalGrade?: NullableNumber;
  grade?: NullableString;
}

function ViewGradingScore(props: ViewGradingScoreProps) {
  const {
    centeringGrade,
    cornersGrade,
    edgesGrade,
    surfaceGrade,
    finalGrade,
    grade,
  } = props;
  const classes = useStyles();

  const authentic =
    centeringGrade === 0 &&
    cornersGrade === 0 &&
    edgesGrade === 0 &&
    surfaceGrade === 0;

  if (!grade)
    return (
      <Paper className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Grading
        </Typography>
        No grade allocated
      </Paper>
    );

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.title} gutterBottom>
        Grading
      </Typography>
      <Grid container direction="column">
        <Grid container direction="column" alignItems="center">
          <Grid className={classes.finalGrade}>
            <Typography variant="h4" className={classes.finalScore}>
              {authentic ? "A" : finalGrade}
            </Typography>
            <Typography variant="subtitle2">
              {" "}
              {authentic ? "Authentic" : grade}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center">
          <ScoreIndicator label="Centering Grade" score={centeringGrade} />
          <ScoreIndicator label="Corners Grade" score={cornersGrade} />
          <ScoreIndicator label="Edges Grade" score={edgesGrade} />
          <ScoreIndicator label="Surface Grade" score={surfaceGrade} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ViewGradingScore;
