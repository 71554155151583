import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

type DeleteConfirmModalProps = {
  cardInfo: any;
  open: boolean;
  onClose: () => void;
  onConfirm: (data: any) => void;
};

export const DeleteConfirmModal = ({
  cardInfo,
  open,
  onClose,
  onConfirm
}: DeleteConfirmModalProps) => {
  const handleConfirm = () => {
    onConfirm({});
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>Are you sure</DialogTitle>
      <DialogContent>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <p>Are you sure you want to delete this?</p>
        </Box>
      </DialogContent>
      <DialogActions>
        <br />
        <Button
          variant="contained"
          size="small"
          style={{ float: "right" }}
          color="primary"
          onClick={handleConfirm}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
