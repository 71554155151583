import { useState } from "react";

import { Button } from "@material-ui/core";

import EditorModal from "./EditorModal";
import {
  useUpdateCardInfo,
  useUpdatCardImage,
  useDeleteCardInfo
} from "../../hooks/catalogHooks";
import { useAlert } from "../../hooks/alertHooks";

function EditCardButton(props: { data: any; gameInfo: GameInfo }) {
  const { data, gameInfo } = props;

  const { setName, gameCode } = data;
  const [modalOpen, setModalOpen] = useState(false);
  const { mutateAsync, isMutating } = useUpdateCardInfo(gameCode, setName);
  const {
    mutateAsync: mutateImage,
    isMutating: imageIsMutating
  } = useUpdatCardImage(data.id, gameCode, setName);
  const { mutateAsync: deleteCardInfo } = useDeleteCardInfo();
  const { addAlert } = useAlert();

  const handleOnSave = async (localData: any) => {
    await mutateAsync(localData);
    setModalOpen(false);
  };

  const handleFileChange = async (file: any) => {
    await mutateImage(file);
  };

  const handleOnDelete = async (payload: any) => {
    const { selectedMigration } = payload;

    const deletePayload = {
      id: data.id,
      migration: { ...data, gameProductDto: selectedMigration }
    };

    deleteCardInfo(deletePayload)
      .then(() => {
        addAlert("Delete successful", "success");
      })
      .catch(() => {
        addAlert("Delete failed", "error");
      });
  };

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Edit</Button>
      <EditorModal
        title={`Update ${data["setName"]}`}
        data={data}
        editableKeys={[
          {
            key: "gameCode",
            type: "string"
          },
          {
            key: "binderId",
            type: "string"
          },
          {
            key: "title",
            type: "string"
          },
          {
            key: "cardName",
            type: "string"
          },
          {
            key: "finish",
            type: "tags",
            payload: {
              options: gameInfo.finish.map((finish) => finish.printing),
              defaults: data.finish
                ? data.finish.split(",")
                : gameInfo.finish.map((finish) => finish.printing),
              transformSelection: (value: any) => value.join(",")
            }
          },
          {
            key: "rarity",
            type: "string"
          },
          {
            key: "number",
            type: "string"
          },
          {
            key: "image",
            type: "image"
          },
          {
            key: "releaseDate",
            type: "string"
          },
          {
            key: "setName",
            type: "string"
          },
          {
            key: "archived",
            type: "boolean"
          }
        ]}
        migrate={true}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={(localData: any) => handleOnSave(localData)}
        onDelete={handleOnDelete}
        isMutating={isMutating || imageIsMutating}
        onFileChange={handleFileChange}
      />
    </>
  );
}

export default EditCardButton;
