import { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth hook must be used with AuthProvider");
  }
  const { authAttempted, user } = context;
  return { authAttempted, user };
};
