import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ProgressButton from "../generic/ProgressButton";
import { ReactComponent as GoogleLogo } from "../../assets/google-logo.svg";
import PCGLogo from "../../assets/pcg-logo.png";
import { auth, signInWithGoogle } from "../../services/firebase";

const useStyles = makeStyles({
  paper: {
    padding: "2rem",
    height: "63vh",
    width: "20rem",
    margin: "2rem auto",
    border: "2px solid #666",
  },

  logo: {
    height: "50%",
    width: "50%",
  },
});

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const signInWithEmailAndPasswordHandler = (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    auth.signInWithEmailAndPassword(email, password).catch((err) => {
      setError("Error signing in with password and email!");
      console.error("Error signing in with password and email", err);
      setIsSubmitting(false);
    });
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { code } = event;
    if (code === "Enter") {
      event.currentTarget.querySelector("input")?.blur();
      signInWithEmailAndPasswordHandler();
    }
  };

  return (
    <form>
      <Paper elevation={10} className={classes.paper}>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ gap: 15 }}
        >
          <img src={PCGLogo} className={classes.logo} alt="PCG Logo" />
          <Typography variant="h4">Staff Sign In</Typography>
          <div style={{ minHeight: "2rem" }}>
            {error ? <Typography color="error">{error}</Typography> : null}
          </div>
          <TextField
            required
            name="userEmail"
            label="Email"
            fullWidth
            onChange={onChangeHandler}
            placeholder="user@example.com"
          />
          <TextField
            name="userPassword"
            label="Password"
            fullWidth
            type="password"
            autoComplete="current-password"
            onChange={onChangeHandler}
            onKeyUp={onKeyUpHandler}
            placeholder="*****"
          />

          <ProgressButton
            type="button"
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            disabled={isSubmitting}
            onClick={signInWithEmailAndPasswordHandler}
            inProgress={isSubmitting}
          >
            Log In
          </ProgressButton>

          <Typography>or</Typography>
          <Button
            variant="contained"
            fullWidth
            onClick={() => signInWithGoogle()}
            size="large"
            startIcon={
              <Icon fontSize="large">
                <GoogleLogo />
              </Icon>
            }
          >
            Sign in with Google
          </Button>
        </Grid>
      </Paper>
    </form>
  );
};
export default SignInForm;
