import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import PrintIcon from "@material-ui/icons/Print";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import LabelTemplate from "../../components/label/LabelTemplate";
import exportAsImage, {
  getFrontLine2Text,
  getBackLine5Text,
  getBackLine3Text,
  getFinishAndRarity
} from "../../helpers/labelHelpers";
import { extractGradingData } from "../../helpers/gradingHelpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    form: {
      marginTop: "1rem",
      marginBottom: "1rem"
    },
    button: {
      marginTop: "0.5rem"
    }
  })
);

interface LabelCustomisationProps {
  grading: Grading;
  cancelAction: Function;
}

function LabelCustomisation(props: LabelCustomisationProps) {
  const { cancelAction, grading } = props;
  const { game, gradedId } = grading;
  const printRef = useRef<HTMLDivElement>(null);
  const gradingDetails = extractGradingData(grading);
  const { cardName } = gradingDetails;
  const frontLine2 = getFrontLine2Text(extractGradingData(grading));
  const [cardNameFrontSizeOverride, setCardNameFrontSizeOverride] = useState(
    10
  );
  const [cardNameBackSizeOverride, setCardNameBackSizeOverride] = useState(8);
  const [
    cardDetailsFrontSizeOverride,
    setCardDetailsFrontSizeOverride
  ] = useState(6);
  const [
    cardDetailsBackSizeOverride,
    setCardDetailsBackSizeOverride
  ] = useState(6);
  const [cardNameOverride, setCardNameOverride] = useState(cardName);
  const [frontLine2Override, setFrontLine2Override] = useState(frontLine2);
  const [backLine2Override, setBackLine2Override] = useState(game);
  const [backLine3Override, setBackLine3Override] = useState(
    getBackLine3Text(gradingDetails)
  );
  const [backLine4Override, setBackLine4Override] = useState(
    getFinishAndRarity(gradingDetails)
  );
  const [backLine5Override, setBackLine5Override] = useState(
    getBackLine5Text(gradingDetails)
  );

  const [gradeId, setGradeId] = useState(gradedId);
  const [flipReverse, setFlipReverse] = useState(false);
  const [worldsLogos] = useState(false);
  const [etcher, setEtcher] = useState(false);
  const classes = useStyles();

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  // @ts-ignore
  return (
    <Dialog
      open={true}
      onClose={() => cancelAction()}
      fullScreen={true}
      aria-labelledby="form-dialog-title"
    >
      <Container>
        <form className={classes.form} noValidate>
          <Grid container>
            <Grid item sm={7}>
              <TextField
                id="cardName"
                label="Card Name"
                fullWidth
                type="string"
                value={cardNameOverride}
                onChange={event => setCardNameOverride(event.target.value)}
              />
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={2}>
              <TextField
                id="cardNameFrontSizeOverride"
                label="Size (Front)"
                type="number"
                value={cardNameFrontSizeOverride}
                onChange={event =>
                  setCardNameFrontSizeOverride(Number(event.target.value))
                }
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                id="cardNameBackSizeOverride"
                label="Size (Back)"
                type="number"
                value={cardNameBackSizeOverride}
                onChange={event =>
                  setCardNameBackSizeOverride(Number(event.target.value))
                }
              />
            </Grid>
            <Grid item sm={7}>
              <TextField
                id="frontLine2"
                label="Front: Line 2"
                fullWidth
                type="string"
                value={frontLine2Override}
                onChange={event => setFrontLine2Override(event.target.value)}
              />
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={4}>
              <TextField
                id="cardDetailsFrontSizeOverride"
                label="Size (Front)"
                type="number"
                value={cardDetailsFrontSizeOverride}
                onChange={event =>
                  setCardDetailsFrontSizeOverride(Number(event.target.value))
                }
              />
            </Grid>
            <Grid item sm={7}>
              <TextField
                id="backLine2"
                label="Back: Line 2"
                fullWidth
                type="string"
                value={backLine2Override}
                onChange={event => setBackLine2Override(event.target.value)}
              />
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={4}>
              <TextField
                id="cardDetailsBackSizeOverride"
                label="Size (Back)"
                type="number"
                value={cardDetailsBackSizeOverride}
                onChange={event =>
                  setCardDetailsBackSizeOverride(Number(event.target.value))
                }
              />
            </Grid>
            <Grid item sm={7}>
              <TextField
                id="backLine3"
                label="Back: Line 3"
                fullWidth
                type="string"
                value={backLine3Override}
                onChange={event => setBackLine3Override(event.target.value)}
              />
            </Grid>
            <Grid item sm={5}></Grid>
            <Grid item sm={7}>
              <TextField
                id="backLine4"
                label="Back: Line 4"
                fullWidth
                type="string"
                value={backLine4Override}
                onChange={event => setBackLine4Override(event.target.value)}
              />
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={flipReverse}
                    onChange={() => setFlipReverse(!flipReverse)}
                    name="checkedG"
                  />
                }
                label="Flip Reverse"
              />
            </Grid>
            <Grid item sm={7}>
              <TextField
                id="backLine5"
                label="Back: Line 5"
                fullWidth
                type="string"
                value={backLine5Override}
                onChange={event => setBackLine5Override(event.target.value)}
              />
            </Grid>
            <Grid item sm={1}></Grid>
            {/*<Grid sm={4}>*/}
            {/*  <FormControlLabel*/}
            {/*      control={*/}
            {/*        <Checkbox*/}
            {/*            checked={worldsLogos}*/}
            {/*            onChange={() => {*/}
            {/*              setWorldsLogos(!worldsLogos);*/}
            {/*            }}*/}
            {/*            name="checked7"*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label="Worlds Logos"*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item sm={7}>
              <TextField
                id="gradedId"
                label="Grade Id"
                fullWidth
                type="string"
                value={gradeId}
                onChange={event => setGradeId(event.target.value)}
              />
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={etcher}
                    onChange={() => {
                      setEtcher(!etcher);
                    }}
                    name="checked8"
                  />
                }
                label="Etcher"
              />
            </Grid>
            <Grid item sm={4}></Grid>
            <Grid item sm={2}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<PrintIcon />}
                onClick={() =>
                  exportAsImage(printRef.current, gradeId, "front")
                }
              >
                PNG Front
              </Button>
            </Grid>
            <Grid item sm={2}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<PrintIcon />}
                onClick={() =>
                  exportAsImage(printRef.current, gradeId, "reverse")
                }
              >
                PNG Reverse
              </Button>
            </Grid>
            <Grid item sm={2}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<PrintIcon />}
                onClick={handlePrint}
              >
                Print
              </Button>
            </Grid>
            <Grid item sm={2}>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<CancelIcon />}
                onClick={() => cancelAction()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
        <div ref={printRef}>
          <LabelTemplate
            grading={grading}
            cardNameFrontSizeOverride={cardNameFrontSizeOverride}
            cardNameBackSizeOverride={cardNameBackSizeOverride}
            cardDetailsFrontSizeOverride={cardDetailsFrontSizeOverride}
            cardDetailsBackSizeOverride={cardDetailsBackSizeOverride}
            cardNameOverride={cardNameOverride}
            cardFrontLine2Override={frontLine2Override}
            cardBackLine2Override={backLine2Override}
            cardBackLine3Override={backLine3Override}
            cardBackLine4Override={backLine4Override}
            cardBackLine5Override={backLine5Override}
            gradeIdOverride={gradeId}
            flipReverse={flipReverse}
            worldsLogos={worldsLogos}
            etcher={etcher}
          />
        </div>
      </Container>
    </Dialog>
  );
}

export default LabelCustomisation;
