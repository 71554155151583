import { useEffect, useRef } from "react";
import "./ScoreIndicator.css";

interface ScoreIndicatorProps {
  label: string;
  score?: NullableNumber;
  children?: React.ReactNode;
}

function ScoreIndicator(props: ScoreIndicatorProps) {
  const { label, score, children } = props;
  const markerRef = useRef<SVGCircleElement>(null);

  useEffect(() => {
    const circle = markerRef.current;
    if (!circle || !score) return;
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    const offset = ((10 - score) * circumference) / 20;
    circle.style.strokeDashoffset = String(offset);
  }, [score]);

  return (
    <div className="scoreIndicator">
      <svg width="120" height="60" viewBox="0 -60 120 -120">
        <circle
          className="scoreIndicator__runner"
          stroke="#aaa"
          strokeWidth="2"
          fill="transparent"
          r="52"
          cx="60"
          cy="60"
        />
        <circle
          ref={markerRef}
          className="scoreIndicator__marker"
          stroke="#d1a96f"
          strokeWidth="5"
          fill="transparent"
          r="52"
          cx="60"
          cy="60"
        />
        {label}
      </svg>
      <div className="scoreIndicator__wrapper">
        <span className="scoreIndicator__score">{children ?? score}</span>
        <span className="scoreIndicator__label">{label}</span>
      </div>
    </div>
  );
}

export default ScoreIndicator;
