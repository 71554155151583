import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { common } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDeleteFile } from "../../hooks/gradingHooks";
import { useAlert } from "../../hooks/alertHooks";
import ImageDetails from "./ImageDetails";

const useStyles = makeStyles((theme) => ({
  title: {
    color: common.white,
    "&:hover": {
      
      cursor: "pointer",
      boxShadow: "2px 4px #88888855",
    }
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  image: {
    height: "10rem",
    marginBottom: "1rem",
  },
}));

interface ImageProps {
  gradingId: number;
  file: UploadedFile;
  readOnly?: boolean;
}

function Image(props: ImageProps) {
  const { gradingId, file, readOnly } = props;
  const [showFull, setShowFull] = useState(false);
  const { addAlert } = useAlert();
  const { mutateAsync, isMutating } = useDeleteFile();
  const classes = useStyles();

  const handleClick = () => {
    setShowFull(true);
  };

  const handleDismissPopup = () => {
    setShowFull(false);
  };

  const handleDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      await mutateAsync({ gradingId, fileId: file.id });
      addAlert(`Deleted image ${file.fileName}`, "success");
    } catch (error) {
      addAlert(`Failed to deleted ${file.fileName}`, "error");
    }
  };

  const renderDeleteButton = () => {
    if (readOnly) return null;
    return (
      <IconButton
        aria-label={`delete ${file.fileName}`}
        disabled={isMutating}
        onClick={handleDelete}
      >
        {isMutating ? (
          <CircularProgress size={16} />
        ) : (
          <DeleteIcon className={classes.title} />
        )}
      </IconButton>
    );
  };

  return (
    <GridListTile key={file.fileUrl} className={classes.title}>
      <img
        src={file.fileUrl}
        alt={file.fileName}
        className={classes.image}
        onClick={() => handleClick()}
      />
      <GridListTileBar
        title={file.fileName}
        classes={{
          root: classes.titleBar,
          title: classes.title,
        }}
        actionIcon={renderDeleteButton()}
      />
      <Dialog onClose={() => handleDismissPopup()} open={showFull}>
        <ImageDetails file={file} closeFunction={handleDismissPopup} />
      </Dialog>
    </GridListTile>
  );
}

Image.defaultProps = {
  readOnly: false,
};

export default Image;
