import { ChangeEvent, useState, useEffect } from 'react';

import {
  Grid,
  Button,
  TextField,
  Dialog,
} from '@material-ui/core';

function UploadImage(props: {
  label: string,
  value: string,
  onFileChange: any,
}) {
  const { label, value, onFileChange } = props;
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [localData, setLocalData] = useState(value === 'null' ? '' : value);
  
  useEffect(() => {
    if (value !== localData) {
      setLocalData(value)
    }
  }, [value, localData, setLocalData]);

  const handleFileUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    onFileChange(file);
  }

  return (
    <>
      <div style={{ marginBottom: 16, width: '100%' }}>
        <TextField
          label={label}
          value={localData ? localData : ''}
          onChange={(e) => setLocalData(e.target.value)}
          fullWidth
          disabled={true}
          style={{ marginBottom: 16 }}
        />
        <Grid container>
          <Grid item xs={12} sm={6}>
            {localData && localData !== 'null' && (
              <>
              <Button onClick={() => setImageModalOpen(true)}>Show Image</Button>
              <Button href={localData} target='__blank'>Open in New Tab</Button>
            </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              component="label"
            >
              Upload Image File
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleFileUploadChange}
              />
            </Button>
          </Grid>
        </Grid>
      </div>
      <Dialog onClose={() => setImageModalOpen(false)} aria-labelledby="image-modal" open={imageModalOpen}>
        <img src={localData} alt={localData} />
      </Dialog>
    </>
  );
}

export default UploadImage;
