import React, { useState, createContext, useEffect } from "react";
import firebase from "firebase/app";
import { auth } from "../services/firebase";
import { updateUserToken } from "../helpers/authHelpers";

interface AuthContextProps {
  authAttempted: boolean;
  user: firebase.User | null;
}

const defaultContextValue = {
  authAttempted: false,
  user: null,
};

export const AuthContext = createContext<AuthContextProps>(defaultContextValue);

interface UserProviderProps {
  children: React.ReactChild | React.ReactChildren;
}

function UserProvider(props: UserProviderProps) {
  const { children } = props;
  const [user, setUser] = useState<firebase.User | null>(null);
  const [authAttempted, setAuthAttempted] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      setUser(userAuth);
      setAuthAttempted(true);
      updateUserToken();
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ authAttempted, user }}>
      {children}
    </AuthContext.Provider>
  );
}

export default UserProvider;
