import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// @ts-ignore
import ReactBarcode from "react-barcode";
import { formatDate } from "../../helpers/formattingHelpers";
import PrintLogo from "../../assets/print-logo.png";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: "4rem",
    height: "2rem",
  },
});

interface OrderBarcodeProps {
  barcode: string;
  receivedDate: string;
  service: string;
  orderItemCount: number;
}

function OrderBarcode(props: OrderBarcodeProps) {
  const { barcode, receivedDate, service, orderItemCount } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={PrintLogo} alt="PCG Logo" className={classes.image} />
      <ReactBarcode value={barcode} />
      <Typography variant="body2">{service.replace("Card Grading - ", "")}</Typography>
      <Typography variant="body2">{`${formatDate(receivedDate)} - ${orderItemCount} ${
        orderItemCount === 1 ? "card" : "cards"
      }`}</Typography>
    </div>
  );
}

export default OrderBarcode;
