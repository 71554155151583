import { ChangeEvent, useState } from 'react';

import {
  Grid,
  Button,
  Dialog,
} from '@material-ui/core';

function UploadImage(props: {
  label: string,
  onFileChange: any,
}) {
  const { onFileChange } = props;
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const handleFileUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    onFileChange(file);
  }

  return (
    <>
      <div style={{ marginBottom: 16, width: '100%' }}>
        <Grid container>
          <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
            <br />
            <Button
              variant="contained"
              component="label"
            >
              Upload CSV File
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={handleFileUploadChange}
              />
            </Button>
          </Grid>
        </Grid>
      </div>
      <Dialog onClose={() => setImageModalOpen(false)} aria-labelledby="image-modal" open={imageModalOpen}>
      </Dialog>
    </>
  );
}

export default UploadImage;
