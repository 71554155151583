import { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getCurrentUser } from "../../helpers/authHelpers";
import { signOut } from "../../services/firebase";

function UserMenu() {
  const user = getCurrentUser();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const displayName = user?.displayName || undefined;
  const photoURL = user?.photoURL || undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOutClick = () => {
    signOut();
  }

  return (
    <>
      <Button
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar alt={displayName} src={photoURL} />
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSignOutClick}>Sign Out</MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
