import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ProgressButton from "../generic/ProgressButton";
import {
  useUpdateGrading,
  useBulkUpdateGradings,
} from "../../hooks/gradingHooks";
import { useAlert } from "../../hooks/alertHooks";

interface NotesFormProps {
  gradingId?: number;
  gradingIdList?: number[];
  cancelAction?: Function;
  ActionsWrapper: Function;
}

function NotesForm(props: NotesFormProps) {
  const { gradingId, gradingIdList, ActionsWrapper, cancelAction } = props;
  const [isPublic, setIsPublic] = useState(false);
  const [note, setNote] = useState<string>("");
  const { mutateAsync: mutateAsyncSingle, isMutating: isMutatingSingle } =
    useUpdateGrading();
  const { mutateAsync: mutateAsyncBulk, isMutating: isMutatingBulk } =
    useBulkUpdateGradings();
  const { addAlert } = useAlert();

  const updateSingleGrading = async () => {
    if (!gradingId) return;
    const payload = {
      id: gradingId,
      [isPublic ? "publicNotes" : "privateNotes"]: note,
    };
    try {
      await mutateAsyncSingle(payload);
      addAlert("Note added", "success");
      setNote("");
      if (cancelAction) {
        cancelAction();
      }
    } catch (error) {
      addAlert("Failed to add note", "error");
    }
  };

  const bulkUpdateGradings = async () => {
    if (!gradingIdList) return;
    const payload = {
      gradingIds: gradingIdList,
      [isPublic ? "publicNotes" : "privateNotes"]: note,
    };
    try {
      await mutateAsyncBulk(payload);
      addAlert("Notes added", "success");
      setNote("");
      if (cancelAction) {
        cancelAction();
      }
    } catch (error) {
      addAlert("Failed to add notes", "error");
    }
  };

  const handleNoteChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setNote(event.target.value);

  const handlePublicChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => setIsPublic(checked);

  const handleSave = async () => {
    if (gradingId) {
      updateSingleGrading();
    } else {
      bulkUpdateGradings();
    }
  };

  return (
    <form>
      <TextField
        id="note-text"
        label="Enter note"
        variant="outlined"
        fullWidth
        multiline
        minRows={4}
        value={note}
        onChange={handleNoteChange}
      />
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={isPublic}
              onChange={handlePublicChange}
              name="public"
            />
          }
          label="Make public"
        />
      </FormGroup>
      <ActionsWrapper>
        <ProgressButton
          disabled={isMutatingSingle || isMutatingBulk}
          inProgress={isMutatingSingle || isMutatingBulk}
          variant="contained"
          color="secondary"
          onClick={() => handleSave()}
        >
          Add Note
        </ProgressButton>
        {cancelAction ? (
          <Button variant="contained" onClick={() => cancelAction()}>
            Cancel
          </Button>
        ) : null}
      </ActionsWrapper>
    </form>
  );
}

NotesForm.defaultProps = {
  cancelAction: null,
};

export default NotesForm;
