import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Button, { ButtonProps } from "@material-ui/core/Button";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import CircularProgress from "@material-ui/core/CircularProgress";

interface SaveAndNextButtonProps extends ButtonProps {
  order?: Order;
  isMutating: boolean;
  onSave: () => Promise<void>;
  disabled: boolean;
}

const SaveAndNextButton = ({
  order,
  isMutating,
  onSave,
  disabled,
}: SaveAndNextButtonProps) => {
  const history = useHistory();
  const { gradingId } = useParams<{ gradingId: string }>();

  const handleSaveAndNext = async () => {
    await onSave();

    if (!order) return;

    // Find the index of the current grading
    const currentIndex = order.orderItems.findIndex(
      (item) => item.id === parseInt(gradingId)
    );

    if (currentIndex > -1 && currentIndex < order.orderItems.length - 1) {
      const nextGrading = order.orderItems[currentIndex + 1];
      // Replace current history entry instead of pushing a new one
      history.replace(`/orders/gradings/${nextGrading.id}`);
    } else {
      history.goBack();
    }
  };

  // Don't show the button if:
  // 1. There's no order
  // 2. Can't find the current grading in the order
  // 3. This is the last card in the order
  if (!order?.orderItems) return null;

  const currentIndex = order.orderItems.findIndex(
    (item) => item.id === parseInt(gradingId)
  );

  if (currentIndex === -1 || currentIndex === order.orderItems.length - 1) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleSaveAndNext}
      fullWidth
      disabled={disabled || isMutating}
      endIcon={isMutating ? <CircularProgress size={20} /> : <SkipNextIcon />}
      style={{ marginTop: "10px" }}
    >
      Save & Next Card
    </Button>
  );
};

export default SaveAndNextButton;
