import { useParams } from "react-router-dom";

interface RouteParams {
  gradingId: string;
}

function GradingDetailsView() {
  const { gradingId } = useParams<RouteParams>();
  return (
    <>
      <h4>{`Grading ${gradingId}`}</h4>
      <p>Grading Details</p>
    </>
  );
}

export default GradingDetailsView;
