import Grid from "@material-ui/core/Grid";
import SignInForm from "../components/auth/SignInForm";

function SignInView() {
  return (
    <Grid>
      <div className="sign-in-view__background" />
      <SignInForm />
    </Grid>
  );
}

export default SignInView;
