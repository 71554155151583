import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GradingTableHeader from "./GradingTableHeader";
import GradingTableRow from "./GradingTableRow";
import { useFetchBuckets, useFetchMe } from "../../hooks/userHooks";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

interface GradingTableProps {
  gradings?: Grading[];
  currentBucket?: string;
  selected: number[];
  emptyRows: number;
  rowsSelected: number;
  rowCount: number;
  order: AscendingOrder;
  orderBy?: string;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onRowSelectClick: (
    event: React.MouseEvent<unknown>,
    grading: Grading
  ) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

function GradingTable(props: GradingTableProps) {
  const {
    gradings,
    currentBucket,
    selected,
    emptyRows,
    rowsSelected,
    rowCount,
    order,
    orderBy,
    onSelectAllClick,
    onRowSelectClick,
    onRequestSort
  } = props;
  const classes = useStyles();
  const { data: currentUser } = useFetchMe();
  const { data: buckets } = useFetchBuckets();

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  if (!gradings) return null;

  if (gradings.length === 0)
    return <p>There are currently no matching cards found</p>;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <GradingTableHeader
          rowsSelected={rowsSelected}
          rowCount={rowCount}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={onSelectAllClick}
          onRequestSort={onRequestSort}
        />
        <TableBody>
          {gradings.map(grading => (
            <GradingTableRow
              grading={grading}
              currentBucket={currentBucket}
              buckets={buckets || []}
              role={currentUser?.role}
              key={grading.id}
              selected={isSelected(grading.id)}
              onRowSelectClick={onRowSelectClick}
            />
          ))}
          {emptyRows > 0 ? (
            <TableRow style={{ height: 73 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

GradingTable.defaultProps = {
  gradings: []
};

export default GradingTable;
