import { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';

import UploadCSV from './UploadCSV';
import {useRecordsDetails} from '../../hooks/catalogHooks';

function CreateModal(props: {
  title: string,
  open: boolean,
  onClose: any,
  history?: [],
  isMutating: boolean,
  onFileChange?: any,
}) {
  const { title, open, history, onClose, isMutating, onFileChange } = props;

  const [id, setId] = useState<any>(undefined);
  const csvDetails = useRecordsDetails(id);

  useEffect(() => { setId(undefined)}, [isMutating, onFileChange])

  const handleCSVChange = (id: any) => {
    setId(id)
  }

  if (id !== undefined && csvDetails && Object.keys(csvDetails).length !== 0) return (
    <Dialog
      open={open}
      onClose={() => {setId(undefined)}}
      aria-labelledby={`alert-dialog-${title}-title`}
      aria-describedby={`alert-dialog-${title}-description`}
      style={{ width: '100%' }}
    >
      <DialogTitle id={`alert-dialog-${title}-title`}>View CSV Record</DialogTitle>
      <DialogContent>
      <strong>Filename: </strong> {csvDetails?.fileName}<br />
      <strong>Failed Records: </strong> {csvDetails?.failedRecords}<br />
      <strong>Successful Records: </strong> {csvDetails?.successfulRecords}<br />
      <strong>Total Records: </strong> {csvDetails?.totalRecords}<br />
      <strong>Status: </strong> {csvDetails?.status}<br />
      <strong>Uploaded By: </strong> {csvDetails?.uploadedBy.name} ({csvDetails?.uploadedBy.region})<br />
      <strong>Uploaded On: </strong> {new Date(csvDetails?.uploadStartTime).toLocaleString()}<br />
      <br />
      {csvDetails && csvDetails.errors.length > 0 && <>
      <strong>Errors: </strong><br />
      <hr />
      {csvDetails && csvDetails.errors.map((item: any) => {
        return <div key={item} style={{ color: 'red' }}>
          <li>{item}</li>
        </div>
      })}
      </>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {setId(undefined)}} color="primary" disabled={isMutating}>
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`alert-dialog-${title}-title`}
      aria-describedby={`alert-dialog-${title}-description`}
      style={{ width: '100%' }}
    >
      <DialogTitle id={`alert-dialog-${title}-title`}>{title}</DialogTitle>
      <DialogContent>
      Feel free to download the following example CSV to get started. <a href="/example-grading-csv.csv" target="__blank">Download Example CSV</a>
      <br />
      <UploadCSV label={'bulkUpload'} onFileChange={onFileChange} />
        {isMutating && 
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', textAlign: 'center', background: 'black', opacity: '.6' }}>
            <CircularProgress style={{ marginTop: '30%' }} />
          </div>        
        }
      <hr />
      <table>
        <thead>
        <tr>
          <th style={{ width: '20%'}}>File Name</th>
          <th>Total Records</th>
          <th>Passed Records</th>
          <th>Failed Records</th>
          <th>Status</th>
          </tr>
        </thead>
        <tbody>
      {history && history.slice(0, 5).map((item: any) => {
      return <tr key={item.id} onClick={() => handleCSVChange(item.id)} className='csvHistory'>
        <td title={`${item.fileName} - uploaded by ${item.uploadedBy.name} (${item.uploadedBy.region})`} style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: 'nowrap'}}>{item.fileName}</td>
        <td style={{ textAlign: 'center'}}>{item.totalRecords}</td>
        <td style={{ textAlign: 'center'}}>{item.successfulRecords}</td>
        <td style={{ textAlign: 'center'}}>{item.failedRecords}</td>
        <td style={{ textAlign: 'center'}}>{item.failedRecords > 0 ? <span style={{ color: 'orange'}}>Errors Found</span> : item.status === 'Failed' ? <span style={{ color: 'red'}}>Failed</span>: <span style={{ color: 'green'}}>Completed</span>}</td>
      </tr>
      }
      )}
      </tbody>
      </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isMutating}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateModal;
