import { makeStyles } from "@material-ui/core/styles";
import { getCardName } from "../../helpers/formattingHelpers";
import GradingBarcode from "./GradingBarcode";

const useStyles = makeStyles({
  barcode: {
    pageBreakAfter: "always",
  },
});

interface GradingBarcodeBulkProps {
  gradings: Grading[];
}

function GradingBarcodeBulk(props: GradingBarcodeBulkProps) {
  const { gradings } = props;
  const classes = useStyles();
  return (
    <>
      {gradings.map((grading) => {
        const cardName = getCardName(grading);
        return (
          <div className={classes.barcode} key={grading.id}>
            <GradingBarcode
              barcode={grading.barcode}
              order={grading.orderBarcode}
              cardName={cardName}
              slabType={grading.slabType}
            />
          </div>
        );
      })}
    </>
  );
}

export default GradingBarcodeBulk;
