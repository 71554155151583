import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@material-ui/core/styles";
import {
  downloadPythonLabels,
  orderItemToPythonLabel,
  PythonLabel
} from '../../helpers/pythonHelpers';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import OrderBarcode from "./OrderBarcode";

const useStyles = makeStyles({
  printContent: {
    display: "none",
  },
});

type OrderActionsMenuProps = {
  orderId: number;
  barcode: string;
  service: string;
  receivedDate: string;
  orderItems: Grading[];
}

function OrderActionsMenu({ orderId, barcode, service, receivedDate, orderItems } : OrderActionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const barcodeRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const pythonLabels : PythonLabel[] = (
    orderItems.map(orderItemToPythonLabel).filter((label):label is PythonLabel => label !== null)
  );

  const handlePrint = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const handleDownloadPythonLabels = (_ : React.MouseEvent<HTMLLIElement>) => {
    downloadPythonLabels(orderId, pythonLabels);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!orderId) return null;

  return (
    <>
      <Button
        aria-controls="grading-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="grading-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handlePrint}>Print Barcode</MenuItem>
        <MenuItem onClick={handleDownloadPythonLabels} disabled={!pythonLabels.length}>
          Download Completed Labels
        </MenuItem>
      </Menu>
      <div className={classes.printContent}>
        <div ref={barcodeRef}>
          <OrderBarcode
            barcode={barcode}
            receivedDate={receivedDate}
            service={service}
            orderItemCount={orderItems.length}
          />
        </div>
      </div>
    </>
  );
}

export default OrderActionsMenu;
