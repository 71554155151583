import { fetchWithToken, uploadFileWithToken } from "../services/api";
import {
  buildQueryFromOptionalParams,
  OptionalParamsObject,
} from "../helpers/apiHelpers";
import { API_BASE_URL } from "../config/api";

interface FetchGradingsParams extends OptionalParamsObject {
  offset: number;
  limit: number;
  order: AscendingOrder;
  orderBy?: string;
  bucket?: OptionalString;
}

export const fetchGradings = (
  params: FetchGradingsParams
): Promise<GradingList> => {
  const { order, orderBy, ...urlParams } = params;
  if (orderBy) {
    urlParams["sort"] = `${order === "asc" ? "+" : "-"}${orderBy}`;
  }
  let url = `${API_BASE_URL}/gradings?`;
  url += buildQueryFromOptionalParams(urlParams);
  return fetchWithToken({
    method: "GET",
    endpoint: url,
  });
};

export const fetchGradingById = (gradingId: number): Promise<Grading> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/grading/${gradingId}`,
  });

export const updateGrading = (data: GradingUpdate): Promise<Grading> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${API_BASE_URL}/grading/update`,
    payload: data,
  });

export const bulkUpdateGradings = (
  data: GradingBulkUpdateVariables
): Promise<GenericResponse> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${API_BASE_URL}/grading/bulk/update`,
    payload: data,
  });

export const uploadFileToGrading = ({
  gradingId,
  file,
}: UploadFileVariables): Promise<UploadedFile> =>
  uploadFileWithToken({
    endpoint: `${API_BASE_URL}/grading/${gradingId}/uploadFile`,
    payload: file,
  });

export const deleteFile = (fileId: number): Promise<GenericResponse> =>
  fetchWithToken({
    method: "DELETE",
    endpoint: `${API_BASE_URL}/grading/file/${fileId}`,
  });

export const fetchCountByGrade = (grade: string): Promise<CountResponse> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/gradings/count/byGrade/${grade}`,
  });

export const fetchCountByBucket = (bucket: string): Promise<CountResponse> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/gradings/count/byBucket/${bucket}`,
  });

export const fetchGradingByBarcode = (barcode: string): Promise<Grading> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/grading/byBarcode/${barcode}`,
  });
