import CircularProgress from "@material-ui/core/CircularProgress";
import { useUpdateGrading } from "../../hooks/gradingHooks";
import { useAlert } from "../../hooks/alertHooks";
import { SUPERVISOR_BUCKET } from "../../config/buckets";

interface ReferToSupervisorButtonProps {
  gradingId: number;
  onComplete: Function;
}

function ReferToSupervisorButton(props: ReferToSupervisorButtonProps) {
  const { gradingId, onComplete } = props;
  const { addAlert } = useAlert();
  const { mutateAsync, isMutating } = useUpdateGrading();

  const handleClick = async () => {
    try {
      await mutateAsync({ id: gradingId, bucketUpdate: SUPERVISOR_BUCKET });
      addAlert("Grading referred to supervisor", "success");
    } catch (error) {
      addAlert("Failed to refer grading", "error");
    }
    onComplete();
  };

  if (isMutating) {
    return <CircularProgress size={16} />;
  }

  return <span onClick={() => handleClick()}>Refer to supervisor</span>;
}

export default ReferToSupervisorButton;
