import { useQueryClient, useQueries, useQuery, useMutation } from "react-query";
import {
  fetchGradings,
  fetchGradingById,
  updateGrading,
  uploadFileToGrading,
  deleteFile,
  bulkUpdateGradings,
  fetchCountByGrade,
  fetchCountByBucket,
  fetchGradingByBarcode,
} from "../api/grading";

type GradingSearchParams = {
  bucket?: string;
  setName?: string;
  cardName?: string;
  cardNumber?: string;
};

export const useFetchGradings = (
  offset: number,
  limit: number,
  order: AscendingOrder,
  orderBy?: string,
  searchParams?: GradingSearchParams
) =>
  useQuery(
    ["gradings", { offset, limit, order, orderBy, ...searchParams }],
    () => fetchGradings({ offset, limit, order, orderBy, ...searchParams }),
    {
      keepPreviousData: true,
    }
  );

export const useFetchGradingById = (gradingId: number) =>
  useQuery(["grading", { gradingId }], () => fetchGradingById(gradingId));

export const useUpdateGrading = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (grading: GradingUpdate) => updateGrading(grading),
    {
      onSuccess: (updatedGrading: Grading, update: GradingUpdate) => {
        queryClient.setQueryData<Grading | undefined>(
          ["grading", { gradingId: updatedGrading.id }],
          updatedGrading
        );
        queryClient.setQueryData<Grading[]>(["gradings"], (prev): Grading[] => {
          if (prev) {
            return prev.map((grading) =>
              grading.id === update.id ? updatedGrading : grading
            );
          }
          return [updatedGrading];
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("gradings", { refetchInactive: true });
        queryClient.invalidateQueries("orders", { refetchInactive: true });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useBulkUpdateGradings = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (variables: GradingBulkUpdateVariables) => bulkUpdateGradings(variables),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gradings", { refetchInactive: true });
        queryClient.invalidateQueries("orders", { refetchInactive: true });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useUploadFileToGrading = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (variables: UploadFileVariables) => uploadFileToGrading(variables),
    {
      onSuccess: (
        uploadedFile: UploadedFile,
        variables: UploadFileVariables
      ) => {
        queryClient.setQueryData<Grading | undefined>(
          ["grading", { gradingId: variables.gradingId }],
          (prev): Grading | undefined => {
            if (prev) {
              return {
                ...prev,
                files: [...prev.files, uploadedFile],
              };
            }
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useDeleteFile = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (variables) => deleteFile(variables.fileId),
    {
      onSuccess: (
        response: GenericResponse,
        variables: DeleteFileVariables
      ) => {
        queryClient.setQueryData<Grading | undefined>(
          ["grading", { gradingId: variables.gradingId }],
          (prev): Grading | undefined => {
            if (prev) {
              return {
                ...prev,
                files: prev.files.filter(
                  (file) => file.id !== variables.fileId
                ),
              };
            }
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useFetchCountByGrade = (grade: string) =>
  useQuery(["countByGrade", { grade }], () => fetchCountByGrade(grade));

export const useFetchCountByBuckets = (buckets: string[] = []) =>
  useQueries(
    buckets.map((bucket: string) => {
      return {
        queryKey: ["countByBucket", bucket],
        queryFn: () => fetchCountByBucket(bucket),
      };
    })
  );

export const useFetchGradingByBarcode = (barcode?: string) =>
  useQuery(["grading", { barcode }], () => fetchGradingByBarcode(barcode!), {
    enabled: barcode !== undefined,
  });
