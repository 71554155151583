function LabelTemplateFABWorldsGradeLogo() {

    return (
        <g id="WorldsLogo" transform="scale(1.1) translate(153 45)">
            <g>
                <g id="tournament_logos">
                    <g id="worlds">
                        <g>
                            <path className="worldsLogo"
                                  d="M5.15,7.79l-.05-.02c.04-.07,.08-.13,.1-.2,.04-.1,0-.21-.02-.3-.12-.47-.24-.93-.36-1.4-.09-.33-.18-.66-.27-.99,0-.02-.02-.05-.03-.08-.02,.03-.04,.04-.05,.06-.11,.22-.21,.44-.32,.66-.24,.49-.47,.97-.71,1.46-.07,.14-.14,.28-.22,.41-.02,.03-.05,.07-.09,.08-.28,.13-.57,.21-.87,.27-.06,.01-.12,.02-.2,.03,.02-.03,.02-.05,.03-.07,.05-.08,.07-.17,.06-.26-.03-.26-.11-.51-.19-.76-.18-.58-.37-1.16-.55-1.74-.16-.51-.32-1.02-.5-1.53-.13-.35-.26-.71-.46-1.03-.07-.11-.16-.2-.29-.25-.06-.03-.11-.08-.16-.12-.01,0-.02-.03-.01-.05,0-.01,.03-.02,.04-.02,.24,0,.46-.09,.67-.18,.2-.09,.39-.19,.59-.29,.03-.01,.08-.02,.1,0,.07,.05,.13,.11,.18,.18,.13,.17,.2,.38,.26,.58,.13,.4,.25,.79,.37,1.19,.18,.6,.36,1.21,.55,1.81,.07,.23,.15,.45,.23,.69,.07-.16,.14-.3,.21-.45,.3-.68,.6-1.36,.9-2.05,.05-.12,.09-.23,.15-.35,.02-.04,.05-.08,.09-.11,.18-.13,.36-.26,.54-.39,0,0,.01-.01,.02-.02,.03-.02,.08-.07,.1-.06,.06,.02,.11,.06,.13,.13,.07,.23,.14,.47,.22,.7,.16,.51,.31,1.02,.47,1.53,.09,.29,.18,.59,.27,.88,0,.02,.01,.04,.02,.07,.05-.12,.09-.22,.13-.33,.17-.44,.34-.88,.5-1.32,.12-.34,.23-.69,.34-1.03,.03-.08,.02-.15-.06-.21,0,0-.02-.02-.02-.03,0-.01-.02-.03-.02-.04,0-.01,.03-.02,.04-.02,.16-.04,.32-.06,.47-.11,.2-.06,.4-.14,.6-.22,.04-.01,.06-.01,.08,.01,.01,.01,.02,.02,.04,.03,.07,.06,.09,.11,.05,.22-.11,.26-.22,.52-.32,.78-.22,.56-.44,1.11-.65,1.67-.19,.5-.36,1.01-.55,1.52-.07,.2-.15,.39-.22,.59-.03,.08-.09,.14-.17,.17-.32,.14-.66,.22-1.01,.28l-.15,.02h-.05Z"/>
                            <path className="worldsLogo"
                                  d="M21.38,7.41s.08-.07,.11-.1c.12-.09,.15-.22,.15-.36,0-.83,0-1.66,0-2.49,0-.16,0-.33,0-.49,0-.08-.03-.13-.09-.16-.18-.1-.28-.26-.36-.44-.01-.03-.02-.06-.03-.09-.01-.05,0-.07,.05-.05,.24,.07,.48,.08,.73,.05,.16-.02,.32-.06,.48-.1,.24-.06,.48-.12,.71-.19,.33-.09,.65-.05,.97,.04,.32,.08,.62,.23,.92,.38,.43,.22,.81,.51,1.14,.86,.15,.15,.28,.32,.35,.53,.06,.19,.05,.37,0,.56-.07,.28-.19,.54-.36,.78-.09,.12-.19,.24-.3,.35-.19,.19-.39,.37-.61,.53-.23,.18-.48,.33-.75,.44-.06,.02-.13,.04-.2,.04-.93,0-1.86,0-2.79,0-.06,0-.09-.03-.13-.07h0Zm1.52-2.16h0v1.44c0,.09,.02,.12,.12,.12,.28,0,.55,0,.83,0,.13,0,.25-.02,.38-.07,.18-.06,.32-.2,.44-.34,.29-.35,.42-.75,.4-1.2-.01-.29-.11-.55-.31-.77-.11-.12-.23-.23-.36-.32-.2-.15-.41-.27-.66-.35-.24-.08-.48-.13-.73-.04-.09,.03-.1,.03-.1,.12v1.42h0Z"/>
                            <path className="worldsLogo"
                                  d="M13.21,7.49s.03-.04,.04-.06c.04-.06,.09-.12,.13-.18,.07-.09,.1-.2,.1-.31,0-.95,0-1.91,0-2.86,0-.25,0-.2-.17-.35-.12-.1-.24-.21-.29-.36-.02-.05,0-.07,.05-.06,.47,.1,.94,.04,1.4-.07,.27-.07,.52-.16,.77-.27,.04-.02,.1-.02,.14-.01,.75,.22,1.3,.68,1.64,1.38,.04,.09,.08,.19,.1,.28,.01,.06,0,.13-.01,.18-.09,.25-.27,.43-.48,.58-.13,.1-.26,.19-.4,.28-.04,.03-.04,.05-.01,.08,.22,.26,.44,.53,.66,.79,.17,.2,.33,.39,.5,.59,.09,.11,.18,.2,.32,.24-.05,.07-.1,.12-.19,.12-.38,0-.77,0-1.15,0-.08,0-.14-.02-.19-.08-.15-.2-.31-.4-.47-.59-.15-.19-.3-.38-.46-.56-.11-.13-.23-.23-.4-.26-.04,0-.08,0-.13-.01-.05,0-.07,.02-.07,.07,0,.14,0,.28,0,.42,0,.29,0,.58,0,.87,0,.1-.04,.14-.14,.15-.03,0-.06,0-.09,0h-1.12s-.04,0-.08,0h0Zm1.43-2.02c.13,0,.25,.01,.37,.01,.12,0,.24,0,.36-.03,.24-.05,.46-.17,.63-.36,.11-.12,.14-.26,.08-.42-.08-.24-.22-.45-.41-.62-.1-.08-.2-.16-.31-.23-.2-.14-.41-.12-.62-.02-.07,.03-.1,.08-.1,.15,0,.48,0,.96,0,1.44,0,.02,0,.03,0,.06h0Z"/>
                            <path className="worldsLogo"
                                  d="M10.5,6.86c-.54,0-.94-.06-1.33-.21-.34-.13-.65-.31-.86-.62-.26-.38-.37-.81-.3-1.27,.08-.52,.36-.9,.8-1.16,.41-.24,.86-.4,1.32-.52,.28-.07,.56-.11,.84-.12,.15,0,.3,.04,.44,.08,.37,.11,.71,.28,1.02,.5,.34,.24,.54,.56,.59,.97,.07,.51-.03,.98-.3,1.41-.29,.45-.7,.71-1.21,.82-.38,.08-.76,.1-1.02,.11h0Zm1.22-1.83c0-.29-.02-.5-.09-.72-.09-.27-.27-.48-.49-.65-.2-.15-.41-.23-.66-.17-.15,.04-.31,.08-.45,.14-.29,.13-.49,.34-.58,.64-.09,.31-.11,.63-.05,.96,.04,.2,.12,.38,.27,.53,.17,.16,.36,.29,.58,.36,.21,.07,.43,.12,.66,.08,.39-.06,.71-.46,.76-.78,.02-.15,.04-.31,.05-.4h0Z"/>
                            <path className="worldsLogo"
                                  d="M17.82,7.5c.06-.09,.12-.16,.18-.24,.07-.1,.11-.21,.11-.34,0-.55,0-1.11,0-1.67,0-.41,0-.83,0-1.24,0-.1-.03-.18-.07-.27-.02-.05-.05-.1-.06-.15,0-.01,.01-.05,.02-.05,.34-.13,.63-.34,.95-.5,.14-.07,.29-.09,.45-.09,.02,0,.03,0,.05,.01v.02c-.07,.1-.06,.23-.06,.34v3.31c0,.13,0,.13,.13,.13,.58,0,1.16,0,1.74,0h.08s-.03,.07-.05,.1c-.16,.24-.35,.45-.61,.6-.05,.03-.11,.04-.16,.04-.87,0-1.75,0-2.62,0-.02,0-.03,0-.06,0h0Z"/>
                            <path className="worldsLogo"
                                  d="M14.08,0s.04,.02,.05,.04c.22,.2,.44,.4,.64,.61,.13,.13,.25,.29,.37,.43,.03,.04,.03,.06,0,.1-.24,.36-.5,.69-.79,1.01-.07,.08-.15,.16-.23,.24-.04,.04-.07,.04-.11,0-.38-.39-.72-.81-1.02-1.26-.02-.03-.02-.05,0-.08,.31-.38,.65-.73,1.02-1.05,.02-.01,.03-.02,.05-.04h0Z"/>
                            <path className="worldsLogo"
                                  d="M10.34,7.49c-.41,0-.81,0-1.22,0-.02,0-.05,0-.07-.01-.06-.04-.06-.06-.02-.12,.06-.08,.12-.17,.17-.26,.03-.05,.06-.06,.11-.06,.48,0,.95,0,1.43,0,.33,0,.66,0,.98,0,.03,0,.06,0,.09,0,.07,.01,.1,.08,.06,.14-.05,.08-.11,.16-.16,.24-.03,.05-.08,.07-.14,.07-.41,0-.82,0-1.23,0h0Z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path className="worldsLogo"
                              d="M1.97,8.84c0-.06-.03-.11-.07-.14-.08-.07-.2-.08-.28-.08-.22,0-.55,.05-.55,.72,0,.41,.14,.7,.56,.7,.09,0,.21-.01,.27-.09,.03-.03,.05-.08,.05-.15h.05c0,.07,0,.15,0,.26v.04c-.11,.04-.29,.05-.42,.05-.31,0-.57-.09-.72-.28-.1-.12-.16-.29-.16-.51,0-.43,.24-.7,.56-.81,.13-.04,.28-.05,.38-.05,.19,0,.37,.03,.39,.03,0,.05,0,.1,0,.15,0,.06,0,.13,0,.16h-.05Z"/>
                        <path className="worldsLogo"
                              d="M3.88,8.61s-.04,.12-.04,.33v.85c0,.16,0,.21,.03,.25,.02,.03,.06,.06,.13,.06v.04c-.1,0-.25,0-.33,0s-.23,0-.33,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.35h-.71v.43c0,.16,0,.21,.03,.25,.02,.03,.06,.06,.13,.06v.04c-.1,0-.25,0-.33,0s-.23,0-.33,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.85c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.25,0,.33,0s.23,0,.33,0v.04s-.08,.01-.12,.05c-.04,.05-.04,.12-.04,.33v.29h.71v-.36c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.25,0,.33,0s.23,0,.33,0v.04s-.08,.01-.12,.05h0Z"/>
                        <path className="worldsLogo"
                              d="M5.39,10.13c-.07,0-.26,0-.33,0v-.04c.06,0,.1-.01,.12-.03,.01-.01,.02-.03,.02-.05,0-.03-.01-.07-.04-.14l-.12-.34h-.46l-.13,.34c-.02,.07-.04,.11-.04,.14,0,.02,0,.04,.02,.05,.03,.03,.08,.03,.11,.03v.04c-.09,0-.15,0-.23,0-.08,0-.15,0-.23,0v-.04c.07,0,.11-.05,.12-.07,.02-.03,.06-.12,.12-.27l.51-1.24,.18-.03,.44,1.25c.06,.17,.11,.28,.12,.29,.01,.02,.05,.07,.12,.07v.04c-.07,0-.24,0-.31,0h0Zm-.5-1.04c-.05-.16-.07-.22-.07-.22l-.2,.55h.38l-.11-.33h0Z"/>
                        <path className="worldsLogo"
                              d="M7.48,10.13c-.09,0-.22,0-.33,0v-.04s.09-.01,.12-.05c.04-.04,.03-.13,.03-.18,0-.02-.04-1.11-.04-1.11,0,0-.01,.05-.05,.14l-.47,1.25h-.08l-.45-1.25c-.03-.09-.05-.15-.05-.15,0,0-.04,1.06-.04,1.11s0,.13,.02,.17c0,0,.03,.06,.13,.07v.04c-.1,0-.16,0-.25,0s-.12,0-.22,0v-.04s.08-.01,.12-.05c.04-.04,.04-.11,.05-.31l.04-.82s0-.14,0-.17c0-.07,0-.09-.02-.12,0,0-.04-.06-.13-.06v-.04c.1,0,.23,0,.32,0,.04,0,.19,0,.23,0l.35,.98c.02,.06,.04,.12,.04,.12,0,0,.01-.05,.04-.12l.38-.98s.2,0,.24,0c.09,0,.18,0,.28,0v.04s-.08,0-.12,.05c-.03,.04-.03,.1-.03,.15,0,.04,0,.1,0,.16l.03,.85c0,.15,0,.22,.04,.27,0,0,.04,.05,.13,.06v.04c-.1,0-.24,0-.33,0h0Z"/>
                        <path className="worldsLogo"
                              d="M8.81,9.42c-.11,0-.16-.02-.16-.02v-.05s.03,0,.05,0c.07,0,.13-.02,.18-.05,.08-.06,.12-.17,.12-.33,0-.36-.21-.37-.33-.37-.04,0-.1,0-.1,0v1.09c0,.19,0,.27,.03,.32,.03,.05,.09,.07,.19,.07v.04c-.1,0-.31,0-.4,0s-.22,0-.33,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.85c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.21,0,.3,0,.14,0,.22,0,.37,0,.05,0,.18,0,.28,.02,.18,.04,.35,.15,.35,.41,0,.33-.25,.47-.55,.47h0Z"/>
                        <path className="worldsLogo"
                              d="M10.1,8.61s-.04,.12-.04,.33v.85c0,.16,0,.21,.03,.25,.02,.03,.06,.06,.13,.06v.04c-.1,0-.25,0-.33,0s-.23,0-.33,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.85c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.25,0,.33,0s.23,0,.33,0v.04s-.08,.01-.12,.05Z"/>
                        <path className="worldsLogo"
                              d="M11.3,10.16c-.51,0-.81-.31-.81-.81s.33-.85,.84-.85,.81,.31,.81,.81-.33,.85-.84,.85h0Zm.01-1.55c-.34,0-.46,.32-.46,.71s.14,.73,.45,.73c.34,0,.46-.32,.46-.71s-.14-.73-.45-.73h0Z"/>
                        <path className="worldsLogo"
                              d="M13.9,8.61s-.04,.12-.04,.33v1.21h-.14l-.94-1.25c-.04-.05-.06-.09-.06-.09v.98c0,.16,0,.21,.03,.25,.02,.03,.06,.06,.13,.06v.04c-.1,0-.15,0-.23,0s-.14,0-.25,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.85c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.2,0,.29,0,.04,0,.15,0,.21,0l.72,.95c.06,.08,.08,.12,.08,.12v-.72c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.16,0,.24,0s.13,0,.24,0v.04s-.08,.01-.12,.05h0Z"/>
                        <path className="worldsLogo"
                              d="M14.64,10.15c-.11,0-.28-.01-.39-.05v-.04c0-.12,0-.2,0-.27h.05c0,.07,.03,.14,.09,.18,.07,.05,.16,.07,.25,.07,.32,0,.32-.21,.32-.26,0-.16-.1-.22-.24-.3l-.14-.09c-.18-.11-.29-.22-.29-.45,0-.12,.06-.43,.57-.43,.22,0,.38,.03,.38,.03,0,0,0,.05,0,.13,0,.14,0,.18,0,.18h-.05c0-.06-.03-.11-.07-.15-.06-.06-.17-.08-.25-.08-.25,0-.28,.15-.28,.23,0,.13,.07,.19,.21,.27l.16,.09c.2,.12,.32,.22,.32,.47,0,.33-.26,.47-.62,.47h0Z"/>
                        <path className="worldsLogo"
                              d="M17.15,8.61s-.04,.12-.04,.33v.85c0,.16,0,.21,.03,.25,.02,.03,.06,.06,.13,.06v.04c-.1,0-.25,0-.33,0s-.23,0-.33,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.35h-.71v.43c0,.16,0,.21,.03,.25,.02,.03,.06,.06,.13,.06v.04c-.1,0-.25,0-.33,0s-.23,0-.33,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.85c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.25,0,.33,0s.23,0,.33,0v.04s-.08,.01-.12,.05c-.04,.05-.04,.12-.04,.33v.29h.71v-.36c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.25,0,.33,0s.23,0,.33,0v.04s-.08,.01-.12,.05h0Z"/>
                        <path className="worldsLogo"
                              d="M18.08,8.61s-.04,.12-.04,.33v.85c0,.16,0,.21,.03,.25,.02,.03,.06,.06,.13,.06v.04c-.1,0-.25,0-.33,0s-.23,0-.33,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.85c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.25,0,.33,0s.23,0,.33,0v.04s-.08,.01-.12,.05Z"/>
                        <path className="worldsLogo"
                              d="M19.21,9.42c-.11,0-.16-.02-.16-.02v-.05s.03,0,.05,0c.07,0,.13-.02,.18-.05,.08-.06,.12-.17,.12-.33,0-.36-.21-.37-.33-.37-.04,0-.1,0-.1,0v1.09c0,.19,0,.27,.03,.32,.03,.05,.09,.07,.19,.07v.04c-.1,0-.31,0-.4,0s-.22,0-.33,0v-.04s.08-.01,.12-.05c.04-.05,.04-.12,.04-.33v-.85c0-.16,0-.21-.03-.25-.02-.03-.06-.06-.13-.06v-.04c.1,0,.21,0,.3,0,.14,0,.22,0,.37,0,.05,0,.18,0,.28,.02,.18,.04,.35,.15,.35,.41,0,.33-.25,.47-.55,.47h0Z"/>
                    </g>
                    <g>
                        <path className="worldsLogo"
                              d="M21.75,10.13h-1.1v-.11c.17-.14,.45-.36,.61-.6,.07-.11,.12-.25,.12-.39,0-.19-.07-.36-.32-.36-.06,0-.2,0-.31,.16l-.06-.04c.03-.06,.14-.29,.55-.29,.09,0,.17,.01,.24,.04,.18,.07,.28,.21,.28,.41,0,.21-.08,.39-.44,.68-.09,.07-.2,.16-.34,.25h.44c.12,0,.23,0,.28-.08,.02-.03,.04-.07,.04-.13h.05c0,.06-.02,.29-.02,.47Z"/>
                        <path className="worldsLogo"
                              d="M22.58,10.16c-.54,0-.6-.57-.6-.79,0-.18,0-.87,.63-.87,.54,0,.6,.57,.6,.79,0,.18,0,.87-.63,.87Zm.02-1.56c-.07,0-.15,.02-.21,.21-.03,.11-.05,.28-.05,.51,0,.58,.09,.74,.26,.74,.07,0,.15-.02,.21-.21,.03-.11,.05-.28,.05-.51,0-.58-.09-.74-.26-.74Z"/>
                        <path className="worldsLogo"
                              d="M24.47,10.13h-1.1v-.11c.17-.14,.45-.36,.61-.6,.07-.11,.12-.25,.12-.39,0-.19-.07-.36-.32-.36-.06,0-.2,0-.31,.16l-.06-.04c.03-.06,.14-.29,.55-.29,.09,0,.17,.01,.24,.04,.18,.07,.28,.21,.28,.41,0,.21-.08,.39-.44,.68-.09,.07-.2,.16-.34,.25h.44c.12,0,.23,0,.28-.08,.02-.03,.04-.07,.04-.13h.05c0,.06-.02,.29-.02,.47Z"/>
                        <path className="worldsLogo"
                              d="M25.83,10.13h-1.1v-.11c.17-.14,.45-.36,.61-.6,.07-.11,.12-.25,.12-.39,0-.19-.07-.36-.32-.36-.06,0-.2,0-.31,.16l-.06-.04c.03-.06,.14-.29,.55-.29,.09,0,.17,.01,.24,.04,.18,.07,.28,.21,.28,.41,0,.21-.08,.39-.44,.68-.09,.07-.2,.16-.34,.25h.44c.12,0,.23,0,.28-.08,.02-.03,.04-.07,.04-.13h.05c0,.06-.02,.29-.02,.47Z"/>
                    </g>
                </g>
            </g>
        </g>
    );
}

export default LabelTemplateFABWorldsGradeLogo;
