import { useState, useEffect } from "react";

import { Button, TextField } from "@material-ui/core";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useDeleteFinish } from "../../hooks/catalogHooks";

function useConfirm(message: any, onConfirm: any, onAbort: any) {
  const confirm = (id: any) => {
    if (window.confirm(message)) onConfirm(id);
    else onAbort();
  };
  return confirm;
}

function PrintingOptions(props: { label: string; value: any; onChange: any }) {
  const { value, onChange } = props;
  const [newRow, setNewRow] = useState(false);
  const [newFinish, setNewFinish] = useState("");
  const [localData, setLocalData] = useState(value);

  const { mutateAsync: mutateImage } = useDeleteFinish("");

  useEffect(() => {
    if (value !== localData) {
      setLocalData(value);
    }
  }, [value, localData, setLocalData]);

  const handleDelete = async (id: any) => {
    await mutateImage(id);
  };
  const handleAbort = () => {
    //No handling required
    //onChange([...localData, { printing: "GoldFoil" }]);
  };

  const handleNewFinish = (e: any) => {
    setNewFinish(e.target.value);
  };

  const handleEditFinish = (e: any) => {
    if (newRow) {
      if (newFinish !== "") {
        onChange([...localData, { printing: newFinish }]);
        setNewRow(!newRow);
        setNewFinish("");
      }
    }
    setNewRow(!newRow);
  };

  const confirmDelete = useConfirm("Are you Sure?", handleDelete, handleAbort);

  const columns: GridColDef[] = [
    {
      field: "printing",
      headerName: "Finish / Printing",
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      editable: false,
      headerAlign: "right",
      align: "right",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => {
              confirmDelete(params.row.id);
            }}
            variant="contained"
            size="small"
            color="primary"
          >
            Delete
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 16, width: "100%" }}>
        <DataGrid
          rows={localData.filter((row: any) => row.id !== undefined)}
          columns={columns}
          pageSizeOptions={[100]}
          hideFooter={true}
        />
        {newRow && (
          <TextField
            label="New Finish"
            fullWidth
            style={{ marginTop: "1rem" }}
            onChange={handleNewFinish}
          />
        )}
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleEditFinish}
          style={{ float: "right", marginTop: "1rem" }}
        >
          {!newRow ? "Add a Finish" : "Save"}
        </Button>
      </div>
    </>
  );
}

export default PrintingOptions;
