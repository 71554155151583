import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  trace: {
    fontFamily: "monospace",
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
  },
}));

interface ErrorDialogProps {
  open: boolean;
  onClose: () => void;
  error: {
    detailedMessage: string;
    traceId?: string | null;
  } | null;
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({
  open,
  onClose,
  error,
}) => {
  const classes = useStyles();

  if (!error) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography className={classes.error}>
          {error.detailedMessage}
        </Typography>
        {error.traceId && (
          <Typography className={classes.trace}>
            Trace ID: {error.traceId}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
