import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Chip from "@material-ui/core/Chip";
import { titleCase } from "../../helpers/formattingHelpers";
import AllocateBuckets from "./AllocateBuckets";
import UserActionsMenu from "./UserActionsMenu";
import { useFetchSites } from "../../hooks/siteHooks";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5)
  },
  chip: {
    maxWidth: '120px'
  }
}));

interface UserTableRowProps {
  user: User;
  buckets: Bucket[] | undefined;
}

function UserTableRow(props: UserTableRowProps) {
  const { user, buckets } = props;
  const classes = useStyles();
  const { data: sites } = useFetchSites();

  const getRegionLabel = (region: string) => {
    const site = sites?.find(site => site.region === region);
    return site?.label || region;
  };

  return (
    <TableRow key={user.id}>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{titleCase(user.role)}</TableCell>
      <TableCell align="center">
        {user.enabled ? <CheckIcon /> : <NotInterestedIcon />}
      </TableCell>
      <TableCell>
        <AllocateBuckets availableBuckets={buckets} user={user} />
      </TableCell>
      <TableCell>
        <div className={classes.chips}>
          {user.regions?.map((region) => (
            <Chip
              key={region}
              label={getRegionLabel(region)}
              size="small"
              className={classes.chip}
            />
          ))}
        </div>
      </TableCell>
      <TableCell align="right">
        <UserActionsMenu userId={user.id} enabled={user.enabled} />
      </TableCell>
    </TableRow>
  );
}

export default UserTableRow;