import { useState } from "react";

import { Button } from "@material-ui/core";

import EditorModal from "./EditorModal";

import { useUpdateGameInfo, useUpdatGameImage } from "../../hooks/catalogHooks";

function EditGameButton(props: { data: any }) {
  const { data } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { mutateAsync, isMutating } = useUpdateGameInfo();
  const { mutateAsync: mutateImage, isMutating: imageIsMutating } =
    useUpdatGameImage(data.gameCode);

  const handleOnSave = async (localData: any) => {
    console.log("mutate", localData);
    await mutateAsync(localData);
  };

  const handleFileChange = async (file: any) => {
    await mutateImage(file);
  };

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Edit</Button>
      <EditorModal
        title={`Update ${data["game"]}`}
        data={data}
        editableKeys={[
          {
            key: "game",
            type: "string",
          },
          {
            key: "gameCode",
            type: "string",
          },
          {
            key: "finish",
            type: "array",
          },
          {
            key: "gameImage",
            type: "image",
          },
          {
            key: "description",
            type: "string",
          },
          {
            key: "reviewed",
            type: "boolean",
          },
        ]}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={(localData: any) => handleOnSave(localData)}
        isMutating={isMutating || imageIsMutating}
        onFileChange={handleFileChange}
      />
    </>
  );
}

export default EditGameButton;
