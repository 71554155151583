import React, { useCallback, useState, createContext, useEffect } from "react";
import Alert from "../components/generic/Alert";
import { ErrorDialog } from "../components/generic/ErrorDialog";

const ALERT_TIMEOUT = 5000; // ms

interface AlertState {
  alerts: Alert[];
  error: {
    detailedMessage: string;
    traceId?: string | null;
  } | null;
}

interface AlertContextProps {
  addAlert: (message: string, severity?: Severity) => void;
  removeAlert: () => void;
  showError: (error: {
    detailedMessage: string;
    traceId?: string | null;
  }) => void;
  clearError: () => void;
  error: AlertState["error"];
}

const defaultContextValue = {
  addAlert: (message: string, severity?: Severity) => {
    return undefined;
  },
  removeAlert: () => {
    return undefined;
  },
};

export const AlertContext = createContext<AlertContextProps>({
  addAlert: () => undefined,
  removeAlert: () => undefined,
  showError: () => undefined,
  clearError: () => undefined,
  error: null,
});

interface AlertProviderProps {
  children: React.ReactChild | React.ReactChildren;
}

function AlertProvider(props: AlertProviderProps) {
  const { children } = props;
  const [state, setState] = useState<AlertState>({
    alerts: [],
    error: null,
  });

  const addAlert = useCallback(
    (message: string, severity: Severity = "info") => {
      const newAlert = {
        message,
        severity,
      };
      setState((prev) => ({
        ...prev,
        alerts: [newAlert, ...prev.alerts],
      }));
    },
    []
  );

  const removeAlert = useCallback(() => {
    setState((prev) => ({
      ...prev,
      alerts: prev.alerts.slice(0, prev.alerts.length - 1),
    }));
  }, []);

  const showError = useCallback(
    (error: { detailedMessage: string; traceId?: string | null }) => {
      setState((prev) => ({
        ...prev,
        error,
      }));
    },
    []
  );

  const clearError = useCallback(() => {
    setState((prev) => ({
      ...prev,
      error: null,
    }));
  }, []);

  const activeAlert =
    state.alerts.length > 0 ? (
      <Alert
        severity={state.alerts[0].severity}
        message={state.alerts[0].message}
      />
    ) : null;

  return (
    <AlertContext.Provider
      value={{
        addAlert,
        removeAlert,
        showError,
        clearError,
        error: state.error,
      }}
    >
      {children}
      {activeAlert}
      <ErrorDialog
        open={!!state.error}
        onClose={clearError}
        error={state.error}
      />
    </AlertContext.Provider>
  );
}

export default AlertProvider;
