import { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChangeEnabledStatus from "./ChangeEnabledStatus";
import ResetPassword from "./ResetPassword";

const useStyles = makeStyles({
  link: {
    color: "black",
    textDecoration: "none",
  }
})

interface UserActionsMenuProps {
  userId?: number;
  enabled: boolean;
}

function UserActionsMenu(props: UserActionsMenuProps) {
  const { userId, enabled } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!userId) return null;

  return (
    <>
      <Button
        aria-controls="user-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="user-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ChangeEnabledStatus userId={userId} enabled={enabled} />
        </MenuItem>
        <Link to={`/users/${userId}`} onClick={() => handleClose()} className={classes.link}>
          <MenuItem>Edit details</MenuItem>
        </Link>
        <MenuItem>
          <ResetPassword userId={userId} />
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserActionsMenu;
