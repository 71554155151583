import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useFetchUsers } from "../hooks/userHooks";
import UserTable from "../components/user/UserTable";
import Loader from "../components/generic/Loader";
import GenericError from "../components/generic/GenericError";

function UsersView() {
  const { isLoading, data, error } = useFetchUsers();

  if (isLoading) return <Loader />;

  if (error) {
    console.error(error);
    return <GenericError />;
  }

  return (
    <>
      <Typography variant="h2">Users</Typography>
      <Grid container justifyContent="flex-end">
        <Button
          startIcon={<PersonAddIcon />}
          color="primary"
          variant="contained"
          component={Link}
          to="/users/new"
        >
          Add User
        </Button>
      </Grid>
      <UserTable users={data} />
    </>
  );
}

export default UsersView;
