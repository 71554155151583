import { useQuery } from "react-query";
import { fetchWithToken } from "../services/api";
import { API_BASE_URL } from "../config/api";

export interface Site {
  url: string;
  region: string;
  label: string;
  address: string;
  currencyCode: string;
}

export const fetchSites = async (): Promise<Site[]> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/sites`,
  });

export const useFetchSites = () => {
  return useQuery(["sites"], fetchSites, {
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
  });
};
