import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ApiProvider from "../providers/ApiProvider";
import AuthProvider from "../providers/AuthProvider";
import AlertProvider from "../providers/AlertProvider";
import AppRouting from "./AppRouting";
import AppHeader from "./AppHeader";
import AuthCheck from "../components/auth/AuthCheck";
import { theme } from "../theme";

function App() {
  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ApiProvider>
            <AuthCheck>
              <AlertProvider>
                <AppRouting>
                  <AppHeader />
                </AppRouting>
              </AlertProvider>
            </AuthCheck>
          </ApiProvider>
        </AuthProvider>
      </ThemeProvider>
    </CssBaseline>
  );
}

export default App;
