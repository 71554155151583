import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import { useAlert } from "../hooks/alertHooks";
import { useUpdateCardInfo } from "../hooks/cardHooks";
import { useFetchGradingById } from "../hooks/gradingHooks";
import ProgressButton from "../components/generic/ProgressButton";
import CardSelection from "../components/card/CardSelection";
import ManualEntry from "../components/card/ManualEntry";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),

      "& > * > .MuiPaper-root": {
        padding: theme.spacing(2)
      }
    },
    title: {
      display: "flex",
      justifyContent: "space-between"
    },
    button: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      minWidth: "12rem"
    }
  })
);

interface RouterProps {
  gradingId?: string;
}

type EntryType = "selectCard" | "manual";

function UpdateCardView() {
  const { gradingId } = useParams<RouterProps>();
  const history = useHistory();
  // Card details form
  const [cardId, setCardId] = useState<number>();
  const [game, setGame] = useState<string>("");
  const [gameCode, setGameCode] = useState<string>("");
  const [setName, setSetName] = useState<string>("");
  const [cardName, setCardName] = useState<string>("");
  const [cardNumber, setCardNumber] = useState<string>("");
  const [finish, setFinish] = useState<string>("");
  const [cardValue, setCardValue] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [rarity, setRarity] = useState<string>("");
  const [cardError, setCardError] = useState<string>("");
  const [entryMethod, setEntryMethod] = useState<EntryType>();
  const [selectedCard, setSelectedCard] = useState<SearchCard>();
  const classes = useStyles();
  const { isLoading, data: grading } = useFetchGradingById(Number(gradingId));
  const { mutateAsync, isMutating } = useUpdateCardInfo();
  const { addAlert } = useAlert();

  useEffect(() => {
    if (grading?.id) {
      const details = grading?.gameProduct ?? grading;
      if (!details) return;
      setGame(grading.game);
      if (grading?.gameProduct) {
        setGameCode(grading.gameProduct.gameCode);
        setEntryMethod("selectCard");
      } else {
        setEntryMethod("manual");
      }
      setSetName(grading?.setName ?? grading?.gameProduct?.setName);
      setCardName(grading?.cardName ?? grading?.gameProduct?.cardName);
      setCardNumber(grading?.cardNumber ?? grading?.gameProduct?.number);
      setFinish(grading?.finish || "");
      setCardValue(String(grading?.cardValue || ""));
      setYear(String(grading?.year || ""));
      setLanguage(grading?.language || "English");
      setCardError(grading.cardError || "");
      setRarity(grading.rarity || "");
      setCardId(grading?.gameProduct?.id);
      const selectedCardData = grading?.gameProduct
        ? {
            id: details.id,
            gameCode: grading.gameProduct.gameCode || "",
            title: details.title,
            cardName: details.cardName,
            rarity: grading.gameProduct.rarity,
            number: grading.gameProduct.number,
            image: grading.gameProduct.image,
            setName: details.setName,
            language: grading.language
          }
        : {
            id: undefined,
            gameCode: "",
            title: details.title,
            cardName: details.cardName,
            rarity: "",
            number: grading?.cardNumber || "",
            image: "",
            setName: details.setName,
            language: grading.language
          };
      setSelectedCard(selectedCardData);
    }
  }, [grading]);

  const handleClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push("/gradings");
    }
  };

  const updateCardInfo = async () => {
    try {
      const data =
        entryMethod === "selectCard"
          ? {
              gradingItemId: Number(gradingId),
              game,
              setName,
              cardName,
              cardNumber,
              gameProductId: selectedCard?.id,
              cardValue: Number(cardValue),
              finish,
              rarity,
              cardError,
              language
            }
          : {
              gradingItemId: Number(gradingId),
              game,
              setName,
              cardName,
              cardNumber,
              cardValue: Number(cardValue),
              finish,
              rarity,
              cardError,
              language
            };
      if (year !== "") {
        // @ts-ignore
        data.year = String(year);
      }
      await mutateAsync(data);
      addAlert("Card details updated", "success");
    } catch (error) {
      addAlert("Failed to update card deails", "error");
    }
  };

  if (!gradingId || isLoading) return null;

  if (!grading) {
    return <p>No grading found</p>;
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullScreen={true}
      className={classes.root}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h5" component="h2">
          Edit Card Details
        </Typography>
        <IconButton aria-label="close" onClick={() => handleClose()}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <FormControl component="fieldset">
        <FormLabel component="legend">Entry type</FormLabel>
        <RadioGroup
          row
          aria-label="entryMethod"
          name="controlled-radio-buttons-group"
          value={entryMethod}
          onChange={event => setEntryMethod(event.target.value as EntryType)}
        >
          <FormControlLabel
            value="selectCard"
            control={<Radio />}
            label="Select card"
          />
          <FormControlLabel
            value="manual"
            control={<Radio />}
            label="Manual entry"
          />
        </RadioGroup>
      </FormControl>
      {entryMethod === "selectCard" ? (
        <CardSelection
          game={game}
          setGame={setGame}
          setName={setName}
          setSetName={setSetName}
          cardName={cardName}
          setCardName={setCardName}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          finish={finish}
          setFinish={setFinish}
          cardValue={cardValue}
          setCardValue={setCardValue}
          year={year}
          setYear={setYear}
          rarity={rarity}
          setRarity={setRarity}
          cardError={cardError}
          setCardError={setCardError}
          cardId={cardId}
          setCardId={setCardId}
          language={language}
          setLanguage={setLanguage}
        />
      ) : null}
      {entryMethod === "manual" ? (
        <ManualEntry
          gameCode={gameCode}
          setGameCode={setGameCode}
          setName={setName}
          setSetName={setSetName}
          cardName={cardName}
          setCardName={setCardName}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          finish={finish}
          setFinish={setFinish}
          cardValue={cardValue}
          setCardValue={setCardValue}
          year={year}
          setYear={setYear}
          rarity={rarity}
          setRarity={setRarity}
          cardError={cardError}
          setCardError={setCardError}
          language={language}
          setLanguage={setLanguage}
        />
      ) : null}
      <Grid container spacing={2}>
        <ProgressButton
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => updateCardInfo()}
          inProgress={isMutating}
        >
          Save
        </ProgressButton>

        <Button
          className={classes.button}
          variant="contained"
          onClick={() => handleClose()}
        >
          Close
        </Button>
      </Grid>
    </Dialog>
  );
}

export default UpdateCardView;
