import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import OrderGradingsTableRow from "./OrderGradingsTableRow";

const useStyles = makeStyles((theme) => ({
  header: {
    // backgroundColor: theme.palette.primary.light,
    // borderTop: `2px solid ${theme.palette.primary.light}`,
    // borderBottom: `2px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.main,
  },
  rowH: {},
  row: {
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    borderRight: `1px solid ${theme.palette.primary.light}`,
  },
  headerCheckbox: {
    backgroundColor: "#ffff",
    borderRadius: 0,
    color: theme.palette.primary.main,
  },
}));

interface OrderGradingsProps {
  gradings: Grading[];
  selectedGradings: Grading[];
  currentBucket: string;
  buckets: Bucket[];
  role?: string;
  onRowSelectClick: (
    event: React.MouseEvent<unknown>,
    grading: Grading
  ) => void;
  onSelectAllGradingsClick: (checked: boolean) => void;
}

function OrderGradings(props: OrderGradingsProps) {
  const {
    gradings,
    currentBucket,
    buckets,
    role,
    selectedGradings,
    onRowSelectClick,
    onSelectAllGradingsClick,
  } = props;
  const [selectedAll, setSelectedAll] = useState(false);
  const classes = useStyles();

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelectedAll(checked);
    onSelectAllGradingsClick(checked);
  };

  return (
    <Table size="small">
      <TableHead className={classes.header}>
        <TableRow className={classes.rowH}>
          <TableCell className={classes.header} padding="checkbox">
            <Checkbox
              className={classes.headerCheckbox}
              checked={selectedAll}
              onChange={handleSelectAllClick}
              inputProps={{ "aria-label": "select all gradings" }}
            />
          </TableCell>
          <TableCell className={classes.header}>Card</TableCell>
          <TableCell className={classes.header} align="right">
            Value ($)
          </TableCell>
          <TableCell className={classes.header} align="right">
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {gradings.map((grading, index) => (
          <OrderGradingsTableRow
            key={index}
            grading={grading}
            selectedGradings={selectedGradings}
            currentBucket={currentBucket}
            buckets={buckets}
            role={role}
            onRowSelectClick={onRowSelectClick}
          />
        ))}
      </TableBody>
    </Table>
  );
}

export default OrderGradings;
