import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useFetchBuckets, useFetchMe } from "../../hooks/userHooks";
import { titleCase } from "../../helpers/formattingHelpers";

const useStyles = makeStyles({
  formControl: {
    minWidth: "8rem",
    marginTop: "1rem",
  },
});

interface BucketSelectProps {
  currentBucket?: string;
  handleBucketChange: (newBucket: string) => void;
}

function BucketSelect(props: BucketSelectProps) {
  const { currentBucket, handleBucketChange } = props;
  const { isLoading, data: currentUser } = useFetchMe();
  const { isLoading: isLoadingBuckets, data: buckets } = useFetchBuckets();
  const classes = useStyles();

  useEffect(() => {
    // Set default bucket
    const availableBuckets = currentUser?.buckets || [];
    if (currentBucket === undefined && availableBuckets.length > 0) {
      // handleBucketChange(availableBuckets[0]);
    }
  }, [currentUser, currentBucket, handleBucketChange]);

  if (isLoading || isLoadingBuckets) return null;

  if (!currentUser?.buckets) return null;

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    handleBucketChange(event.target.value as string);
  };

  const availableBuckets =
    buckets
      ?.map((bucket) => bucket.name)
      .filter((bucketName) => currentUser.buckets?.includes(bucketName)) || [];

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="select-bucket-label">Select bucket</InputLabel>
      <Select
        labelId="select-bucket-label"
        id="select-bucket"
        value={currentBucket || ""}
        onChange={handleChange}
      >
        {availableBuckets.map((bucket) => (
          <MenuItem key={bucket} value={bucket}>
            {titleCase(bucket)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default BucketSelect;
