import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import UserForm from "../components/user/UserForm";

function CreateUserView() {
  const history = useHistory();

  const handleClose = () => {
    history.push("/users");
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create User</DialogTitle>
      <UserForm
        ActionsWrapper={DialogActions}
        closeForm={handleClose}
      />
    </Dialog>
  );
}

export default CreateUserView;
