import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useFetchBuckets, useFetchMe } from "../../hooks/userHooks";
import OrderTableHeader from "./OrderTableHeader";
import OrderTableRow from "./OrderTableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface OrderTableProps {
  orders?: Order[];
  currentBucket?: string;
  selectedGradings: Grading[];
  emptyRows: number;
  rowsSelected: number;
  rowCount: number;
  order: AscendingOrder;
  orderBy?: string;
  canOrderRows: boolean;
  setSelectedGradings: React.Dispatch<React.SetStateAction<Grading[]>>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

function OrderTable(props: OrderTableProps) {
  const {
    orders,
    currentBucket,
    selectedGradings,
    emptyRows,
    order: orderDirection,
    orderBy,
    canOrderRows,
    setSelectedGradings,
    onRequestSort,
  } = props;
  const classes = useStyles();
  const { data: currentUser } = useFetchMe();
  const { data: buckets } = useFetchBuckets();

  if (!orders) return null;

  if (orders.length === 0)
    return <p>There are currently no matching orders found</p>;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <OrderTableHeader
          orderDirection={orderDirection}
          orderBy={orderBy}
          canOrderRows={canOrderRows}
          onRequestSort={onRequestSort}
        />
        <TableBody>
          {orders.map((order) => (
            <OrderTableRow
              key={order.id}
              order={order}
              selectedGradings={selectedGradings}
              currentBucket={currentBucket || ""}
              buckets={buckets || []}
              role={currentUser?.role}
              setSelectedGradings={setSelectedGradings}
            />
          ))}
          {emptyRows > 0 ? (
            <TableRow style={{ height: 73 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

OrderTable.defaultProps = {
  gradings: [],
};

export default OrderTable;
