import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { useAddCardToOrder } from "../../hooks/orderHooks";
import CardSelection from "../card/CardSelection";
import ManualEntry from "../card/ManualEntry";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

type EntryType = "selectCard" | "manual";

interface AddCardButtonProps {
  order: Order;
}

export const AddCardButton: React.FC<AddCardButtonProps> = ({ order }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [entryMethod, setEntryMethod] = useState<EntryType>();
  const [gameCode, setGameCode] = useState("");
  const [game, setGame] = useState("");
  const [setName, setSetName] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardId, setCardId] = useState<number>();
  const [cardNumber, setCardNumber] = useState("");
  const [finish, setFinish] = useState("");
  const [cardValue, setCardValue] = useState("");
  const [year, setYear] = useState("");
  const [rarity, setRarity] = useState("");
  const [cardError, setCardError] = useState("");
  const [language, setLanguage] = useState<string>("");
  const [selectedCard, setSelectedCard] = useState<SearchCard>();

  const { mutateAsync, isLoading } = useAddCardToOrder(order.id);

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setEntryMethod(undefined);
    setGameCode("");
    setGame("");
    setSetName("");
    setCardName("");
    setCardNumber("");
    setFinish("");
    setCardValue("");
    setYear("");
    setRarity("");
    setCardError("");
    setLanguage("English");
    setSelectedCard(undefined);
  };

  const handleSubmit = async () => {
    const request = {
      manualEntry: entryMethod === "manual",
      gameProductId: selectedCard?.id,
      cardDetails: {
        name: order.type,
        subgradeRequired: !order.type.toLowerCase().includes("authentication"),
        productId: order.orderItems[0]?.productId || 0,
        quantity: 1,
        title: order.type,
        variantId: order.orderItems[0]?.variantId || 0,
        variantTitle: order.orderItems[0]?.variantTitle || "Standard",
        game: game || selectedCard?.gameCode || "",
        setName,
        cardName,
        cardNumber,
        finish,
        language,
        cardValue: Number(cardValue),
        cardError,
        rarity,
        slabType: "Normal",
      },
    };

    await mutateAsync(request);
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        className={classes.button}
      >
        Add Card
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Add Card to Order {order.orderName}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Entry type</FormLabel>
            <RadioGroup
              row
              value={entryMethod}
              onChange={(e) => setEntryMethod(e.target.value as EntryType)}
            >
              <FormControlLabel
                value="selectCard"
                control={<Radio />}
                label="Select card"
              />
              <FormControlLabel
                value="manual"
                control={<Radio />}
                label="Manual entry"
              />
            </RadioGroup>
          </FormControl>

          {entryMethod === "selectCard" && (
            <CardSelection
              game={game}
              setGame={setGame}
              setName={setName}
              setSetName={setSetName}
              cardName={cardName}
              setCardName={setCardName}
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              finish={finish}
              setFinish={setFinish}
              cardValue={cardValue}
              setCardValue={setCardValue}
              year={year}
              setYear={setYear}
              rarity={rarity}
              setRarity={setRarity}
              cardError={cardError}
              setCardError={setCardError}
              cardId={cardId}
              setCardId={setCardId}
              language={language}
              setLanguage={setLanguage}
            />
          )}

          {entryMethod === "manual" && (
            <ManualEntry
              gameCode={gameCode}
              setGameCode={setGameCode}
              setName={setName}
              setSetName={setSetName}
              cardName={cardName}
              setCardName={setCardName}
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              finish={finish}
              setFinish={setFinish}
              cardValue={cardValue}
              setCardValue={setCardValue}
              year={year}
              setYear={setYear}
              rarity={rarity}
              setRarity={setRarity}
              cardError={cardError}
              setCardError={setCardError}
              language={language}
              setLanguage={setLanguage}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isLoading || !entryMethod}
          >
            Add Card
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
