import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import NotesForm from "./NotesForm";
import NotesList from "./NotesList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
  },
  title: {
    color: theme.palette.primary.main,
  },
}));

interface GradingNotesProps {
  gradingId: number;
  notes: Note[];
}

function GradingNotes(props: GradingNotesProps) {
  const { gradingId, notes } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.title} gutterBottom>
        Notes
      </Typography>
      <NotesList notes={notes} />
      <NotesForm gradingId={gradingId} ActionsWrapper={FormGroup} />
    </Paper>
  );
}

export default GradingNotes;
