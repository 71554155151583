import Chart from "react-apexcharts";
import { useFetchBuckets, useFetchMe } from "../../hooks/userHooks";
import { useFetchCountByBuckets } from "../../hooks/gradingHooks";
import { titleCase } from "../../helpers/formattingHelpers";
import Loader from "../generic/Loader";

function BucketGraph() {
  const { isLoading: isLoadingUser, data: currentUser } = useFetchMe();
  const { isLoading: isLoadingBuckets, data: buckets } = useFetchBuckets();

  const availableBuckets =
    buckets
      ?.map((bucket) => bucket.name)
      .filter((bucketName) => currentUser?.buckets?.includes(bucketName)) || [];

  const countResponses = useFetchCountByBuckets(availableBuckets);

  const isLoading = isLoadingUser || isLoadingBuckets;

  if (isLoading) return <Loader />;

  const categories = [] as string[];
  const counts = [] as number[];

  countResponses.forEach((response) => {
    if (response.isFetched) {
      const { name, count } = response.data as CountResponse;
      categories.push(titleCase(name)!);
      counts.push(count);
    }
  });

  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories,
    },
    colors: ["#d1a96f"],
  };
  const series = [
    {
      name: "cards",
      data: counts,
    },
  ];

  return <Chart options={options} series={series} type="bar" width="1024" />;
}

export default BucketGraph;
