import { makeStyles } from "@material-ui/core/styles";
import { ImageList } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ImageIcon from "@material-ui/icons/Image";
import Image from "./Image";
import ImageUpload from "./ImageUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
  },
  title: {
    color: theme.palette.primary.main,
  },
  placeholder: {
    color: "#999",
  },
  gridList: {
    transform: "translateZ(0)",
  },
}));

interface GradingImagesProps {
  gradingId: number;
  files: UploadedFile[];
  readOnly?: boolean;
}

function GradingImages(props: GradingImagesProps) {
  const { gradingId, files, readOnly } = props;
  const classes = useStyles();

  const placeholderCount = Math.max(3 - files.length, 0);
  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.title} gutterBottom>
        Images
      </Typography>
      <ImageList className={classes.gridList} cols={2.5}>
        {files.map((file) => (
          <Image
            gradingId={gradingId}
            file={file}
            key={file.fileUrl}
            readOnly={readOnly}
          />
        ))}
        {new Array(placeholderCount).fill(0).map((_, count) => (
          <ImageIcon className={classes.placeholder} key={count} />
        ))}
      </ImageList>
      {!readOnly ? <ImageUpload gradingId={gradingId} /> : null}
    </Paper>
  );
}

GradingImages.defaultProps = {
  readOnly: false,
};

export default GradingImages;
