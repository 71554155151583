// src/helpers/siteHelpers.ts
export const getCurrentRegion = (): string | null => {
  const { hostname } = window.location;

  if (hostname === "admin.premiercardgrading.com") return "us";
  if (hostname === "admin.premiercardgrading.co.nz") return "nz";
  if (hostname === "admin.premiercardgrading.com.au") return "au";
  if (hostname === "admin.premiercardgrading.com.my") return "my";
  if (hostname === "admin.premiercardgrading.eu") return "eu";
  if (hostname === "admin.premiercardgrading.co.uk") return "uk";
  if (hostname === "admin.premiercardgrading.jp") return "jp";

  // For development
  return "nz";
};

export const isHostnameForRegion = (region: string): boolean => {
  return getCurrentRegion() === region;
};
