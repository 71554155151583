import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

interface ApiProviderProps {
  children: React.ReactChild | React.ReactChildren;
}

function ApiProvider(props: ApiProviderProps) {
  const { children } = props;
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
}

export default ApiProvider;