export const DEFAULT_GRADING_ID = 7;

export const gradingComplete = {
  id: 7,
  barcode: "00000000007",
  orderBarcode: "00000000001",
  subgradeRequired: true,
  bucket: "encapsulating",
  gradedId: "1234567",
  completed: false,
  completedOn: "",
  gradedBy: "",
  sealedBy: "",
  centeringGrade: 10,
  cornersGrade: 10,
  edgesGrade: 10,
  surfaceGrade: 10,
  finalGrade: 10,
  grade: "Pristine",
  slabType: "Normal",
  signedOffBy: "",
  signedOffAt: "",
  gradingItemId: 36,
  quantity: 1,
  title: "Card Grading",
  variantTitle: "Standard",
  productId: 6731374330033,
  variantId: 40111450325169,
  name: "Card Grading - Standard",
  game: "Pokemon",
  setName: "Base Set",
  cardName: "Charizard",
  cardNumber: "004",
  finish: "Holofoil",
  rarity: "Ultra Rare",
  cardValue: 5000.0,
  graded: false,
  gameProduct: null,
  files: [
    {
      id: 9,
      visibleToCustomer: true,
      fileName: "36-693432a0-76c9-4645-be98-2699a618c4d9-1624955964197.jpg",
      fileUrl:
        "https://storage.googleapis.com/card-grading-files/36-693432a0-76c9-4645-be98-2699a618c4d9-1624955964197.jpg",
      uploadedBy: "pete+admin@binderpos.com",
      gradingId: 36,
      uploadDate: "2021-06-29T08:39:24.896Z",
    },
    {
      id: 10,
      visibleToCustomer: true,
      fileName: "36-1226619c-843e-46d5-8409-96d55c5661ed-1624955975435.jpg",
      fileUrl:
        "https://storage.googleapis.com/card-grading-files/36-1226619c-843e-46d5-8409-96d55c5661ed-1624955975435.jpg",
      uploadedBy: "pete+admin@binderpos.com",
      gradingId: 36,
      uploadDate: "2021-06-29T08:39:36.107Z",
    },
    {
      id: 11,
      visibleToCustomer: true,
      fileName: "36-b4b4dd15-989a-43a7-b870-9386ce5f76e8-1624955975469.jpg",
      fileUrl:
        "https://storage.googleapis.com/card-grading-files/36-b4b4dd15-989a-43a7-b870-9386ce5f76e8-1624955975469.jpg",
      uploadedBy: "pete+admin@binderpos.com",
      gradingId: 36,
      uploadDate: "2021-06-29T08:39:36.130Z",
    },
  ],
  notes: [
    {
      id: 1,
      uploadDate: "2021-06-21T05:39:06.529Z",
      note: "Test private note",
      noteBy: "pete+admin@binderpos.com",
      userRole: "admin",
      bucket: "receiving/research and id",
      lastUpdated: "2021-06-21T05:39:06.529Z",
      isPrivate: true,
      gradingId: 36,
    },
    {
      id: 2,
      uploadDate: "2021-06-21T05:39:27.967Z",
      note: "Test public note",
      noteBy: "pete+admin@binderpos.com",
      userRole: "admin",
      bucket: "receiving/research and id",
      lastUpdated: "2021-06-21T05:39:27.967Z",
      isPrivate: false,
      gradingId: 36,
    },
  ],
};

export const gradings = {
  totalElements: 6,
  totalPages: 2,
  data: [
    {
      id: 28,
      customerEmail: "rhys@glaskintechindustries.com",
      customerFirstName: "Rhys",
      customerLastName: "Glaskin",
      bucket: "receiving/research and id",
      gradedId: "ad3c2f73-905a-412b-ac4f-d418a8d091db",
      completed: false,
      completedOn: null,
      gradedBy: null,
      sealedBy: null,
      centeringGrade: null,
      cornersGrade: null,
      edgesGrade: null,
      surfaceGrade: null,
      finalGrade: null,
      grade: null,
      slabType: "Normal",
      signedOffBy: null,
      signedOffAt: null,
      gradingItemId: 28,
      quantity: 1,
      title: "Card Grading",
      variantTitle: "Standard",
      productId: 6731374330033,
      variantId: 40111450325169,
      name: "Card Grading - Standard",
      game: "Flesh and Blood",
      setName: "Crucible of War",
      cardName: "Shard",
      cardNumber: "000",
      finish: "Cold Foil",
      cardValue: 17000.0,
      graded: false,
      gameProduct: null,
      files: [
        {
          id: 19,
          visibleToCustomer: true,
          fileName: "28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 28,
          uploadDate: "2021-07-06T08:32:25.989Z",
        },
        {
          id: 20,
          visibleToCustomer: true,
          fileName: "28-5dec8826-ca8b-4f60-af96-3272dc2a9b03-1625560354061.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-5dec8826-ca8b-4f60-af96-3272dc2a9b03-1625560354061.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 28,
          uploadDate: "2021-07-06T08:32:34.806Z",
        },
        {
          id: 21,
          visibleToCustomer: true,
          fileName: "28-bce81e55-92dd-418e-b8a4-12e3e42f6c4b-1625560359313.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-bce81e55-92dd-418e-b8a4-12e3e42f6c4b-1625560359313.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 28,
          uploadDate: "2021-07-06T08:32:39.971Z",
        },
        {
          id: 22,
          visibleToCustomer: true,
          fileName: "28-64b2a3e2-c96b-4b9e-95ef-c70efaef6e5c-1625562893944.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-64b2a3e2-c96b-4b9e-95ef-c70efaef6e5c-1625562893944.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 28,
          uploadDate: "2021-07-06T09:14:54.878Z",
        },
      ],
      notes: [
        {
          id: 13,
          uploadDate: "2021-06-29T09:16:58.174Z",
          note: "I did the grade",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:16:58.174Z",
          isPrivate: true,
          gradingId: 28,
        },
        {
          id: 14,
          uploadDate: "2021-06-29T09:18:22.135Z",
          note: "Hmmmmm",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:22.135Z",
          isPrivate: false,
          gradingId: 28,
        },
        {
          id: 15,
          uploadDate: "2021-06-29T09:18:51.622Z",
          note: "hhhhhhh",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:51.622Z",
          isPrivate: false,
          gradingId: 28,
        },
        {
          id: 16,
          uploadDate: "2021-06-29T09:21:11.567Z",
          note: "Test 3",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:21:11.567Z",
          isPrivate: false,
          gradingId: 28,
        },
        {
          id: 17,
          uploadDate: "2021-07-05T05:39:22.206Z",
          note: "This is a test of a private note",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T05:39:22.206Z",
          isPrivate: true,
          gradingId: 28,
        },
        {
          id: 18,
          uploadDate: "2021-07-05T09:41:24.735Z",
          note: "Woah, public note!",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T09:41:24.735Z",
          isPrivate: false,
          gradingId: 28,
        },
      ],
    },
    {
      id: 29,
      customerEmail: "rhys@glaskintechindustries.com",
      customerFirstName: "Rhys",
      customerLastName: "Glaskin",
      bucket: "receiving/research and id",
      gradedId: "e6cc6368-9d7e-40cf-a35c-8528fc4ecd9f",
      completed: false,
      completedOn: null,
      gradedBy: null,
      sealedBy: null,
      centeringGrade: null,
      cornersGrade: null,
      edgesGrade: null,
      surfaceGrade: null,
      finalGrade: null,
      grade: null,
      signedOffBy: null,
      signedOffAt: null,
      slabType: "Player",
      gradingItemId: 29,
      quantity: 1,
      title: "Card Grading",
      variantTitle: "Standard",
      productId: 6731374330033,
      variantId: 40111450325169,
      name: "Card Grading - Standard",
      game: "Pokemon",
      setName: "Base Set",
      cardName: "Charizard",
      cardNumber: "004",
      finish: "Holofoil",
      cardValue: 5000.0,
      graded: false,
      gameProduct: null,
      files: [
        {
          id: 19,
          visibleToCustomer: true,
          fileName: "28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 29,
          uploadDate: "2021-07-06T08:32:25.989Z",
        },
        {
          id: 20,
          visibleToCustomer: true,
          fileName: "28-5dec8826-ca8b-4f60-af96-3272dc2a9b03-1625560354061.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-5dec8826-ca8b-4f60-af96-3272dc2a9b03-1625560354061.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 29,
          uploadDate: "2021-07-06T08:32:34.806Z",
        },
        {
          id: 21,
          visibleToCustomer: true,
          fileName: "28-bce81e55-92dd-418e-b8a4-12e3e42f6c4b-1625560359313.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-bce81e55-92dd-418e-b8a4-12e3e42f6c4b-1625560359313.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 29,
          uploadDate: "2021-07-06T08:32:39.971Z",
        },
        {
          id: 22,
          visibleToCustomer: true,
          fileName: "28-64b2a3e2-c96b-4b9e-95ef-c70efaef6e5c-1625562893944.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-64b2a3e2-c96b-4b9e-95ef-c70efaef6e5c-1625562893944.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 29,
          uploadDate: "2021-07-06T09:14:54.878Z",
        },
      ],
      notes: [
        {
          id: 13,
          uploadDate: "2021-06-29T09:16:58.174Z",
          note: "I did the grade",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:16:58.174Z",
          isPrivate: true,
          gradingId: 29,
        },
        {
          id: 14,
          uploadDate: "2021-06-29T09:18:22.135Z",
          note: "Hmmmmm",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:22.135Z",
          isPrivate: false,
          gradingId: 29,
        },
        {
          id: 15,
          uploadDate: "2021-06-29T09:18:51.622Z",
          note: "hhhhhhh",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:51.622Z",
          isPrivate: false,
          gradingId: 29,
        },
        {
          id: 16,
          uploadDate: "2021-06-29T09:21:11.567Z",
          note: "Test 3",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:21:11.567Z",
          isPrivate: false,
          gradingId: 29,
        },
        {
          id: 17,
          uploadDate: "2021-07-05T05:39:22.206Z",
          note: "This is a test of a private note",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T05:39:22.206Z",
          isPrivate: true,
          gradingId: 29,
        },
        {
          id: 18,
          uploadDate: "2021-07-05T09:41:24.735Z",
          note: "Woah, public note!",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T09:41:24.735Z",
          isPrivate: false,
          gradingId: 29,
        },
      ],
    },
    {
      id: 30,
      customerEmail: "rhys@glaskintechindustries.com",
      customerFirstName: "Rhys",
      customerLastName: "Glaskin",
      bucket: "receiving/research and id",
      gradedId: "2005c6c7-f00d-4211-8036-2ae2baa9408e",
      completed: false,
      completedOn: null,
      gradedBy: null,
      sealedBy: null,
      centeringGrade: null,
      cornersGrade: null,
      edgesGrade: null,
      surfaceGrade: null,
      finalGrade: null,
      grade: null,
      slabType: "Normal",
      signedOffBy: null,
      signedOffAt: null,
      gradingItemId: 30,
      quantity: 1,
      title: "Card Grading",
      variantTitle: "Standard",
      productId: 6731374330033,
      variantId: 40111450325169,
      name: "Card Grading - Standard",
      game: "Magic: the Gathering",
      setName: "Aethers Revolt",
      cardName: "Fatal Push",
      cardNumber: "048",
      finish: "Foil",
      cardValue: 450.0,
      graded: false,
      gameProduct: null,
      files: [
        {
          id: 19,
          visibleToCustomer: true,
          fileName: "28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 30,
          uploadDate: "2021-07-06T08:32:25.989Z",
        },
        {
          id: 20,
          visibleToCustomer: true,
          fileName: "28-5dec8826-ca8b-4f60-af96-3272dc2a9b03-1625560354061.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-5dec8826-ca8b-4f60-af96-3272dc2a9b03-1625560354061.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 30,
          uploadDate: "2021-07-06T08:32:34.806Z",
        },
        {
          id: 21,
          visibleToCustomer: true,
          fileName: "28-bce81e55-92dd-418e-b8a4-12e3e42f6c4b-1625560359313.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-bce81e55-92dd-418e-b8a4-12e3e42f6c4b-1625560359313.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 30,
          uploadDate: "2021-07-06T08:32:39.971Z",
        },
        {
          id: 22,
          visibleToCustomer: true,
          fileName: "28-64b2a3e2-c96b-4b9e-95ef-c70efaef6e5c-1625562893944.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-64b2a3e2-c96b-4b9e-95ef-c70efaef6e5c-1625562893944.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 30,
          uploadDate: "2021-07-06T09:14:54.878Z",
        },
      ],
      notes: [
        {
          id: 13,
          uploadDate: "2021-06-29T09:16:58.174Z",
          note: "I did the grade",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:16:58.174Z",
          isPrivate: true,
          gradingId: 30,
        },
        {
          id: 14,
          uploadDate: "2021-06-29T09:18:22.135Z",
          note: "Hmmmmm",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:22.135Z",
          isPrivate: false,
          gradingId: 30,
        },
        {
          id: 15,
          uploadDate: "2021-06-29T09:18:51.622Z",
          note: "hhhhhhh",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:51.622Z",
          isPrivate: false,
          gradingId: 30,
        },
        {
          id: 16,
          uploadDate: "2021-06-29T09:21:11.567Z",
          note: "Test 3",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:21:11.567Z",
          isPrivate: false,
          gradingId: 30,
        },
        {
          id: 17,
          uploadDate: "2021-07-05T05:39:22.206Z",
          note: "This is a test of a private note",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T05:39:22.206Z",
          isPrivate: true,
          gradingId: 30,
        },
        {
          id: 18,
          uploadDate: "2021-07-05T09:41:24.735Z",
          note: "Woah, public note!",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T09:41:24.735Z",
          isPrivate: false,
          gradingId: 30,
        },
      ],
    },
    {
      id: 31,
      customerEmail: "rhys@glaskintechindustries.com",
      customerFirstName: "Rhys",
      customerLastName: "Glaskin",
      bucket: "receiving/research and id",
      gradedId: "5b5b0b10-67ca-49e4-a387-3a8fe93ff804",
      completed: false,
      completedOn: null,
      gradedBy: null,
      sealedBy: null,
      centeringGrade: null,
      cornersGrade: null,
      edgesGrade: null,
      surfaceGrade: null,
      finalGrade: null,
      grade: null,
      slabType: "Normal",
      signedOffBy: null,
      signedOffAt: null,
      gradingItemId: 31,
      quantity: 1,
      title: "Card Grading",
      variantTitle: "Standard",
      productId: 6731374330033,
      variantId: 40111450325169,
      name: "Card Grading - Standard",
      game: "Magic: the Gathering",
      setName: "Aether Revolt",
      cardName: "Fatal Push",
      cardNumber: "078",
      finish: "F",
      cardValue: 150.0,
      graded: false,
      gameProduct: null,
      files: [
        {
          id: 19,
          visibleToCustomer: true,
          fileName: "28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 31,
          uploadDate: "2021-07-06T08:32:25.989Z",
        },
        {
          id: 20,
          visibleToCustomer: true,
          fileName: "28-5dec8826-ca8b-4f60-af96-3272dc2a9b03-1625560354061.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-5dec8826-ca8b-4f60-af96-3272dc2a9b03-1625560354061.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 31,
          uploadDate: "2021-07-06T08:32:34.806Z",
        },
        {
          id: 21,
          visibleToCustomer: true,
          fileName: "28-bce81e55-92dd-418e-b8a4-12e3e42f6c4b-1625560359313.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-bce81e55-92dd-418e-b8a4-12e3e42f6c4b-1625560359313.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 31,
          uploadDate: "2021-07-06T08:32:39.971Z",
        },
        {
          id: 22,
          visibleToCustomer: true,
          fileName: "28-64b2a3e2-c96b-4b9e-95ef-c70efaef6e5c-1625562893944.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-64b2a3e2-c96b-4b9e-95ef-c70efaef6e5c-1625562893944.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 31,
          uploadDate: "2021-07-06T09:14:54.878Z",
        },
      ],
      notes: [
        {
          id: 13,
          uploadDate: "2021-06-29T09:16:58.174Z",
          note: "I did the grade",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:16:58.174Z",
          isPrivate: true,
          gradingId: 31,
        },
        {
          id: 14,
          uploadDate: "2021-06-29T09:18:22.135Z",
          note: "Hmmmmm",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:22.135Z",
          isPrivate: false,
          gradingId: 31,
        },
        {
          id: 15,
          uploadDate: "2021-06-29T09:18:51.622Z",
          note: "hhhhhhh",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:51.622Z",
          isPrivate: false,
          gradingId: 31,
        },
        {
          id: 16,
          uploadDate: "2021-06-29T09:21:11.567Z",
          note: "Test 3",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:21:11.567Z",
          isPrivate: false,
          gradingId: 31,
        },
        {
          id: 17,
          uploadDate: "2021-07-05T05:39:22.206Z",
          note: "This is a test of a private note",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T05:39:22.206Z",
          isPrivate: true,
          gradingId: 31,
        },
        {
          id: 18,
          uploadDate: "2021-07-05T09:41:24.735Z",
          note: "Woah, public note!",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T09:41:24.735Z",
          isPrivate: false,
          gradingId: 31,
        },
      ],
    },
    {
      id: 34,
      customerEmail: "rhys@glaskintechindustries.com",
      customerFirstName: "Rhys",
      customerLastName: "Glaskin",
      bucket: "receiving/research and id",
      gradedId: "f7b43e40-5bab-46fa-90c1-8d3a46eaedde",
      completed: false,
      completedOn: null,
      gradedBy: null,
      sealedBy: null,
      centeringGrade: 1.0,
      cornersGrade: 2.0,
      edgesGrade: 4.0,
      surfaceGrade: 4.0,
      finalGrade: 5.0,
      grade: "5",
      slabType: "Normal",
      signedOffBy: null,
      signedOffAt: null,
      gradingItemId: 34,
      quantity: 1,
      title: "Card Grading",
      variantTitle: "Standard",
      productId: 6731374330033,
      variantId: 40111450325169,
      name: "Card Grading - Standard",
      game: "Magic: the Gathering",
      setName: "Aethers Revolt",
      cardName: "Fatal Push",
      cardNumber: "048",
      finish: "Foil",
      cardValue: 450.0,
      graded: false,
      gameProduct: null,
      files: [
        {
          id: 19,
          visibleToCustomer: true,
          fileName: "28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          fileUrl:
            "https://storage.googleapis.com/card-grading-files/28-0c3b31d2-cabb-412a-bd7f-84dee9f1955a-1625560344790.jpg",
          uploadedBy: "pete+admin@binderpos.com",
          gradingId: 34,
          uploadDate: "2021-07-06T08:32:25.989Z",
        },
      ],
      notes: [
        {
          id: 13,
          uploadDate: "2021-06-29T09:16:58.174Z",
          note: "I did the grade",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:16:58.174Z",
          isPrivate: true,
          gradingId: 34,
        },
        {
          id: 14,
          uploadDate: "2021-06-29T09:18:22.135Z",
          note: "Hmmmmm",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:22.135Z",
          isPrivate: false,
          gradingId: 34,
        },
        {
          id: 15,
          uploadDate: "2021-06-29T09:18:51.622Z",
          note: "hhhhhhh",
          noteBy: "pete+admin@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-06-29T09:18:51.622Z",
          isPrivate: false,
          gradingId: 34,
        },
        {
          id: 18,
          uploadDate: "2021-07-05T09:41:24.735Z",
          note: "Woah, public note!",
          noteBy: "pete@binderpos.com",
          userRole: "admin",
          bucket: "receiving/research and id",
          lastUpdated: "2021-07-05T09:41:24.735Z",
          isPrivate: false,
          gradingId: 34,
        },
      ],
    },
  ],
};
