import { useState } from "react";

import { Button } from "@material-ui/core";

import EditorModal from "./EditorModal";

import { useUpdatSetInfo, useUpdatSetImage } from "../../hooks/catalogHooks";

function EditSetButton(props: { data: { [key: string]: string } }) {
  const { data } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { mutateAsync, isMutating } = useUpdatSetInfo();
  const {
    mutateAsync: mutateImage,
    isMutating: imageIsMutating
  } = useUpdatSetImage(data.id);

  const handleOnSave = async (localData: any) => {
    await mutateAsync(localData);
    setModalOpen(false);
  };

  const handleFileChange = async (file: any) => {
    await mutateImage(file);
  };

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Edit</Button>
      <EditorModal
        title={`Update ${data["setName"]}`}
        data={data}
        editableKeys={[
          {
            key: "setName",
            type: "string"
          },
          {
            key: "setCategory",
            type: "string"
          },
          {
            key: "releaseDate",
            type: "string"
          },
          {
            key: "setImage",
            type: "image"
          },
          {
            key: "archived",
            type: "boolean"
          }
        ]}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={(localData: any) => handleOnSave(localData)}
        isMutating={isMutating || imageIsMutating}
        onFileChange={handleFileChange}
      />
    </>
  );
}

export default EditSetButton;
