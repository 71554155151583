import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

import GridViewIcon from "@material-ui/icons/ViewComfy";
import TableRowsIcon from "@material-ui/icons/TableChart";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import {
  filterCardsGraded,
  filterRowContains,
  sortGameSetsTable,
  filterArchived
} from "../helpers/tableHelpers";

import FilterSearch from "../components/generic/FilterSearch";
import EditSetButton from "../components/catalog/EditSetButton";

import { useGetGameInfo } from "../hooks/cardHooks";
import AddSetButton from "../components/catalog/AddSetButton";

const SORTKEY_TO_FIELD_MAP = {
  graded: "cardsGraded",
  year: "releaseDate"
};

function CatalogGameView() {
  const params = useParams<{ gameCode: string }>();
  const { gameCode = "" } = params;

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<
    "asc_year" | "desc_year" | "asc_graded" | "desc_graded"
  >("desc_graded");
  const [showArchived, setShowArchived] = useState<"all" | "true" | "false">(
    "all"
  );
  const [mode, setMode] = useState("card");
  const [hideEmpty, setHideEmpty] = useState(false);

  const { game, sets, description, gameImage } = useGetGameInfo(gameCode);

  useEffect(() => {
    setSearch("");
  }, [gameCode]);

  if (game === "" || game === null) {
    return <div>Game does not exist or is not supported.</div>;
  }

  const InitData = {
    setName: "",
    setCategory: null,
    releaseDate: null,
    setImage: null,
    archived: false,
    cardsGraded: 0,
    game: game,
    gameCode: gameCode
  };

  // asc_year becomes [asc, year]
  const [splitOrder, splitKey] = sortBy.split("_");

  const order = splitOrder as "asc" | "desc";
  const key = splitKey as keyof typeof SORTKEY_TO_FIELD_MAP;

  const sortField = SORTKEY_TO_FIELD_MAP[key] as keyof Set<string>;

  const filterArchivedFunc = filterArchived(showArchived);
  const filterCardsGradedFunc = filterCardsGraded(null, hideEmpty);
  const filterRowContainsFunc = filterRowContains(search);
  const sortFunc = sortGameSetsTable(order, sortField);

  const finalResult = sets
    ? sets
        .filter(
          item =>
            filterArchivedFunc(item) &&
            filterCardsGradedFunc(item) &&
            filterRowContainsFunc(item)
        )
        .sort(sortFunc)
    : [];

  return (
    <>
      <Typography variant="h2">Catalog</Typography>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img
            src={gameImage}
            alt={game}
            style={{
              marginBottom: 16,
              width: "100%",
              maxWidth: 400
            }}
          />
          <Typography variant="subtitle1">{description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 1rem"
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <AddSetButton data={InitData} />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Box style={{ margin: "1rem", lineHeight: "52px" }}>
                <Tooltip title="Toggle Empty Sets">
                  <IconButton
                    color="primary"
                    aria-label="toggle empty sets"
                    component="span"
                    onClick={() => setHideEmpty(!hideEmpty)}
                  >
                    {hideEmpty ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
              <Box style={{ margin: "1rem", lineHeight: "52px" }}>
                <Tooltip title="Card View">
                  <IconButton
                    color="primary"
                    aria-label="card view"
                    component="span"
                    disabled={mode === "card"}
                    onClick={() => setMode("card")}
                  >
                    <GridViewIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box style={{ margin: "1rem", lineHeight: "52px" }}>
                <Tooltip title="Table View">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    disabled={mode === "table"}
                    onClick={() => setMode("table")}
                  >
                    <TableRowsIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: "flex" }} width={400}>
                <FormControl style={{ marginRight: "12px" }} fullWidth>
                  <InputLabel id="sort-by-label">Sort by</InputLabel>
                  <Select
                    labelId="sort-by-label"
                    id="sort-by-label"
                    value={sortBy}
                    label="Sort by"
                    onChange={(e: any) => setSortBy(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="asc_year">Newest to Oldest</MenuItem>
                    <MenuItem value="desc_year">Oldest to Newest</MenuItem>
                    <MenuItem value="desc_graded">
                      Most Graded to Least
                    </MenuItem>
                    <MenuItem value="asc_graded">Least Graded to Most</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="filter-by-archived-label">
                    Filter by Archived
                  </InputLabel>
                  <Select
                    labelId="filter-by-archived-label"
                    id="filter-by-archived"
                    value={showArchived}
                    label="Filter by Archived"
                    onChange={(e: any) => setShowArchived(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box style={{ paddingRight: 8 }}>
                <FilterSearch
                  onChange={(value: string) => setSearch(value)}
                  label="Search Sets"
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          {mode === "card" && (
            <Grid container>
              {finalResult.map(set => {
                const { id, setName, cardsGraded, releaseDate, setImage } = set;
                return (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    key={id}
                    style={{ padding: "1rem" }}
                  >
                    <Box
                      style={{
                        textAlign: "center",
                        border: "1px solid #212121",
                        borderRadius: 2,
                        padding: "18px 12px",
                        height: "100%",
                        boxSizing: "border-box",
                        display: "flex"
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexGrow: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column"
                        }}
                      >
                        {setImage && (
                          <Link to={`/catalog/${gameCode}/${setName}`}>
                            <img
                              src={setImage}
                              alt={`${gameCode} logo`}
                              style={{
                                maxWidth: "100%"
                              }}
                            />
                          </Link>
                        )}
                        <Typography
                          variant="h6"
                          component={Link}
                          to={`/catalog/${gameCode}/${setName}`}
                        >
                          {set.setName}
                        </Typography>
                        <Typography>
                          Cards graded: {cardsGraded || 0}
                        </Typography>
                        {releaseDate && (
                          <Typography variant="caption">
                            Release Date: {releaseDate}
                          </Typography>
                        )}
                        <Box
                          style={{
                            textAlign: "right",
                            paddingTop: 8,
                            width: "100%"
                          }}
                        >
                          <EditSetButton data={set} />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
          {mode === "table" && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={150}>Total Grades</TableCell>
                    <TableCell>Set Name</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Release Date</TableCell>
                    <TableCell width={100} style={{ textAlign: "right" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finalResult?.length > 0 ? (
                    finalResult?.map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.cardsGraded}</TableCell>
                        <TableCell>
                          <Link to={`/catalog/${gameCode}/${row.setName}`}>
                            {row.setName}
                          </Link>
                        </TableCell>
                        <TableCell>{row.setCategory}</TableCell>
                        <TableCell>{row.releaseDate}</TableCell>
                        <TableCell>
                          <EditSetButton data={row} />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} style={{ fontStyle: "italic" }}>
                        No results
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CatalogGameView;
