import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFetchGameInfo } from "../../hooks/cardHooks";
import { getFinishByGame } from "../../helpers/cardHelpers";
import { LANGUAGE_OPTIONS } from "./CardDetails";

interface ManualEntryProps {
  gameCode: string;
  setGameCode: React.Dispatch<React.SetStateAction<string>>;
  setName: string;
  setSetName: React.Dispatch<React.SetStateAction<string>>;
  cardName: string;
  setCardName: React.Dispatch<React.SetStateAction<string>>;
  cardNumber: string;
  setCardNumber: React.Dispatch<React.SetStateAction<string>>;
  finish: string;
  setFinish: React.Dispatch<React.SetStateAction<string>>;
  cardValue: string;
  setCardValue: React.Dispatch<React.SetStateAction<string>>;
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  rarity: string;
  setRarity: React.Dispatch<React.SetStateAction<string>>;
  cardError: string;
  setCardError: React.Dispatch<React.SetStateAction<string>>;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

function ManualEntry(props: ManualEntryProps) {
  const {
    gameCode,
    setGameCode,
    setName,
    setSetName,
    cardName,
    setCardName,
    cardNumber,
    setCardNumber,
    finish,
    setFinish,
    cardValue,
    setCardValue,
    year,
    setYear,
    rarity,
    setRarity,
    cardError,
    setCardError,
    language,
    setLanguage
  } = props;
  const { isLoading: isLoadingGames, data: gameInfo } = useFetchGameInfo();
  const finishes = getFinishByGame(gameInfo, gameCode);
  return (
    <>
      <TextField
        required
        name="game"
        label="Game Code"
        fullWidth
        value={gameCode}
        onChange={event => setGameCode(event.target.value)}
        placeholder="e.g. mtg, pkm, fab"
      />
      <TextField
        required
        name="setName"
        label="Set Name"
        fullWidth
        value={setName}
        onChange={event => setSetName(event.target.value)}
        placeholder="e.g. Core Set 2020"
      />
      <TextField
        required
        name="cardName"
        label="Card Name"
        fullWidth
        value={cardName}
        onChange={event => setCardName(event.target.value)}
        placeholder="e.g. Fatal Push"
      />
      <TextField
        required
        name="cardNumber"
        label="Card Number"
        fullWidth
        value={cardNumber}
        onChange={event => setCardNumber(event.target.value)}
      />
      <Autocomplete
        id="finish"
        fullWidth
        freeSolo
        value={finish}
        onChange={(event: any, newValue: string | null) => {
          if (newValue) setFinish(newValue);
        }}
        disabled={isLoadingGames}
        options={finishes.map(option => option.printing)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            name="finish"
            label="Finish"
            onChange={event => setFinish(event.target.value)}
          />
        )}
      />
      <TextField
        required
        name="year"
        label="Year"
        fullWidth
        value={year}
        onChange={event => setYear(event.target.value)}
        placeholder="2020"
      />
      <Autocomplete
        id="language"
        freeSolo
        value={language}
        options={LANGUAGE_OPTIONS}
        renderInput={params => <TextField {...params} label="Language" />}
        onChange={(event, value) => value && setLanguage(value)}
      />
      <TextField
        required
        name="rarity"
        label="Rarity"
        fullWidth
        value={rarity}
        onChange={event => setRarity(event.target.value)}
        placeholder="rare"
      />
      <TextField
        required
        name="cardError"
        label="Card Error"
        fullWidth
        value={cardError}
        onChange={event => setCardError(event.target.value)}
      />
      <TextField
        required
        name="cardValue"
        label="Card Value"
        fullWidth
        value={cardValue}
        onChange={event => setCardValue(event.target.value)}
        placeholder="100"
      />
    </>
  );
}

export default ManualEntry;
