import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { formatDateTime } from "../../helpers/formattingHelpers";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import OrderActionsMenu from "./OrderActionsMenu";
import OrderGradingsTable from "./OrderGradingsTable";
import { AddCardButton } from "./OrderAddCardButton";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const getCustomer = (customer: Customer) => {
  if (customer?.firstName || customer?.lastName) {
    return [customer?.firstName, customer?.lastName].join(" ");
  }
  return customer?.email;
};

interface OrderTableRowProps {
  order: Order;
  selectedGradings: Grading[];
  currentBucket: string;
  buckets: Bucket[];
  role?: string;
  setSelectedGradings: React.Dispatch<React.SetStateAction<Grading[]>>;
}

function OrderTableRow(props: OrderTableRowProps) {
  const {
    order,
    selectedGradings,
    currentBucket,
    buckets,
    role,
    setSelectedGradings,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const toggleGradings = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (open) {
      removeAllGradings();
    }
    setOpen(!open);
  };

  const onRowSelectClick = (
    event: React.MouseEvent<unknown, MouseEvent>,
    grading: Grading
  ) => {
    if (
      selectedGradings.findIndex(
        (selectedGrading) => selectedGrading.id === grading.id
      ) < 0
    ) {
      setSelectedGradings([...selectedGradings, grading]);
    } else {
      setSelectedGradings([
        ...selectedGradings.filter(
          (selectedGrading) => selectedGrading.id !== grading.id
        ),
      ]);
    }
  };

  const addAllGradings = () => {
    const updatedSelection = [...selectedGradings];
    order.orderItems.forEach((grading) => {
      if (
        updatedSelection.findIndex(
          (selectedGrading) => selectedGrading.id === grading.id
        ) < 0
      ) {
        updatedSelection.push(grading);
      }
    });
    setSelectedGradings(updatedSelection);
  };

  const removeAllGradings = () => {
    const gradingIds = order.orderItems.map((grading) => grading.id);
    setSelectedGradings([
      ...selectedGradings.filter(
        (selectedGrading) => !gradingIds.includes(selectedGrading.id)
      ),
    ]);
  };

  const onSelectAllGradingsClick = (checked: boolean) => {
    if (checked) {
      addAllGradings();
    } else {
      removeAllGradings();
    }
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleGradings}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{order.orderName}</TableCell>
        <TableCell>{order.type}</TableCell>
        <TableCell>{formatDateTime(order.receivedAt)}</TableCell>
        <TableCell>{getCustomer(order.customer)}</TableCell>
        <TableCell>{order.orderItems.length}</TableCell>
        <TableCell align="right">
          <Grid container spacing={1} justifyContent="space-between">
            <OrderActionsMenu
              orderId={order.id}
              barcode={order.barcode}
              receivedDate={order.receivedAt || new Date().toISOString()}
              service={order.type}
              orderItems={order.orderItems}
            />
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <OrderGradingsTable
                gradings={order.orderItems}
                currentBucket={currentBucket}
                buckets={buckets}
                role={role}
                selectedGradings={selectedGradings}
                onRowSelectClick={onRowSelectClick}
                onSelectAllGradingsClick={onSelectAllGradingsClick}
              />
            </Box>
            <AddCardButton order={order} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default OrderTableRow;
