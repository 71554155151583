import { useReducer, useState } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import { useFetchOrders } from "../hooks/orderHooks";
import { orderReducer, ActionType } from "../reducers/orderReducer";
import GradingBulkActions from "../components/grading/GradingBulkActions";
import OrderBarcodeScanner from "../components/order/OrderBarcodeScanner";
import OrderInstructions from "../components/order/OrderInstructions";
import OrderTable from "../components/order/OrderTable";
import Loader from "../components/generic/Loader";
import GenericError from "../components/generic/GenericError";

const RESULTS_PER_PAGE = 25;

function OrdersView() {
  const [scannedOrders, setScannedOrders] = useState<Order[]>([]);
  const [orderParams, dispatch] = useReducer(orderReducer, { page: 0 });
  const [order, setOrder] = useState<AscendingOrder>("asc");
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(RESULTS_PER_PAGE);
  const [selectedGradings, setSelectedGradings] = useState<Grading[]>([]);
  const { page, ...searchParams } = orderParams;
  const { isLoading, data, error } = useFetchOrders(
    page * rowsPerPage,
    rowsPerPage,
    order,
    orderBy,
    searchParams
  );

  const handlePageChange = (event: any, newPage: number) =>
    dispatch({ type: ActionType.CHANGE_PAGE, page: Math.max(newPage, 0) });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch({ type: ActionType.CHANGE_PAGE, page: 0 });
  };

  const handleBucketChange = (newBucket: string) => {
    setScannedOrders([]);
    setSelectedGradings([]);
    dispatch({ type: ActionType.CHANGE_BUCKET, bucket: newBucket });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const clearSelected = () => setSelectedGradings([]);

  const emptyRows =
    RESULTS_PER_PAGE -
    Math.min(
      RESULTS_PER_PAGE,
      data?.data.length || 0 - orderParams.page * RESULTS_PER_PAGE
    );

  if (isLoading) return <Loader />;

  if (error) {
    console.error(error);
    return <GenericError />;
  }

  const orderList = scannedOrders.length > 0 ? scannedOrders : data?.data;

  return (
    <>
      <Typography variant="h2">Orders</Typography>
      <OrderInstructions
        currentBucket={orderParams.bucket}
        dispatch={dispatch}
        handleBucketChange={handleBucketChange}
      />
      <OrderBarcodeScanner
        scannedOrders={scannedOrders}
        setScannedOrders={setScannedOrders}
      />
      <GradingBulkActions
        currentBucket={orderParams.bucket || ""}
        selectedGradings={selectedGradings}
        clearSelected={clearSelected}

      />
      <OrderTable
        orders={orderList}
        currentBucket={orderParams.bucket}
        selectedGradings={selectedGradings}
        emptyRows={emptyRows}
        rowsSelected={selectedGradings.length}
        rowCount={orderList?.length || 0}
        order={order}
        orderBy={orderBy}
        canOrderRows={scannedOrders.length === 0}
        onRequestSort={handleRequestSort}
        setSelectedGradings={setSelectedGradings}
      />
      {scannedOrders.length === 0 && data && data.totalPages > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data.totalElements}
          rowsPerPage={rowsPerPage}
          page={orderParams.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
}

export default OrdersView;
