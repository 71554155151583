export const grades = {
  "10": "Pristine",
  "9.75": "Gem Mint",
  "9.5": "Gem Mint",
  "9": "Mint",
  "8.5": "NM-Mint+",
  "8": "NM-Mint",
  "7.5": "Near Mint+",
  "7": "Near Mint",
  "6": "EX-NM",
  "5": "Excellent",
  "4": "Very Good",
  "3": "Good",
  "2": "Fair",
  "1": "Poor",
  "0": "Authentic"
};

export const gradesNumbers = {
  "10": 10,
  "9.75": 10, // "Gem Mint 10" is the same as "Pristine", but needs a unique identifier
  "9.5": 9.5,
  "9": 9,
  "8.5": 8.5,
  "8": 8,
  "7.5": 7.5,
  "7": 7,
  "6": 6,
  "5": 5,
  "4": 4,
  "3": 3,
  "2": 2,
  "1": 1,
  "0": 0
};

export const gradingPanels = {
  "receiving/research and id": ["information", "notesList", "updateImages"],
  receiving: ["information", "notesList"],
  "research and id": ["information", "notesList", "updateImages"],
  grading: ["information", "notesList", "updateScore", "viewImages"],
  "quality checkpoint 1": [
    "information",
    "updateScore",
    "viewScore",
    "notesList",
    "viewImages"
  ],
  "label engraving": ["information", "viewScore", "notesList", "viewImages"],
  "encapsulating/quality checkpoint 2": ["information", "viewScore", "notesList", "viewImages"],
  encapsulating: ["information", "viewScore", "notesList", "viewImages"],
  "quality checkpoint 2": [
    "information",
    "viewScore",
    "notesList",
    "updateImages"
  ],
  "shipping/done": ["information", "viewScore", "notesList", "viewImages"],
  shipping: ["information", "viewScore", "notesList", "viewImages"],
  done: ["information", "viewScore", "notesList", "viewImages"],
  supervisor: ["information", "updateScore", "notesList", "updateImages"]
};
