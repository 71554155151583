import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import BarcodeIcon from "@material-ui/icons/ViewWeek";
import { useFetchBuckets, useFetchMe } from "../../hooks/userHooks";
import AddNoteButton from "./AddNoteButton";
import GradingBarcodeBulk from "./GradingBarcodeBulk";
import MoveToBucket from "./MoveToBucket";

const useStyles = makeStyles({
  toolbar: {
    padding: 0,
  },
  container: {
    padding: "0 1rem",
    backgroundColor: "#ededed",
    borderBottom: "3px solid #ededed",
    display: "flex",
    flex: "1",
    alignItems: "flex-end",
  },
  title: {
    flex: "1 1 100%",
  },
  action: {
    marginLeft: "1rem",
    minWidth: "12rem",
  },
  actionButton: {
    height: "2.2rem",
    marginLeft: "1rem",
    minWidth: "12rem",
  },
  printContent: {
    display: "none",
  },
});

interface GradingBulkActionsProps {
  selectedGradings: Grading[];
  currentBucket: string;
  clearSelected: VoidFunction;
}

function GradingBulkActions(props: GradingBulkActionsProps) {
  const { selectedGradings, currentBucket, clearSelected } = props;
  const barcodeRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const selectedCount = selectedGradings.length;
  const { data: currentUser } = useFetchMe();
  const { data: buckets } = useFetchBuckets();

  const handlePrintBarcodes = useReactToPrint({
    content: () => barcodeRef.current,
    onAfterPrint: () => clearSelected(),
  });

  return (
    <Toolbar className={classes.toolbar}>
      {selectedCount > 0 ? (
        <div className={classes.container}>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {selectedCount} selected
          </Typography>
          <span className={classes.action}>
            <MoveToBucket
              currentBucket={currentBucket}
              gradingIdList={selectedGradings.map((grading) => grading.id)}
              buckets={buckets || []}
              role={currentUser?.role}
              onComplete={clearSelected}
            />
          </span>
          <>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.actionButton}
              startIcon={<BarcodeIcon />}
              onClick={handlePrintBarcodes}
            >
              Print Barcodes
            </Button>
            <div className={classes.printContent}>
              <div ref={barcodeRef}>
                <GradingBarcodeBulk gradings={selectedGradings} />
              </div>
            </div>
          </>
          <AddNoteButton
            gradingIdList={selectedGradings.map((grading) => grading.id)}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.action}
            >
              Add Note
            </Button>
          </AddNoteButton>
        </div>
      ) : null}
    </Toolbar>
  );
}

export default GradingBulkActions;
