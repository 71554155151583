import { useState } from "react";

const roundDown = (num: number): number => {
  // Integers only from 7 downwards
  if (num < 7.25) {
    return Math.floor(num);
  }
  // Half steps from 7.5 upwards
  return Math.floor(num * 2) / 2;
};

const roundUp = (num: number): number => {
  // Assuming number is above 8, so half steps
  return Math.ceil(num * 2) / 2;
};

function GradeCheck() {
  const [subgrade1, setSubgrade1] = useState<string | number>(0);
  const [subgrade2, setSubgrade2] = useState<string | number>(0);
  const [subgrade3, setSubgrade3] = useState<string | number>(0);
  const [subgrade4, setSubgrade4] = useState<string | number>(0);

  const subgrades = [
    Number(subgrade1),
    Number(subgrade2),
    Number(subgrade3),
    Number(subgrade4),
  ].sort((a, b) => b - a); // Reverse numeric sort

  // 1, The Second Highest subgrade
  const secondHighest = subgrades[1];
  // 2. The Third Highest subgrade + 0.5, rounded up if the grade would than equal
  // below a 7
  const thirdHighestPlusHalf =
    subgrades[2] + 0.5 >= 7
      ? roundUp(subgrades[2] + 0.5)
      : roundUp(subgrades[2] + 1);
  // 3. The Forth Highest subgrade +1
  const forthHighestPlusOne = subgrades[3] + 1;
  const findLowestIn = [
    secondHighest,
    thirdHighestPlusHalf < 7
      ? roundUp(thirdHighestPlusHalf)
      : thirdHighestPlusHalf,
    forthHighestPlusOne,
  ];

  // The average of all 4 sub grades +0.125 rounded down
  const average =
    (subgrades[0] + subgrades[1] + subgrades[2] + subgrades[3]) / 4;
  findLowestIn.push(roundDown(average + 0.125));

  // If the 4th subgrade is 0.5 lower than the 3rd subgrade...
  if (subgrades[2] - subgrades[3] === 0.5) {
    // 4a The Third Highest subgrade if the 4th subgrade is  0.5 lower than the 3rd subgrade
    findLowestIn.push(subgrades[2]);
  } else {
    //  4b. The Average of the 3rd & 4th Subgrade rounded UP only if the grade would
    // then equal 9, 9.5 or 10.
    const threeAndFourAverage = (subgrades[2] + subgrades[3]) / 2;
    if (roundUp(threeAndFourAverage) >= 9) {
      findLowestIn.push(roundUp(threeAndFourAverage));
    }
    // The Average of the 3rd and 4th Subgrade rounded DOWN +0.5 if the grade would
    // then be equal to a 7.5, 8 or 8.5
    else if (roundDown(threeAndFourAverage) + 0.5 > 7) {
      findLowestIn.push(roundDown(threeAndFourAverage) + 0.5);
    }
    // The Average of the 3rd and 4th Subgrade rounded DOWN +1 if the grade would
    // then equal below a 7.5
    else {
      findLowestIn.push(roundDown(threeAndFourAverage) + 1);
    }
  }

  // The overall grade will be the same as the two lowest subgrades that equal the same
  // value for 6 and below
  if (subgrades[2] <= 6 && subgrades[2] === subgrades[3]) {
    findLowestIn.push(subgrades[2]);
  }

  const finalGrade = Math.min(...findLowestIn);

  return (
    <div>
      <p>
        <input
          type="number"
          value={subgrade1}
          onChange={(event) => setSubgrade1(event.target.value)}
          step="any"
        />
        <input
          type="number"
          value={subgrade2}
          onChange={(event) => setSubgrade2(event.target.value)}
          step="any"
        />
        <input
          type="number"
          value={subgrade3}
          onChange={(event) => setSubgrade3(event.target.value)}
          step="any"
        />
        <input
          type="number"
          value={subgrade4}
          onChange={(event) => setSubgrade4(event.target.value)}
          step="any"
        />
      </p>
      <p>1 - The Second Highest subgrade</p>
      <h4>{findLowestIn[0]}</h4>
      <p>
        2 - The Third Highest subgrade + 0.5, rounded up if the grade would than
        equal below a 7
      </p>
      <h4>{findLowestIn[1]}</h4>
      <p>3 - The Forth Highest subgrade +1</p>
      <h4>{findLowestIn[2]}</h4>
      <p>The average of all 4 sub grades +0.125 rounded down</p>
      <h4>{findLowestIn[3]}</h4>
      <p>
        4 - The Third Highest subgrade if the 4th is subgrade 0.5 lower than the
        3rd subgrade. Otherwise: the Average of the 3rd &amp; 4th Subgrade
        rounded UP only if the grade would then equal 9, 9.5 or 10, the Average
        of the 3rd and 4th Subgrade rounded DOWN +0.5 if the grade would then be
        equal to a 7.5, 8 or 8.5 or the Average of the 3rd and 4th Subgrade
        rounded DOWN +1 if the grade would then equal below a 7.5
      </p>
      <h4>{findLowestIn[4]}</h4>
      <p>
        The overall grade will be the same as the two lowest subgrades that
        equal the same value for 6 and below
      </p>
      <h4>{findLowestIn[5] ?? "-"}</h4>
      <p>Final Grade</p>
      <h3>{finalGrade}</h3>
    </div>
  );
}

export default GradeCheck;
