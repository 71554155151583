import LabelCustomisation from "../components/label/LabelCustomisation";

interface LabelProps {
  grading: Grading;
}

// Component for testing label customisation. Can be deleted

function Label(props: LabelProps) {
  const cancelAction = (event: {}, reason: string) => {};

  return (
    <LabelCustomisation grading={props.grading} cancelAction={cancelAction} />
  );
}

export default Label;
