import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface HeaderCell {
  id: string;
  label: string;
  sortable: boolean;
}

const headerCells: HeaderCell[] = [
  { id: "gradingOrderItem_cardName", label: "Card", sortable: true },
  { id: "gradingOrderItem_setName", label: "Set", sortable: true },
  { id: "gradingOrderItem_game", label: "Game", sortable: true },
  { id: "value", label: "Value", sortable: false },
];

interface GradingTableHeaderProps {
  rowsSelected: number;
  rowCount: number;
  order: AscendingOrder;
  orderBy?: string;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

function GradingTableHeader(props: GradingTableHeaderProps) {
  const {
    rowsSelected,
    rowCount,
    order,
    orderBy,
    onSelectAllClick,
    onRequestSort,
  } = props;

  const createSortHandler =
    (column: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, column);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={rowsSelected > 0 && rowsSelected < rowCount}
            checked={rowCount > 0 && rowsSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all gradings" }}
          />
        </TableCell>
        {headerCells.map((headerCell) => (
          <TableCell
            key={headerCell.id}
            sortDirection={orderBy === headerCell.id ? order : false}
          >
            {headerCell.sortable ? (
              <TableSortLabel
                active={orderBy === headerCell.id}
                direction={orderBy === headerCell.id ? order : "asc"}
                onClick={createSortHandler(headerCell.id)}
              >
                {headerCell.label}
              </TableSortLabel>
            ) : (
              headerCell.label
            )}
          </TableCell>
        ))}
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default GradingTableHeader;
