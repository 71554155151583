import { makeStyles } from "@material-ui/core/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  spinner: {
    marginLeft: 5,
  },
});

interface ProgressButtonProps extends ButtonProps {
  inProgress: boolean;
}

function ProgressButton(props: ProgressButtonProps) {
  const { inProgress, children, ...buttonProps } = props;
  const classes = useStyles();

  return (
    <Button {...buttonProps}>
      {children}
      {inProgress ? (
        <CircularProgress size={20} className={classes.spinner} />
      ) : null}
    </Button>
  );
}

ProgressButton.defaultProps = {
  inProgress: false,
};

export default ProgressButton;
