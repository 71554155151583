import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BarcodeIcon from "@material-ui/icons/ViewWeekSharp";
import PageviewIcon from "@material-ui/icons/Pageview";
import { Action } from "../../reducers/gradingReducer";
import GradingSearch from "./GradingSearch";
import BucketSelect from "../generic/BucketSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
  },
  avatar: {
    marginRight: "1rem",
    backgroundColor: theme.palette.primary.light,
  },
  step: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "1rem",
    marginBottom: "1rem",
    bgradingRadius: "1rem",
  },
  label: {
    color: theme.palette.primary.main,
  },
  stepContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    minHeight: "4rem",
  },
}));

interface GradingInstructionsProps {
  currentBucket?: string;
  dispatch: React.Dispatch<Action>;
  handleBucketChange: (newBucket: string) => void;
}

function GradingInstructions(props: GradingInstructionsProps) {
  const { currentBucket, dispatch, handleBucketChange } = props;
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid className={classes.step}>
          <Grid container>
            <Avatar variant="rounded" className={classes.avatar}>
              <BarcodeIcon />
            </Avatar>
            <Typography variant="h5" className={classes.label}>
              Scan a barcode{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.step}>
          <Grid container>
            <Avatar variant="rounded" className={classes.avatar}>
              <PageviewIcon />
            </Avatar>
            <Typography variant="h5" className={classes.label}>
              {" "}
              or search gradings{" "}
            </Typography>
          </Grid>
          <Grid className={classes.stepContent}>
            <GradingSearch dispatch={dispatch} />
          </Grid>
        </Grid>
        <Grid className={classes.step}>
          <Grid container>
            <Avatar variant="rounded" className={classes.avatar}>
              <AssignmentIcon />
            </Avatar>
            <Typography variant="h5" className={classes.label}>
              {" "}
              or select a bucket
            </Typography>
          </Grid>
          <Grid className={classes.stepContent}>
            <BucketSelect
              currentBucket={currentBucket}
              handleBucketChange={handleBucketChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default GradingInstructions;
