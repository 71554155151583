import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";

type SearchResultsTableProps = {
  data: SetCard[];
  onSelect: (id: number) => void;
  selected: number | null;
};

export const SearchResultsTable = (props: SearchResultsTableProps) => {
  const { data, onSelect, selected } = props;
  return (
    <TableContainer>
      <Table aria-label="finish table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Card Number</TableCell>
            <TableCell>Card Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              selected={row.id === selected}
              key={index}
              onClick={() => onSelect(row.id)}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.number}</TableCell>
              <TableCell>{row.cardName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
