function LabelTemplateStandardLogo() {

  return (
      <g id="Logo">
        <path
          className="backgroundLogo"
          d="M282.79,60.77a51.91,51.91,0,0,1,103.81,0v5.67h-8l0-5.67a47.49,47.49,0,0,0-95,0l0,5.67h-.85Z"
          transform="translate(5.67 5.67)"
        />
        <g>
          <path
            className="backgroundLogo"
            d="M332.26,55.3c-3.74,0-6.52-3-6.52-6.95s2.78-7,6.52-7a5.81,5.81,0,0,1,5.33,3.49l5.33-2.52a11.16,11.16,0,0,0-10.66-6.44c-5.47,0-10,2.89-11.9,7.53-.36-4-3.27-7.14-8.31-7.14H300v24h6.19V52.2h5.84c3.61,0,6.12-1.59,7.39-3.94v.09c0,7.38,5.62,12.42,12.82,12.42a11.23,11.23,0,0,0,10.66-6.44l-5.33-2.52A5.81,5.81,0,0,1,332.26,55.3Zm-21-8.35h-5V41.54h5a2.69,2.69,0,0,1,2.88,2.74A2.66,2.66,0,0,1,311.22,47Z"
            transform="translate(5.67 5.67)"
          />
          <path
            className="backgroundLogo"
            d="M353.56,46.69v5.15h5.51v2a7.48,7.48,0,0,1-4.54,1.44,6.64,6.64,0,0,1-6.7-6.95,6.64,6.64,0,0,1,6.7-7,6,6,0,0,1,5.08,2.84l5.19-2.73a11.31,11.31,0,0,0-10.27-5.58c-7.16,0-13,4.79-13,12.42s5.84,12.42,13,12.42a13.88,13.88,0,0,0,10.63-4.68v-9.4Z"
            transform="translate(5.67 5.67)"
          />
        </g>
      </g>
  );
}

export default LabelTemplateStandardLogo;
