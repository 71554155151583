import { getUserToken } from "../helpers/authHelpers";

interface FetchParams {
  method: HttpMethod;
  endpoint: string;
  payload?: unknown;
}

export const fetchWithToken = async <T>(
  params: FetchParams,
  isRetry = false
): Promise<T> => {
  let currentToken = await getUserToken(isRetry);
  const { method, endpoint, payload } = params;
  const response = await fetch(endpoint, {
    credentials: "same-origin",
    method,
    body: payload ? JSON.stringify(payload) : null,
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    }),
  });

  if (!response.ok && response.status !== 201) {
    if (response.status === 401 && !isRetry) {
      return fetchWithToken({ method, endpoint, payload }, true);
    }
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }
  return response.json();
};

export const fetchCSVWithToken = async (
  params: FetchParams,
  isRetry = false
): Promise<any> => {
  let currentToken = await getUserToken(isRetry);
  const { method, endpoint, payload } = params;
  const response = await fetch(endpoint, {
    credentials: "same-origin",
    method,
    body: payload ? JSON.stringify(payload) : null,
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    }),
  });

  if (!response.ok && response.status !== 201) {
    if (response.status === 401 && !isRetry) {
      return fetchWithToken({ method, endpoint, payload }, true);
    }
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }
  return response.text();
};

interface UploadFileParams {
  endpoint: string;
  payload?: File;
}
export const uploadFileWithToken = async <T>(
  params: UploadFileParams,
  isRetry = false
): Promise<T> => {
  let currentToken = await getUserToken(isRetry);
  const { endpoint, payload } = params;
  const form = new FormData();
  if (payload) {
    form.append("file", payload);
  }

  const response = await fetch(endpoint, {
    credentials: "same-origin",
    method: "POST",
    body: form,
    headers: new Headers({
      Authorization: `Bearer ${currentToken}`,
    }),
  });

  if (!response.ok && response.status !== 201) {
    if (response.status === 401 && !isRetry) {
      return uploadFileWithToken({ endpoint, payload }, true);
    }
    const errorMessage = await response.text();
    throw console.error(errorMessage);
  }
  return response.json();
};
