import { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import NotesForm from "./NotesForm";

interface AddNoteButtonProps {
  gradingId?: number;
  gradingIdList?: number[];
  children: React.ReactNode;
}

function AddNoteButton(props: AddNoteButtonProps) {
  const { gradingId, gradingIdList, children } = props;
  const [noteFormVisible, setNoteFormVisible] = useState(false);

  const handleFormClose = (event: {}) => setNoteFormVisible(false);

  return (
    <>
      {noteFormVisible ? (
        <Dialog
          open
          onClose={handleFormClose}
          fullWidth
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Note</DialogTitle>
          <DialogContent>
            <NotesForm
              gradingId={gradingId}
              gradingIdList={gradingIdList}
              cancelAction={handleFormClose}
              ActionsWrapper={DialogActions}
            />
          </DialogContent>
        </Dialog>
      ) : null}
      <span onClick={() => setNoteFormVisible(true)}>{children}</span>
    </>
  );
}

export default AddNoteButton;
