import CircularProgress from "@material-ui/core/CircularProgress";
import { useEnableUser, useDisableUser } from "../../hooks/userHooks";
import { useAlert } from "../../hooks/alertHooks";

interface ChangeEnabledStatusProps {
  userId: number;
  enabled: boolean;
}

function ChangeEnabledStatus(props: ChangeEnabledStatusProps) {
  const { userId, enabled } = props;
  const { addAlert } = useAlert();

  const updateHook = enabled ? useDisableUser : useEnableUser;
  const { mutateAsync, isMutating } = updateHook();

  const handleUpdate = async () => {
    try {
      await mutateAsync(userId);
    } catch(error) {
      addAlert("Failed to update user", "error");
    }
  };

  if (isMutating) return <CircularProgress size={24} />;

  return (
    <span onClick={() => handleUpdate()} role="button">
      {enabled ? "Disable user" : "Enable user"}
    </span>
  );
}

export default ChangeEnabledStatus;
