const API_SERVER = "https://app.premiercardgrading.com";
//const API_SERVER = "https://localdefault.fableforge.co"; // Dev

const API_SERVER_DEV = `${API_SERVER}/api/nz/v1`;
//const API_SERVER_DEV = `${API_SERVER}/api/nz/v1`; // Dev

const REGION_US = "us";
const REGION_NZ = "nz";
const REGION_AU = "au";
const REGION_MY = "my";
const REGION_EU = "eu";
const REGION_UK = "uk";
const REGION_JP = "jp";

const getBaseUrl = () => {
  const { hostname } = window.location;
  if (hostname === "admin.premiercardgrading.com")
    return `${API_SERVER}/api/${REGION_US}/v1`;
  if (hostname === "admin.premiercardgrading.co.nz")
    return `${API_SERVER}/api/${REGION_NZ}/v1`;
  if (hostname === "admin.premiercardgrading.com.au")
    return `${API_SERVER}/api/${REGION_AU}/v1`;
  if (hostname === "admin.premiercardgrading.com.my")
    return `${API_SERVER}/api/${REGION_MY}/v1`;
  if (hostname === "admin.premiercardgrading.eu")
    return `${API_SERVER}/api/${REGION_EU}/v1`;
  if (hostname === "admin.premiercardgrading.co.uk")
    return `${API_SERVER}/api/${REGION_UK}/v1`;
  if (hostname === "admin.premiercardgrading.jp")
    return `${API_SERVER}/api/${REGION_JP}/v1`;
  return API_SERVER_DEV;
};

export const API_BASE_URL = getBaseUrl();

export const PUBLIC_API_BASE_URL = `${API_SERVER}/public/api/v1`;
export const ADMIN_API_BASE_URL = `${API_SERVER}/admin/v1`;
