import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

interface HeaderCell {
  id: string;
  label: string;
  sortable: boolean;
}

const headerCells: HeaderCell[] = [
  { id: "shopifyId", label: "Order Id", sortable: true },
  { id: "type", label: "Type", sortable: true },
  { id: "receivedAt", label: "Received", sortable: true },
  { id: "customer", label: "Customer", sortable: false },
  { id: "cards", label: "Cards", sortable: false },
];

interface OrderTableHeaderProps {
  orderDirection: AscendingOrder;
  orderBy?: string;
  canOrderRows: boolean;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

function OrderTableHeader(props: OrderTableHeaderProps) {
  const { orderDirection, orderBy, canOrderRows, onRequestSort } = props;

  const createSortHandler =
    (column: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, column);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headerCells.map((headerCell) => (
          <TableCell
            key={headerCell.id}
            sortDirection={orderBy === headerCell.id ? orderDirection : false}
          >
            {canOrderRows && headerCell.sortable ? (
              <TableSortLabel
                active={orderBy === headerCell.id}
                direction={orderBy === headerCell.id ? orderDirection : "asc"}
                onClick={createSortHandler(headerCell.id)}
              >
                {headerCell.label}
              </TableSortLabel>
            ) : (
              headerCell.label
            )}
          </TableCell>
        ))}
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default OrderTableHeader;
