import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  },
  error: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    padding: "3rem",
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: "0.5rem",
    display: "inline-block",
    position: "relative",
    top: "0.1rem",
  },
  subtext: {
    marginTop: "0.5rem",
  },
}));

function GenericError() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.error}>
        <Typography variant="h5">
          <WarningIcon className={classes.icon} />
          Something went wrong
        </Typography>
        <Typography className={classes.subtext}>
          Please refresh and try again.
        </Typography>
      </div>
    </div>
  );
}

export default GenericError;
