/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useAlert } from "../../hooks/alertHooks";
import { useFetchGradingByBarcode } from "../../hooks/gradingHooks";
import {
  startBarcodeListener,
  stopBarcodeListener,
} from "../../services/barcode";

const useStyles = makeStyles({
  button: {
    marginTop: "1rem",
  },
});

interface GradingBarcodeScannerProps {
  scannedGradings: Grading[];
  setScannedGradings: React.Dispatch<React.SetStateAction<Grading[]>>;
}

function GradingBarcodeScanner(props: GradingBarcodeScannerProps) {
  const { scannedGradings, setScannedGradings } = props;
  const [barcode, setBarcode] = useState<string>();
  const { addAlert } = useAlert();
  const classes = useStyles();

  useEffect(() => {
    startBarcodeListener();
    window.addEventListener("onbarcode", onBarcode as EventListener);
    return () => {
      stopBarcodeListener();
      window.removeEventListener("onbarcode", onBarcode as EventListener);
    };
  }, []);

  const onBarcode = (event: CustomEvent) => {
    console.log(`Barcode received: ${event.detail}`);
    if (event.detail) {
      setBarcode(event.detail);
      setTimeout(() => setBarcode(undefined), 200);
    }
  };

  const {
    data: grading,
    isLoading,
    failureCount,
  } = useFetchGradingByBarcode(barcode);

  useEffect(() => {
    if (failureCount > 0) {
      addAlert("No matching grading found", "error");
    }
  }, [failureCount]);

  useEffect(() => {
    if (grading) {
      const previouslyScannedIds = scannedGradings.map(
        (scannedGrading) => scannedGrading.id
      );
      if (!previouslyScannedIds.includes(grading.id)) {
        setScannedGradings([...scannedGradings, grading]);
      }
    }
  }, [grading]);

  if (isLoading) return <p>Searching...</p>;

  if (scannedGradings.length > 0) {
    return (
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => setScannedGradings([])}
      >
        Clear scanned gradings
      </Button>
    );
  }

  return null;
}

export default GradingBarcodeScanner;
