import { useQueryClient, useMutation, useQuery } from "react-query";
import {
  addGameInfo,
  addSetInfo,
  updateGameInfo,
  uploadGameImage,
  uploadCSV,
  addCardInfo,
  updateReviewedGame,
  updateSetInfo,
  uploadSetImage,
  updateCardInfo,
  uploadCardImage,
  uploadRecords,
  recordDetails,
  deleteFinish,
  csvExport,
  deleteCardInfo
} from "../api/catalog";

export const useUpdateReviewedGame = () => {
  const queryClient = useQueryClient();

  const mutationFunction = async ({
    gameCode,
    reviewedStatus
  }: {
    gameCode: string;
    reviewedStatus: string;
  }) => {
    const result = await updateReviewedGame(gameCode, reviewedStatus);
    return result;
  };

  const { isLoading: isMutating, ...rest } = useMutation(mutationFunction, {
    onSettled: () => {
      queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
    }
  });

  return { isMutating, ...rest };
};

export const useCsvExport = () => {
  const queryClient = useQueryClient();

  const mutationFunction = async ({
    gameCode,
    setName
  }: {
    gameCode: string;
    setName: string;
  }) => {
    // setname doesnt need to be encoded as it is always
    // a simple 3 letter code
    const escaped = encodeURIComponent(setName);

    const result = await csvExport(gameCode, escaped);
    return result;
  };

  const { isLoading: isMutating, ...rest } = useMutation(mutationFunction, {
    onSettled: () => {
      queryClient.invalidateQueries("csvExport", { refetchInactive: true });
    }
  });

  return { isMutating, ...rest };
};

export const useAddGameInfo = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (gameInfo: GameInfo) => addGameInfo(gameInfo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useUpdateGameInfo = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (gameInfo: GameInfo) => updateGameInfo(gameInfo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useUpdatGameImage = (gameCode: string) => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (data: any) => uploadGameImage(data, gameCode),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useUpdatSetInfo = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (setInfo: Set<any>) => updateSetInfo(setInfo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useAddSetInfo = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (setInfo: Set<any>) => addSetInfo(setInfo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useAddCardInfo = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (setInfo: Set<any>) => addCardInfo(setInfo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("setInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useUpdatSetImage = (setId: string) => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (data: any) => uploadSetImage(data, setId),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useUploadCSV = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (data: any) => uploadCSV(data),
    {
      onSettled: () => {
        queryClient.invalidateQueries("historyInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useUpdateCardInfo = (game: string, setName: string) => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (cardInfo: CardInfo) => updateCardInfo(cardInfo),
    {
      onSettled: () => {
        queryClient.invalidateQueries(["setInfo", { game, setName }], {
          refetchInactive: true
        });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useDeleteCardInfo = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ id, migration }: { id: any; migration?: any }) =>
      deleteCardInfo(id, migration),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useUpdatCardImage = (
  productId: string,
  game: string,
  setName: string
) => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (data: any) => uploadCardImage(data, productId),
    {
      onSettled: () => {
        queryClient.invalidateQueries(["setInfo", { game, setName }], {
          refetchInactive: true
        });
      }
    }
  );
  return { isMutating, ...rest };
};

export const useUploadRecords = () => {
  const { isLoading, data, error } = useQuery(["historyInfo"], uploadRecords);
  if (!isLoading && !error && data) {
    return data.data;
  }
  return [];
};

export const useRecordsDetails = (id: any) => {
  const { isLoading, data, error } = useQuery(["historyDetail", { id }], () => {
    if (id !== undefined) {
      return recordDetails(id);
    } else {
      return { data: {} };
    }
  });
  if (!isLoading && error === null && data) {
    return data;
  }
  return {};
};

export const useDeleteFinish = (id: any) => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (id: any) => deleteFinish(id),
    {
      onSettled: () => {
        queryClient.invalidateQueries("gameInfo", { refetchInactive: true });
      }
    }
  );
  return { isMutating, ...rest };
};
