import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// @ts-ignore
import ReactBarcode from "react-barcode";
import { formatDate } from "../../helpers/formattingHelpers";
import PrintLogo from "../../assets/print-logo.png";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: "4rem",
    height: "2rem",
  },
});

interface GradingBarcodeProps {
  barcode: string;
  order: string;
  cardName?: string;
  slabType?: string;
}

function GradingBarcode(props: GradingBarcodeProps) {
  const { barcode, order, cardName, slabType } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={PrintLogo} alt="PCG Logo" className={classes.image} />
      <ReactBarcode value={barcode} />
      <Typography variant="body2">{cardName}</Typography>
      <Typography variant="body2">{`Order #: ${order}`}</Typography>
      <Typography variant="body2">{`Date: ${formatDate(
        new Date().toISOString()
      )}`}</Typography>
      <Typography variant="body2">{`Slab Type: ${
        slabType === "Normal" ? "Standard" : slabType
      }`}</Typography>
    </div>
  );
}

export default GradingBarcode;
