import { makeStyles } from "@material-ui/core/styles";
import { green, blueGrey } from "@material-ui/core/colors";
import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TimeAgo from "react-timeago";

const useStyles = makeStyles({
  root: {
    marginBottom: "1rem",
    border: (note: Note) =>
      note.isPrivate ? `1px solid ${blueGrey[50]}` : `1px solid ${green[50]}`,
  },
  avatar: {
    backgroundColor: (note: Note) =>
      note.isPrivate ? blueGrey[200] : green[400],
  },
});

interface NoteProps {
  note: Note;
}

function Note(props: NoteProps) {
  const { uploadDate, note, noteBy, userRole, bucket, isPrivate } = props.note;
  const classes = useStyles(props.note);

  const Icon = isPrivate ? LockIcon : PublicIcon;
  const iconTitle = isPrivate ? "Private note" : "Public note";

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar title={iconTitle} className={classes.avatar}>
            <Icon />
          </Avatar>
        }
        title={`${noteBy} (${userRole})`}
        subheader={
          <>
            <TimeAgo date={uploadDate} />
            {` in ${bucket}`}
          </>
        }
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {note}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Note;
