import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles({
  image: {
    maxWidth: "95vw",
    maxHeight: "80vh",
  },
  details: {
    margin: "1rem",
  },
  button: {
    float: "right"
  }
});

interface ImageDetailsProps {
  file: UploadedFile;
  closeFunction?: Function;
}

function ImageDetails(props: ImageDetailsProps) {
  const { file, closeFunction } = props;
  const classes = useStyles();

  return (
    <>
      <img src={file.fileUrl} alt={file.fileName} className={classes.image} />
      <div className={classes.details}>
        <Typography variant="caption">Filename</Typography>
        <Typography gutterBottom>{file.fileName}</Typography>
        <Typography variant="caption">Uploaded By</Typography>
        <Typography gutterBottom>{file.uploadedBy}</Typography>
        <Typography variant="caption">Uploaded Date</Typography>
        <Typography gutterBottom>
          {new Date(file.uploadDate).toLocaleDateString()}{" "}
          {new Date(file.uploadDate).toLocaleTimeString()}
        </Typography>
        {closeFunction ? (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            startIcon={<CancelIcon />}
            onClick={() => closeFunction()}
          >
            Close
          </Button>
        ) : null}
      </div>
    </>
  );
}

ImageDetails.defaultProps = {
  closeFunction: null,
};

export default ImageDetails;
