import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { useFetchBuckets } from "../../hooks/userHooks";
import UserTableHeader from "./UserTableHeader";
import UserTableRow from "./UserTableRow";

const useStyles = makeStyles({
  container: {
    marginTop: "2rem",
  },
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: 5,
    minWidth: 120,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

interface UserTableProps {
  users?: User[];
}

function UserTable(props: UserTableProps) {
  const { users } = props;
  const classes = useStyles();
  const { data: buckets } = useFetchBuckets();
  
  if (!users) return null;

  const sortedUsers = users.sort((a: User, b: User) =>
    a.id && b.id && a.id > b.id ? 1 : -1
  );

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <UserTableHeader />
        <TableBody>
          {sortedUsers.map((user) => (
            <UserTableRow user={user} buckets={buckets} key={user.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserTable;
