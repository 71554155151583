import React, { useEffect, useState } from "react";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { titleCase } from "../../helpers/formattingHelpers";
import { useUpdateUser } from "../../hooks/userHooks";
import { useAlert } from "../../hooks/alertHooks";
import { arraysAreEquivalent } from "../../helpers/logicHelpers";

const useStyles = makeStyles({
  formControl: {
    margin: 10,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

const getStyles = (
  bucketName: string,
  availableBuckets: Bucket[],
  theme: Theme
) => {
  return {
    fontWeight: availableBuckets.find((bucket) => bucket.name === bucketName)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface AllocateBucketsProps {
  availableBuckets?: Bucket[];
  user: User;
}

function AllocateBuckets(props: AllocateBucketsProps) {
  const { availableBuckets, user } = props;
  const [allocatedBuckets, setAllocatedBuckets] = useState<string[]>([]);
  const { mutateAsync, isMutating } = useUpdateUser();
  const { addAlert } = useAlert();
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => setAllocatedBuckets(user.buckets || []), [user.buckets]);

  const updateUser = async () => {
    try {
      await mutateAsync({ ...user, buckets: allocatedBuckets });
      addAlert("Buckets updated", "success");
    } catch (error) {
      addAlert("Failed to update buckets", "error");
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAllocatedBuckets(event.target.value as string[]);
  };

  const handleClose = (event: React.ChangeEvent<{}>) => {
    if (!arraysAreEquivalent(allocatedBuckets, user.buckets)) updateUser();
  };

  if (!availableBuckets) return null;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="allocated-buckets-label">Buckets</InputLabel>
      {isMutating ? (
        <CircularProgress size={24} />
      ) : (
        <Select
          labelId="dallocated-buckets-label"
          id="allocated-buckets"
          multiple
          value={allocatedBuckets}
          onChange={handleChange}
          onClose={handleClose}
          input={<Input id="select-multiple-buckets" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {allocatedBuckets.map((bucketName) => (
                <Chip
                  key={bucketName}
                  label={titleCase(bucketName)}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {availableBuckets.map((bucket) => (
            <MenuItem
              key={bucket.id}
              value={bucket.name}
              style={getStyles(bucket.name, availableBuckets, theme)}
            >
              {titleCase(bucket.name)}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}

export default AllocateBuckets;
