import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import UserMenu from "../components/auth/UserMenu";
import PCGLogo from "../assets/pcg-logo-white.png";
import { useFetchMe } from "../hooks/userHooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    logo: {
      height: "3rem",
      marginRight: "3rem",
    },
    appBar: {
      backgroundColor: "black",
    },
    navLink: {
      flexGrow: 1,
      color: "#FFF",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);

function AppHeader() {
  const classes = useStyles();
  const { data: currentUser } = useFetchMe();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Link to="/">
            <img src={PCGLogo} alt="" className={classes.logo} />
          </Link>
          <Link to="/orders" className={classes.navLink}>
            <Typography variant="h6">Orders</Typography>
          </Link>
          <Link to="/catalog" className={classes.navLink}>
            <Typography variant="h6">Catalog</Typography>
          </Link>
          <Link to="/gradings" className={classes.navLink}>
            <Typography variant="h6">Gradings</Typography>
          </Link>
          <Link to="/dashboard" className={classes.navLink}>
            <Typography variant="h6">Dashboard</Typography>
          </Link>
          {currentUser?.role?.toLowerCase() === "admin" || currentUser?.role?.toLowerCase() === "super admin" ? (
            <Link to="/users" className={classes.navLink}>
              <Typography variant="h6">Users</Typography>
            </Link>
          ) : null}
          <UserMenu />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppHeader;
