/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useAlert } from "../../hooks/alertHooks";
import { useFetchOrderByBarcode } from "../../hooks/orderHooks";
import {
  startBarcodeListener,
  stopBarcodeListener,
} from "../../services/barcode";

const useStyles = makeStyles({
  button: {
    marginTop: "1rem",
  },
});

interface OrderBarcodeScannerProps {
  scannedOrders: Order[];
  setScannedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
}

function OrderBarcodeScanner(props: OrderBarcodeScannerProps) {
  const { scannedOrders, setScannedOrders } = props;
  const [barcode, setBarcode] = useState<string>();
  const { addAlert } = useAlert();
  const classes = useStyles();

  useEffect(() => {
    startBarcodeListener();
    window.addEventListener("onbarcode", onBarcode as EventListener);
    return () => {
      stopBarcodeListener();
      window.removeEventListener("onbarcode", onBarcode as EventListener);
    };
  }, []);

  const onBarcode = (event: CustomEvent) => {
    console.log(`Barcode received: ${event.detail}`);
    if (event.detail) {
      setBarcode(event.detail);
      setTimeout(() => setBarcode(undefined), 200);
    }
  };

  const {
    data: order,
    isLoading,
    failureCount,
  } = useFetchOrderByBarcode(barcode);

  useEffect(() => {
    if (failureCount > 0) {
      addAlert("No matching order found", "error");
    }
  }, [failureCount]);

  useEffect(() => {
    if (order) {
      const previouslyScannedIds = scannedOrders.map(
        (scannedOrder) => scannedOrder.id
      );
      if (!previouslyScannedIds.includes(order.id)) {
        setScannedOrders([...scannedOrders, order]);
      }
    }
  }, [order]);

  if (isLoading) return <p>Searching...</p>;

  if (scannedOrders.length > 0) {
    return (
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => setScannedOrders([])}
      >
        Clear scanned orders
      </Button>
    );
  }

  return null;
}

export default OrderBarcodeScanner;
