import CircularProgress from "@material-ui/core/CircularProgress";
import { useResetPassword } from "../../hooks/userHooks";
import { useAlert } from "../../hooks/alertHooks";

interface ResetPasswordProps {
  userId: number;
}

function ResetPassword(props: ResetPasswordProps) {
  const { userId } = props;
  const { addAlert } = useAlert();
  const { mutateAsync, isMutating } = useResetPassword();

  const handleClick = async () => {
    try {
      await mutateAsync(userId);
      addAlert("Password reset sent", "success");
    } catch (error) {
      addAlert("Failed to send password reset email", "error");
    }
  };

  if (isMutating) return <CircularProgress size={24} />;

  return (
    <span onClick={() => handleClick()} role="button">
      Send password reset
    </span>
  );
}

export default ResetPassword;
