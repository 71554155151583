import Typography from "@material-ui/core/Typography";
import BucketGraph from "../components/dashboard/BucketGraph";

function DashboardView() {
  return (
    <>
      <Typography variant="h2">Dashboard</Typography>
      <BucketGraph />
    </>
  );
}

export default DashboardView;
