import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#d1a96f",
      light: "#e0c69e",
    },
    secondary: {
      main: "#d1a96f",
    },
  },
  overrides: {
    MuiGrid: {
      "spacing-xs-2": {
        width: "calc(99% + 16px)",
      },
    },
  },
});
