import {
  fetchCSVWithToken,
  fetchWithToken,
  uploadFileWithToken
} from "../services/api";
import { ADMIN_API_BASE_URL } from "../config/api";

export const updateReviewedGame = (
  gameCode: string,
  reviewed: string
): Promise<GameInfo> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${ADMIN_API_BASE_URL}/game/${gameCode}/review?reviewed=${reviewed}`
  });

export const addGameInfo = (data: GameInfo): Promise<GameInfo> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${ADMIN_API_BASE_URL}/game/`,
    payload: data
  });

export const updateGameInfo = (data: GameInfo): Promise<GameInfo> =>
  fetchWithToken({
    method: "PUT",
    endpoint: `${ADMIN_API_BASE_URL}/game/${data.gameCode}`,
    payload: data
  });

export const uploadGameImage = (data: any, gameCode: string): Promise<any> =>
  uploadFileWithToken({
    endpoint: `${ADMIN_API_BASE_URL}/game/${gameCode}/image`,
    payload: data
  });

export const updateSetInfo = (data: Set<any>): Promise<Set<any>> =>
  fetchWithToken({
    method: "PUT",
    endpoint: `${ADMIN_API_BASE_URL}/gameSet/${data.id}`,
    payload: data
  });

export const addSetInfo = (data: Set<any>): Promise<Set<any>> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${ADMIN_API_BASE_URL}/gameSet`,
    payload: data
  });

export const csvExport = (gameCode: string, setName: string): Promise<any> =>
  fetchCSVWithToken({
    method: "GET",
    endpoint: `${ADMIN_API_BASE_URL}/gameProduct/export?gameCode=${gameCode}&setName=${setName}`
  });

export const addCardInfo = (data: Set<any>): Promise<Set<any>> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${ADMIN_API_BASE_URL}/gameProduct`,
    payload: data
  });

export const uploadCSV = (data: any): Promise<any> =>
  uploadFileWithToken({
    endpoint: `${ADMIN_API_BASE_URL}/gameProduct/bulk`,
    payload: data
  });

export const uploadSetImage = (data: any, setId: string): Promise<any> =>
  uploadFileWithToken({
    endpoint: `${ADMIN_API_BASE_URL}/gameSet/${setId}/image`,
    payload: data
  });

export const updateCardInfo = (data: CardInfo): Promise<CardInfo> =>
  fetchWithToken({
    method: "PUT",
    endpoint: `${ADMIN_API_BASE_URL}/gameProduct/${data.id}`,
    payload: data
  });

export const uploadCardImage = (data: any, productId: string): Promise<any> =>
  uploadFileWithToken({
    endpoint: `${ADMIN_API_BASE_URL}/gameProduct/${productId}/image`,
    payload: data
  });

export const uploadRecords = (): Promise<any> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${ADMIN_API_BASE_URL}/uploadRecords`
  });

export const recordDetails = (id: any): Promise<any> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${ADMIN_API_BASE_URL}/uploadRecords/${id}`
  });

export const deleteFinish = (id: any): Promise<any> =>
  fetchWithToken({
    method: "DELETE",
    endpoint: `${ADMIN_API_BASE_URL}/game/printing/${id}`
  });

export const deleteCardInfo = (id: any, migration: any): Promise<any> =>
  fetchWithToken({
    method: "DELETE",
    endpoint: `${ADMIN_API_BASE_URL}/gameProduct/${id}`,
    ...(!!migration ? { payload: migration } : {})
  });
