import { useEffect, useState } from 'react';

import {
  Button,
} from '@material-ui/core';

import CsvModal from './CsvModal';

import { useUploadCSV, useUploadRecords } from '../../hooks/catalogHooks';

function UploadCSVButton() {
  const [modalOpen, setModalOpen] = useState(false);

  const { mutateAsync: mutateImage, isMutating: imageIsMutating } = useUploadCSV();
  const  history = useUploadRecords();
  
  const handleFileChange = async (file: any) => {
    await mutateImage(file);
  }

  useEffect(() => {
    }, [history])

  return (
    <>
      <Button 
        variant="contained"
        size="small"
        color="primary"
        startIcon={""}
        onClick={() => setModalOpen(true)}
      >
        Bulk Upload
      </Button>
      <CsvModal
        title={`Upload a CSV`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        history={history}
        isMutating={imageIsMutating }
        onFileChange={handleFileChange}
      />
    </>
  );
}

export default UploadCSVButton;
