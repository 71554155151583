import { useParams, useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useFetchUsers } from "../hooks/userHooks";
import UserForm from "../components/user/UserForm";

interface RouterProps {
  userId?: string;
}

function UpdateUserView() {
  const { userId } = useParams<RouterProps>();
  const history = useHistory();

  const { isLoading, data } = useFetchUsers();

  const handleClose = () => {
    history.push("/users");
  };

  if (!userId || isLoading) return null;

  const selectedUser = data?.find((user) => user.id === Number(userId));

  if (!selectedUser) {
    return <p>No user found</p>;
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Update User</DialogTitle>
      <UserForm
        user={selectedUser}
        ActionsWrapper={DialogActions}
        closeForm={handleClose}
      />
    </Dialog>
  );
}

export default UpdateUserView;