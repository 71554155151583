import Button from "@material-ui/core/Button";
import { useAlert } from "../hooks/alertHooks";

function CustomersView() {
  const { addAlert } = useAlert();

  return (
    <>
      <p>CUSTOMERS</p>
      <Button onClick={() => addAlert("I hope this works")}>Test</Button>
    </>
  );
}

export default CustomersView;
