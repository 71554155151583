import { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { useUpdateReviewedGame } from "../../hooks/catalogHooks";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box,
  Typography,
  Modal
} from "@material-ui/core";

import UploadImage from "./UploadImage";
import PrintingOptions from "./PrintingOptions";
import { Autocomplete } from "@mui/material";
import { DeleteConfirmMigrateModal } from "./DeleteConfirmMigrateModal";
import { DeleteConfirmModal } from "./DeleteConfirmModal";

export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ff0000",
  boxShadow: 24,
  p: 4
};

type TagsPayload = {
  options: any[];
  defaults: any[];
  transformSelection: (value: any) => any;
};

interface EditableKey {
  key: string;
  type: "string" | "image" | "boolean" | "array" | "tags";
  payload?:
    | { [key: string]: string | null | boolean | object | [] }
    | TagsPayload;
}

function EditorModal(props: {
  title: string;
  data: { [key: string]: string | null | boolean | object | [] };
  editableKeys: EditableKey[];
  open: boolean;
  onSave: any;
  onDelete?: (data: any) => void;
  onClose: any;
  isMutating: boolean;
  onFileChange?: any;
  migrate?: boolean;
}) {
  const {
    title,
    data,
    editableKeys,
    open,
    onSave,
    onClose,
    isMutating,
    onFileChange,
    migrate = false,
    onDelete
  } = props;
  const [localData, setLocalData] = useState(data);
  const [isHiddenSetting, setIsHiddenSetting] = useState(false);
  const [openArchiveWarningModel, setOpenArchiveWarningModel] = useState(false);
  const [openDeleteWarningModel, setOpenDeleteWarningModel] = useState(false);
  const handleOpenArchive = () => setOpenArchiveWarningModel(true);
  const handleCloseArchive = () => setOpenArchiveWarningModel(false);
  const handleOpenDelete = () => setOpenDeleteWarningModel(true);
  const handleCloseDelete = () => setOpenDeleteWarningModel(false);
  const {
    mutateAsync: mutateReview,
    isMutating: isReviewMutating
  } = useUpdateReviewedGame();

  useEffect(() => {
    if (data.gameImage && data.gameImage !== localData.gameImage) {
      setLocalData({
        ...localData,
        gameImage: data.gameImage
      });
    }
    if (data.image && data.image !== localData.image) {
      setLocalData({
        ...localData,
        image: data.image
      });
    }
    if (data.setImage && data.setImage !== localData.setImage) {
      setLocalData({
        ...localData,
        setImage: data.setImage
      });
    }
  }, [data, localData, setLocalData]);

  const handleOnSave = () => {
    onSave(localData);
  };

  const handleConfirmDelete = (data: any) => {
    onDelete && onDelete(data);
    handleCloseModal();
  };

  const handleSetLocalData = (key: string, value: string | boolean) => {
    setLocalData({
      ...localData,
      [key]: value === "" ? null : value
    });
  };

  const handleSetLocalDataAndSave = (key: string, value: any) => {
    console.log("localData:", {
      ...localData,
      [key]: value
    });
    onSave({
      ...localData,
      [key]: value
    });
  };

  const handleCloseModal = (...props: any) => {
    onClose(props);
    setLocalData(data);
  };

  useEffect(() => {
    if (data) {
      setLocalData(data);
    }
  }, [data, setLocalData]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby={`alert-dialog-${title}-title`}
      aria-describedby={`alert-dialog-${title}-description`}
    >
      <DialogTitle id={`alert-dialog-${title}-title`}>{title}</DialogTitle>
      <DialogContent>
        {editableKeys.map((entry: EditableKey) => {
          const { key, type, payload } = entry;
          const value = localData[key];

          if (data[key] !== undefined) {
            switch (type) {
              case "string":
                return (
                  <TextField
                    key={key}
                    label={key}
                    value={value === null ? "" : value}
                    onChange={(e) => handleSetLocalData(key, e.target.value)}
                    fullWidth
                    style={{ marginBottom: 16 }}
                  />
                );
              case "tags":
                const {
                  options,
                  defaults,
                  transformSelection
                } = payload as TagsPayload;

                return (
                  <Autocomplete
                    multiple
                    id={`tags-${key}`}
                    options={options}
                    onChange={(_, value) =>
                      handleSetLocalData(key, transformSelection(value))
                    }
                    defaultValue={defaults}
                    renderInput={(params) => (
                      <TextField {...params} label={key} placeholder={key} />
                    )}
                  />
                );
              case "image":
                return (
                  <UploadImage
                    key={key}
                    label={key}
                    value={String(value)}
                    onFileChange={onFileChange}
                  />
                );
              case "array":
                return (
                  <PrintingOptions
                    key={key}
                    label={key}
                    value={value}
                    onChange={(finishes: any) =>
                      handleSetLocalDataAndSave(key, finishes)
                    }
                  />
                );
              case "boolean":
                const booleanValue = Boolean(value);
                return (
                  <FormControl fullWidth key={key}>
                    <div
                      onClick={() => setIsHiddenSetting(!isHiddenSetting)}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <input
                        type="checkbox"
                        checked={isHiddenSetting}
                        tabIndex={-1}
                        onChange={() => {}}
                      />
                      Check to reveal the <b>{key}</b> and <b>deletion</b> state
                      controller.
                    </div>
                    {isHiddenSetting ? (
                      <div
                        style={{
                          padding: "12px"
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={booleanValue}
                              tabIndex={-1}
                              onClick={() => {
                                handleOpenArchive();
                              }}
                              name={key}
                            />
                          }
                          label={
                            key === "reviewed" && booleanValue
                              ? "Currently Reviewed & Visible"
                              : key === "archived" && booleanValue
                              ? "Archived. Restore Now?"
                              : key === "archived" && !booleanValue
                              ? "Currently Active & Visible"
                              : "Not Reviewed"
                          }
                        />
                        {!!onDelete ? (
                          <Button
                            style={{ float: "right" }}
                            variant="contained"
                            color="secondary"
                            onClick={handleOpenDelete}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </div>
                    ) : null}
                    {key === "reviewed" ? (
                      <p
                        style={{
                          textAlign: "center",
                          color: "orange",
                          fontSize: "12px",
                          fontWeight: "bold"
                        }}
                      >
                        * Review status determines whether a game is shown on
                        the pop report & shopify
                      </p>
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          color: "orange",
                          fontSize: "12px",
                          fontWeight: "bold"
                        }}
                      >
                        * Archiving a set or card will hide it from PCG. Delete
                        will remove it entirely. Please only use these inputs if
                        you are sure you want to hide or delete this set or
                        card.
                      </p>
                    )}
                    {migrate ? (
                      <DeleteConfirmMigrateModal
                        cardInfo={data}
                        open={openDeleteWarningModel}
                        onClose={handleCloseDelete}
                        onConfirm={handleConfirmDelete}
                      />
                    ) : (
                      <DeleteConfirmModal
                        cardInfo={data}
                        open={openDeleteWarningModel}
                        onClose={handleCloseDelete}
                        onConfirm={handleConfirmDelete}
                      />
                    )}
                    <Modal
                      open={openArchiveWarningModel}
                      onClose={handleCloseArchive}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={modalStyle}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Are you Sure?
                        </Typography>
                        <hr />
                        <Typography>
                          You are about to update this to
                          {key === "reviewed" ? (
                            <b>{booleanValue ? "Not Reviewed" : "Reviewed"}</b>
                          ) : (
                            <b>
                              {booleanValue
                                ? "Restore from archives"
                                : "Archive Now"}
                            </b>
                          )}
                        </Typography>
                        <>
                          <br />
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleCloseArchive}
                          >
                            No
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            style={{ float: "right" }}
                            color="primary"
                            disabled={isReviewMutating}
                            onClick={() => {
                              if (
                                key === "reviewed" &&
                                typeof data.gameCode === "string"
                              ) {
                                mutateReview({
                                  gameCode: data.gameCode,
                                  reviewedStatus: !booleanValue
                                    ? "true"
                                    : "false"
                                });
                                handleCloseArchive();
                              } else {
                                handleSetLocalData(key, !booleanValue);
                              }
                            }}
                          >
                            Yes, Save & Update
                          </Button>
                        </>
                      </Box>
                    </Modal>
                  </FormControl>
                );
              default:
                return null;
            }
          }
          return null;
        })}
        {isMutating && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              textAlign: "center",
              background: "black",
              opacity: ".6"
            }}
          >
            <CircularProgress style={{ marginTop: "30%" }} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseModal}
          color="primary"
          disabled={isMutating}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOnSave}
          color="primary"
          autoFocus
          disabled={isMutating || isEqual(data, localData)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditorModal;
