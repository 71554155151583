import { useReducer, useState } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import { useFetchGradings } from "../hooks/gradingHooks";
import { gradingReducer, ActionType } from "../reducers/gradingReducer";
import GradingBarcodeScanner from "../components/grading/GradingBarcodeScanner";
import GradingBulkActions from "../components/grading/GradingBulkActions";
import GradingInstructions from "../components/grading/GradingInstructions";
import GradingTable from "../components/grading/GradingTable";
import Loader from "../components/generic/Loader";
import GenericError from "../components/generic/GenericError";

const RESULTS_PER_PAGE = 25;

function GradingsView() {
  const [scannedGradings, setScannedGradings] = useState<Grading[]>([]);
  const [gradingParams, dispatch] = useReducer(gradingReducer, { page: 0 });
  const [order, setOrder] = useState<AscendingOrder>("asc");
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(RESULTS_PER_PAGE);
  const [selected, setSelected] = useState<Grading[]>([]);
  const { page, ...searchParams } = gradingParams;
  const { isLoading, data, error } = useFetchGradings(
    page * rowsPerPage,
    rowsPerPage,
    order,
    orderBy,
    searchParams
  );

  const gradingList = scannedGradings.length > 0 ? scannedGradings : data?.data;

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => dispatch({ type: ActionType.CHANGE_PAGE, page: Math.max(newPage, 0) });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch({ type: ActionType.CHANGE_PAGE, page: 0 });
  };

  const handleBucketChange = (newBucket: string) => {
    setScannedGradings([]);
    setSelected([]);
    dispatch({ type: ActionType.CHANGE_BUCKET, bucket: newBucket });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = gradingList || [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowSelectClick = (
    event: React.MouseEvent<unknown>,
    grading: Grading
  ) => {
    const selectedIndex = selected.findIndex(
      selectedGrading => selectedGrading.id === grading.id
    );
    let newSelected: Grading[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, grading);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const clearSelected = () => setSelected([]);

  const emptyRows =
    RESULTS_PER_PAGE -
    Math.min(
      RESULTS_PER_PAGE,
      data?.data.length || 0 - gradingParams.page * RESULTS_PER_PAGE
    );

  if (isLoading) return <Loader />;

  if (error) {
    console.error(error);
    return <GenericError />;
  }

  return (
    <>
      <Typography variant="h2">Gradings</Typography>
      <GradingInstructions
        currentBucket={gradingParams.bucket}
        dispatch={dispatch}
        handleBucketChange={handleBucketChange}
      />
      <GradingBarcodeScanner
        scannedGradings={scannedGradings}
        setScannedGradings={setScannedGradings}
      />
      <GradingBulkActions
        currentBucket={gradingParams.bucket || ""}
        selectedGradings={selected}
        clearSelected={clearSelected}
      />
      <GradingTable
        gradings={gradingList}
        currentBucket={gradingParams.bucket}
        selected={selected.map(grading => grading.id)}
        emptyRows={emptyRows}
        rowsSelected={selected.length}
        rowCount={gradingList?.length || 0}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRowSelectClick={handleRowSelectClick}
        onRequestSort={handleRequestSort}
      />
      {scannedGradings.length === 0 && data && data.totalPages > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data.totalElements}
          rowsPerPage={rowsPerPage}
          page={gradingParams.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
}

export default GradingsView;
