import firebase from "firebase/app";

export const updateUserToken = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    return user.getIdToken(true).then((token) => {
      sessionStorage.setItem("token", token);
      return token;
    });
  }
};

export const getUserToken = async (forceUpdate = false) => {
  let currentToken = sessionStorage.getItem("token") as AuthToken;
  if (!currentToken || currentToken.length === 0 || forceUpdate) {
    currentToken = await updateUserToken();
  }
  return currentToken;
};

export const deleteUserToken = () => {
  try {
    sessionStorage.removeItem("token");
  } catch (error) {
    // Not really much we can do about this
  }
};

export const getCurrentUser = () => firebase.auth().currentUser;
